import { HttpClient } from '@angular/common/http';
import { AppConfigurationService, prepareListUrlWithFilter } from 'crmcloud-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "crmcloud-core";
var WeatherStationCoreProxyService = /** @class */ (function () {
    function WeatherStationCoreProxyService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
        this.readApiUrl = this.config.configuration.api_url + "/v1/sensors";
        this.writeApiUrl = this.config.configuration.api_url + "/v1/weatherstations/sensors";
    }
    WeatherStationCoreProxyService.prototype.find = function (deviceType, requestList) {
        return this.httpClient.get(prepareListUrlWithFilter(this.createReadApiUrl(deviceType), requestList, this.prepareFilterUrl));
    };
    WeatherStationCoreProxyService.prototype.get = function (deviceType, id) {
        return this.httpClient.get(this.createReadApiUrl(deviceType) + "/" + id);
    };
    WeatherStationCoreProxyService.prototype.create = function (deviceType, model) {
        return this.httpClient.post(this.createWriteApiUrl(deviceType), model);
    };
    WeatherStationCoreProxyService.prototype.update = function (deviceType, id, model) {
        return this.httpClient.put(this.createWriteApiUrl(deviceType) + "/" + id, model);
    };
    WeatherStationCoreProxyService.prototype.delete = function (deviceType, id) {
        return this.httpClient.delete(this.createWriteApiUrl(deviceType) + "/" + id);
    };
    WeatherStationCoreProxyService.prototype.getPermissions = function (deviceType, id) {
        return this.httpClient.get(this.createWriteApiUrl(deviceType) + "/" + id + "/permissions");
    };
    WeatherStationCoreProxyService.prototype.updatePermissions = function (deviceType, id, data) {
        return this.httpClient.put(this.createWriteApiUrl(deviceType) + "/" + id + "/permissions", data);
    };
    WeatherStationCoreProxyService.prototype.prepareFilterUrl = function (filter) {
        var params = [];
        if (filter.serialNumber != null && filter.serialNumber !== '') {
            params.push("SerialNumber=" + filter.serialNumber);
        }
        return params;
    };
    WeatherStationCoreProxyService.prototype.createReadApiUrl = function (deviceType) {
        return this.readApiUrl + "/" + deviceType;
    };
    WeatherStationCoreProxyService.prototype.createWriteApiUrl = function (deviceType) {
        return this.writeApiUrl + "/" + deviceType;
    };
    WeatherStationCoreProxyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WeatherStationCoreProxyService_Factory() { return new WeatherStationCoreProxyService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfigurationService)); }, token: WeatherStationCoreProxyService, providedIn: "root" });
    return WeatherStationCoreProxyService;
}());
export { WeatherStationCoreProxyService };
