<div class="row ground-contour-edit-buttons">
  <div class="col-12">
    <div class="d-flex">
      <a
        class="btn btn-raised mr-1 btn-secondary"
        (click)="enableDrawing()">
        <i class="ft-edit-2 mr-1"></i>{{ 'CADASTRALREGISTERMAP.draw:button' | translate }}
      </a>
      <a
        class="btn btn-raised mr-1 btn-secondary"
        (click)="enableChoosing()">
        <i class="ft-list mr-1"></i>{{ 'CADASTRALREGISTERMAP.choose:button' | translate }}
      </a>
    </div>
  </div>
</div>
<div
  class="row"
  [ngClass]="{ 'flex-column-reverse flex-md-row': !drawingEnabled }">
  <div
    [ngClass]="{
      'col-12 col-md-6 mb-4 mb-md-0': !drawingEnabled,
      'col-12': drawingEnabled
    }">
    <div style="height: 420px">
      <app-map
        [mapConfig]="mapConfig"
        [markerConfig]="markerConfig"
        [polygonConfig]="polygonConfig"
        [(features)]="features">
      </app-map>
    </div>
  </div>
  <div
    class="col-12 col-md-6"
    *ngIf="!drawingEnabled">
    <app-cadastral-register-form
      [(cadastralRegister)]="cadastralRegister"
      [translateKey]="'GROUND'"
      [cadastreAsSelectInput]="true">
    </app-cadastral-register-form>
  </div>
</div>
