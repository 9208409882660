<div
  class="mb-2"
  [appSpinner]="dataSource.loading$ | async">
  <div
    class="card"
    appPanelFullscreen>
    <div class="card-header pt-3 px-0">
      <label class="px-0">
        <h5 class="m-0">
          <i class="ft ft-list"></i>
          {{ 'FARM.CERTIFICATES.certificatesList:header' | translate }}
        </h5>
      </label>
      <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
    </div>
    <div class="card-content">
      <div class="card-body px-0">
        <div class="container-fluid table-container">
          <cdk-table
            [dataSource]="dataSource"
            cdkSort
            cdkSortActive="name"
            cdkSortDirection="desc">
            <ng-container cdkColumnDef="name">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                cdk-sort-header
                class="col-2 pr-0">
                {{ 'FARM.CERTIFICATES.certificateName' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-2 pr-0">
                {{ element.name }}
              </div>
            </ng-container>
            <ng-container cdkColumnDef="certificateType">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-2 pr-0">
                {{ 'FARM.CERTIFICATES.certificateType' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-2 pr-0">
                {{ formatCertificateType(element.certificateType) | translate }}
              </div>
            </ng-container>
            <ng-container cdkColumnDef="code">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-2 pr-0">
                {{ 'FARM.CERTIFICATES.certificateCode' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-2 pr-0">
                {{ element.code }}
              </div>
            </ng-container>
            <ng-container cdkColumnDef="startDate">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-2 col-xl-2 pr-0">
                {{ 'FARM.CERTIFICATES.certificateStartDate' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-2 col-xl-2 pr-0">
                {{ element.startDate | date: 'dd.MM.yyyy' }}
              </div>
            </ng-container>
            <ng-container cdkColumnDef="endDate">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-2 col-xl-2 pr-0">
                {{ 'FARM.CERTIFICATES.certificateEndDate' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-2 col-xl-2 pr-0">
                {{ element.endDate | date: 'dd.MM.yyyy' }}
              </div>
            </ng-container>
            <ng-container cdkColumnDef="action">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-2 col-xl-2 text-md-right">
                {{ 'APPLICATION.LIST.action' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="sticky-action col-2 col-xl-2 p-0 pl-1 pl-md-0 pr-0 pr-md-1 w-100">
                <div class="row no-gutters justify-content-md-end">
                  <button
                    (click)="onDeleteClick(element)"
                    *ngIf="!element.isDeleted"
                    title="{{ 'FARM.CERTIFICATES.delete:button' | translate }}"
                    class="btn btn-raised m-0 my-1 mr-1 btn-sm btn-danger">
                    <i
                      class="ft ft-trash-2"
                      aria-hidden="true"></i>
                  </button>
                  <a
                    *ngIf="element.file"
                    href="{{ element.file.link }}"
                    title="{{ 'DOCUMENTS.download:button' | translate }}"
                    target="_blank"
                    class="btn btn-raised m-0 my-1 mr-1 btn-sm btn-info">
                    <i
                      class="ft ft-download"
                      aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </ng-container>
            <cdk-header-row
              class="row header-navbar extra_bg-light-grey"
              *cdkHeaderRowDef="displayedColumns">
            </cdk-header-row>
            <cdk-row
              class="row row-striped align-items-center"
              *cdkRowDef="let row; columns: displayedColumns"></cdk-row>
          </cdk-table>
          <div
            class="row"
            *ngIf="dataSource.noData$ | async">
            <div class="col text-center py-3">
              {{ 'FARM.CERTIFICATES.list:empty' | translate }}
            </div>
          </div>
          <div
            class="row"
            *ngIf="dataSource.error$ | async">
            <div class="col text-center py-3">
              {{ 'FARM.CERTIFICATES.list:error' | translate }}
            </div>
          </div>
          <cdk-paginator
            class="row header-navbar d-flex justify-content-end pr-2 py-1"
            [pageSizeOptions]="[10, 25, 50, 75, 100]"
            showFirstLastButtons></cdk-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
