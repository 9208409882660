import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppConfigurationService, FcTokenService } from 'crmcloud-core';
import { TenantService } from '@crm-portal/core/auth/tenant.service';
import { UserService } from '@crm-portal/core/auth/user.service';

@Component({
  selector: 'app-load-user-data',
  templateUrl: './load-user-data.component.html',
  styleUrls: ['./load-user-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadUserDataComponent implements OnInit {
  messageTranslationKey = 'APPLICATION.loadingData';
  organizationsUrlTemp = '/organizations';
  constructor(
    private config: AppConfigurationService,
    private userService: UserService,
    private tenantService: TenantService,
    private fcTokenService: FcTokenService,
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    let returnUrl: string = this.route.snapshot.queryParams.returnUrl;
    if (returnUrl == null) {
      returnUrl = this.config.configuration.portal_start_url || this.organizationsUrlTemp;
    }

    if (returnUrl === '/') {
      returnUrl = this.config.configuration.portal_start_url || this.organizationsUrlTemp;
    }

    if (returnUrl.includes('id_token')) {
      returnUrl = this.config.configuration.portal_start_url || this.organizationsUrlTemp;
    }

    this.userService
      .getUserStatus(this.tenantService.currentTenantId)
      .pipe(
        tap(token => {
          this.fcTokenService.setFcToken(token);
        }),
      )
      .subscribe(
        () => {
          returnUrl == null ? this.router.navigate([this.organizationsUrlTemp]) : this.router.navigateByUrl(returnUrl);
        },
        _ => {
          this.messageTranslationKey = 'APPLICATION.ERRORS.loadingData';
          this.changeDetectorRef.detectChanges();
        },
      );
  }
}
