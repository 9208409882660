<div *ngIf="!field.valid && field.touched">
  <span
    class="invalid-feedback d-block"
    *ngIf="field.hasError('required')"
    >{{ 'APPLICATION.VALIDATORS.required' | translate: { placeholder: displayName } }}</span
  >
  <span
    class="invalid-feedback d-block"
    *ngIf="field.hasError('email')"
    >{{ 'APPLICATION.VALIDATORS.email' | translate: { placeholder: displayName } }}</span
  >
  <span
    class="invalid-feedback d-block"
    *ngIf="field.hasError('min')"
    >{{
      'APPLICATION.VALIDATORS.min'
        | translate
          : {
              placeholder: displayName,
              'validator.min': field.errors.min.min
            }
    }}</span
  >
  <span
    class="invalid-feedback d-block"
    *ngIf="field.hasError('max')"
    >{{
      'APPLICATION.VALIDATORS.max'
        | translate
          : {
              placeholder: displayName,
              'validator.max': field.errors.max.max
            }
    }}</span
  >
  <span
    class="invalid-feedback d-block"
    *ngIf="field.hasError('dayBefore')"
    >{{
      'APPLICATION.VALIDATORS.dayBefore'
        | translate
          : {
              placeholder: displayName,
              'validator.dayBefore': field.errors.dayBefore.dayBefore
            }
    }}</span
  >
  <span
    class="invalid-feedback d-block"
    *ngIf="field.hasError('dayAfter')"
    >{{
      'APPLICATION.VALIDATORS.dayAfter'
        | translate
          : {
              placeholder: displayName,
              'validator.dayAfter': field.errors.dayAfter.dayAfter
            }
    }}</span
  >
</div>
