<div
  class="container-fluid px-0"
  [appSpinner]="displaySpinner$ | async">
  <button
    class="btn btn-raised btn-primary mb-2 mr-2"
    (click)="enableDrawing()">
    {{ 'FIELDAUDIT.SELECTFIELDAUDITAREA.draw' | translate }}
  </button>
  <button
    class="btn btn-raised btn-primary mb-2 mr-2"
    (click)="enableCadastre()">
    {{ 'FIELDAUDIT.SELECTFIELDAUDITAREA.takeFromCadastral' | translate }}
  </button>
  <div class="map-container">
    <app-pure-map [height]="420"></app-pure-map>
    <div class="map-buttons">
      <div class="icon-button">
        <button (click)="showCadastreFeatures()">
          {{ 'FIELDAUDIT.SELECTFIELDAUDITAREA.showCadastre' | translate }}
        </button>
      </div>
      <div
        style="
          position: relative;
          overflow: hidden;
          width: 110px;
          height: 1px;
          margin: 0px 5px;
          background-color: rgb(230, 230, 230);
          top: 0px;
        "></div>
      <div class="icon-button icon-button__search">
        <button (click)="centerMapToMarker()">
          {{ 'FIELDAUDIT.SELECTFIELDAUDITAREA.centerMap' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="showCadastreRegister"
    class="col-12 mt-3">
    <app-cadastral-register-form
      [(cadastralRegister)]="cadastralRegister"
      [translateKey]="'GROUND'"
      [cadastreAsSelectInput]="true">
    </app-cadastral-register-form>
  </div>
  <div
    [hidden]="!isManualPolygonSelected"
    class="mt-4">
    <label class="px-0">
      <h5 class="m-0">
        <i class="ft ft-target"></i>
        {{ 'FIELDAUDIT.SELECTFIELDAUDITAREA.groundInfo' | translate }}
      </h5>
    </label>
    <hr class="mt-0 ng-star-inserted" />
    <app-cadastral-register-name-form
      #fieldAuditAreaSelectCadastreRegisterForm
      [(cadastralRegister)]="cadastralRegisterForGround"
      [translateKey]="'GROUND'"
      [cadastreAsSelectInput]="false">
    </app-cadastral-register-name-form>
  </div>
</div>
