<ngb-tabset>
  <ngb-tab title="{{ 'DASHBOARD.TABS.locations' | translate }}">
    <ng-template ngbTabContent>
      <app-widget-location></app-widget-location>
    </ng-template>
  </ngb-tab>
  <ngb-tab title="{{ 'DASHBOARD.TABS.devices' | translate }}">
    <ng-template ngbTabContent>
      <app-device-dashboard></app-device-dashboard>
    </ng-template>
  </ngb-tab>
</ngb-tabset>
