<div
  #emailSidebar
  class="email-app-sidebar float-left">
  <div
    [perfectScrollbar]
    class="email-app-sidebar-content">
    <div class="email-app-menu">
      <div class="list-group list-group-messages pb-1">
        <a
          class="list-group-item no-border text-left"
          [ngClass]="filter?.unreadOnly != true && filter?.messageType === undefined ? 'active' : ' list-group-item-action'"
          (click)="getAllMessages($event)">
          <i class="ft-inbox mr-1 pr-1 float-left"></i>
          {{ 'MESSAGE.SHARED.list:all' | translate }}
          <span class="badge badge-success badge-pill float-right">{{ count?.allMessageCount }}</span>
        </a>
        <a
          class="list-group-item no-border text-left"
          [ngClass]="filter?.unreadOnly == true ? 'active' : ' list-group-item-action'"
          (click)="getUnreadMessages($event)">
          <i class="ft-mail mr-1 pr-1 float-left"></i>
          {{ 'MESSAGE.SHARED.list:unread' | translate }}
          <span
            class="badge badge-danger badge-pill float-right"
            *ngIf="count?.unreadMessageCount > 0"
            >{{ count?.unreadMessageCount }}</span
          >
        </a>
      </div>
      <h6 class="text-muted text-bold-500 ml-1 my-2 text-left">
        {{ 'MESSAGE.SHARED.list:categories' | translate }}
      </h6>
      <div class="list-group list-group-messages">
        <a
          class="list-group-item no-border text-left"
          [ngClass]="filter?.messageType === MessageType.Reminder ? 'active' : ' list-group-item-action'"
          (click)="getMessagesByType($event, MessageType.Reminder)">
          <i class="ft-circle mr-1 pr-1 float-left warning"></i>
          {{ 'APPLICATION.DICTIONARIES.MESSAGES_TYPES.reminder' | translate }}
          <span
            class="badge badge-warning badge-pill float-right"
            *ngIf="count?.reminderMessageCount > 0"
            >{{ count?.reminderMessageCount }}</span
          >
        </a>
        <a
          class="list-group-item no-border text-left"
          [ngClass]="filter?.messageType === MessageType.Alert ? 'active' : ' list-group-item-action'"
          (click)="getMessagesByType($event, MessageType.Alert)">
          <i class="ft-circle mr-1 pr-1 float-left danger"></i>
          {{ 'APPLICATION.DICTIONARIES.MESSAGES_TYPES.alert' | translate }}
          <span
            class="badge badge-danger badge-pill float-right"
            *ngIf="count?.alertMessageCount > 0"
            >{{ count?.alertMessageCount }}</span
          >
        </a>
        <a
          class="list-group-item no-border text-left"
          [ngClass]="filter?.messageType === MessageType.Standard ? 'active' : ' list-group-item-action'"
          (click)="getMessagesByType($event, MessageType.Standard)">
          <i class="ft-circle mr-1 pr-1 float-left primary"></i>
          {{ 'APPLICATION.DICTIONARIES.MESSAGES_TYPES.standard' | translate }}
          <span
            class="badge badge-primary badge-pill float-right"
            *ngIf="count?.standardMessageCount > 0"
            >{{ count?.standardMessageCount }}</span
          >
        </a>
      </div>
    </div>
  </div>
</div>
