<div
  class="card"
  appPanelFullscreen>
  <div class="d-flex flex-column flex-md-row justify-content-md-between card-header pt-3">
    <label class="px-0">
      <h5 class="m-0">
        <i class="ft ft-list"></i>
        {{ 'CULTIVATION.fieldCardForm.groundTable.header' | translate }}
      </h5>
    </label>
    <app-panel-fullscreen-button></app-panel-fullscreen-button>
  </div>
  <div class="card-content">
    <div class="card-body">
      <div class="container-fluid table-container">
        <cdk-table [dataSource]="dataSourceSubject$">
          <ng-container cdkColumnDef="name">
            <div
              cdk-header-cell
              *cdkHeaderCellDef
              class="col-3 pr-0">
              {{ 'CULTIVATION.fieldCardForm.groundTable.name' | translate }}
            </div>
            <div
              cdk-cell
              *cdkCellDef="let element"
              class="col-3 pr-0">
              {{ element.name }}
            </div>
          </ng-container>
          <ng-container cdkColumnDef="areaInHa">
            <div
              cdk-header-cell
              *cdkHeaderCellDef
              class="col-2 pr-0">
              {{ 'CULTIVATION.fieldCardForm.groundTable.areaInHa' | translate }}
            </div>
            <div
              cdk-cell
              *cdkCellDef="let element"
              class="col-2 pr-0">
              {{ element.areaInHa }}
            </div>
          </ng-container>
          <ng-container cdkColumnDef="action">
            <div
              cdk-header-cell
              *cdkHeaderCellDef
              class="col-7 text-md-right">
              {{ 'APPLICATION.LIST.action' | translate }}
            </div>
            <div
              cdk-cell
              *cdkCellDef="let element"
              class="col-7 p-0 pl-1 pl-md-0 pr-0 pr-md-1 w-100">
              <div class="sticky-action">
                <div class="row no-gutters justify-content-md-end">
                  <button
                    (click)="onShowOnMap(element)"
                    title="{{ 'GROUND.LIST.GROUNDS.TABLE.showOnMap:button' | translate }}"
                    class="btn btn-raised m-0 my-1 mr-1 btn-sm btn-info">
                    <i
                      class="ft ft-search"
                      aria-hidden="true"></i>
                  </button>
                  <button
                    (click)="onDetails(element)"
                    title="{{ 'GROUND.LIST.GROUNDS.TABLE.details:button' | translate }}"
                    class="btn btn-raised m-0 my-1 mr-1 btn-sm btn-info">
                    <i
                      class="ft ft-info"
                      aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
          <cdk-header-row
            class="row header-navbar py-2 extra_bg-light-grey"
            *cdkHeaderRowDef="displayedColumns">
          </cdk-header-row>
          <cdk-row
            class="row row-striped align-items-center"
            *cdkRowDef="let row; columns: displayedColumns">
          </cdk-row>
        </cdk-table>
        <div
          class="row"
          *ngIf="dataSourceSubject$.value.length == 0">
          <div class="col text-center py-3">
            {{ 'CULTIVATION.fieldCardForm.groundTable.list:empty' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
