<div
  ngbDropdown
  [placement]="'bottom-right'">
  <a
    class="nav-link position-relative"
    id="dropdownBasic2"
    ngbDropdownToggle>
    <i class="ft-bell font-medium-3 blue-grey darken-4"></i>
    <span
      *ngIf="unreadMessageCount$ | async as unreadMessageCount"
      class="notification badge badge-pill badge-danger"
      >{{ unreadMessageCount }}</span
    >
  </a>
  <div
    ngbDropdownMenu
    aria-labelledby="dropdownBasic2"
    class="notification-dropdown">
    <div
      class="noti-list"
      [perfectScrollbar]
      *ngIf="unreadMessages$ | async as unreadMessages">
      <a
        [routerLink]="['/messages', message.id]"
        class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4"
        *ngFor="let message of unreadMessages; trackBy: trackByFn">
        <i
          class="float-left d-block font-large-1 mt-1 mr-2"
          [ngClass]="message?.type | messageTypeIcon"></i>
        <span class="noti-wrapper">
          <span class="noti-title line-height-1 d-block text-bold-400 info">{{ message.title | htmlTextShort: 20 }}</span>
          <span class="noti-text">{{ message.body | htmlTextShort: 50 }}</span>
        </span>
      </a>
      <div
        class="h-100 d-flex justify-content-center"
        *ngIf="unreadMessages.length === 0">
        <div class="align-self-center">
          {{ 'MESSAGE.NAVBAR.list:empty' | translate }}
        </div>
      </div>
    </div>
    <a
      routerLink="/messages"
      class="noti-footer primary text-center d-block border-top border-top-blue-grey border-top-lighten-4 text-bold-400 py-1"
      >{{ 'MESSAGE.NAVBAR.viewAll' | translate }}</a
    >
  </div>
</div>
