import { OnInit, OnDestroy } from '@angular/core';
import { forkJoin } from 'rxjs';
import { SpinnerService, FeatureService, AppConfigurationService } from 'crmcloud-core';
import { ROUTES } from '@crm-portal/app-routing.config';
import { AuthService } from '@crm-portal/core/auth/services/auth.service';
import { AuthStoreService } from '@crm-portal/core/auth/services/auth-store.service';
var FullLayoutComponent = /** @class */ (function () {
    function FullLayoutComponent(spinnerService, authService, authStore, appConfigurationService, featureService) {
        this.spinnerService = spinnerService;
        this.authService = authService;
        this.authStore = authStore;
        this.appConfigurationService = appConfigurationService;
        this.featureService = featureService;
        this.iscollapsed = false;
    }
    Object.defineProperty(FullLayoutComponent.prototype, "groupClaims", {
        get: function () {
            return this.authStore.getUserClaims;
        },
        enumerable: true,
        configurable: true
    });
    FullLayoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.displaySpinner$ = this.spinnerService.display$;
        this.loadConfigurationSubscription = forkJoin({
            features: this.featureService.loadFeatures(),
        }).subscribe(function (config) {
            _this.routes = _this.recursiveRouteFilter(ROUTES, config.features);
        });
    };
    FullLayoutComponent.prototype.ngOnDestroy = function () {
        if (this.loadConfigurationSubscription) {
            this.loadConfigurationSubscription.unsubscribe();
        }
        if (this.logoutSub) {
            this.logoutSub.unsubscribe();
        }
    };
    FullLayoutComponent.prototype.toggleHideSidebar = function ($event) {
        var _this = this;
        setTimeout(function () {
            _this.hideSidebar = $event;
        }, 0);
    };
    FullLayoutComponent.prototype.logout = function () {
        this.logoutSub = this.authService.logout().subscribe();
    };
    FullLayoutComponent.prototype.hasAccess = function (componentKey) {
        return this.groupClaims.some(function (claim) {
            return claim.startsWith(componentKey);
        });
    };
    FullLayoutComponent.prototype.recursiveRouteFilter = function (routes, featureConfig) {
        var _this = this;
        var result = [];
        routes.forEach(function (route) {
            if (featureConfig.isEnabled(route.title)) {
                _this.checkIfExternalAndUpdatePath(route);
                if (route.submenu && route.submenu.length) {
                    route.submenu = _this.recursiveRouteFilter(route.submenu, featureConfig);
                }
                result.push(route);
            }
        });
        return result;
    };
    FullLayoutComponent.prototype.checkIfExternalAndUpdatePath = function (route) {
        if (route.isExternalLink && route.title === 'APPLICATION.MENU.supplierPortal') {
            route.path = this.appConfigurationService.configuration.supplier_portal_url;
        }
    };
    return FullLayoutComponent;
}());
export { FullLayoutComponent };
