import { BehaviorSubject, of, Subject, concat } from 'rxjs';
import { tap, delay, skip, takeUntil, concatMap, catchError, map } from 'rxjs/operators';
import { AppConfigurationService } from 'crmcloud-core';
import { MessageCoreProxyService } from './message-core-proxy.service';
import * as i0 from "@angular/core";
import * as i1 from "./message-core-proxy.service";
import * as i2 from "crmcloud-core";
import * as i3 from "../message-core-services.module";
var MESSAGERE_FRESH_RATE_IN_MS = 60000;
var MessageCoreNotificationService = /** @class */ (function () {
    function MessageCoreNotificationService(messageCoreProxyService, config) {
        var _this = this;
        this.messageCoreProxyService = messageCoreProxyService;
        this.config = config;
        this.unreadMessageCount = new BehaviorSubject(0);
        this.load$ = new BehaviorSubject('');
        this.refresh$ = new Subject();
        if (JSON.parse(this.config.configuration.messages_enabled)) {
            this.unreadMessageCount$ = this.unreadMessageCount.asObservable();
            var getData$ = this.messageCoreProxyService.getAllUnread().pipe(catchError(function (_) {
                return of([]);
            }));
            var whenToRefresh$ = of([]).pipe(delay(MESSAGERE_FRESH_RATE_IN_MS), tap(function (_) { return _this.load$.next(''); }), skip(1), takeUntil(this.refresh$));
            this.poll$ = concat(getData$, whenToRefresh$);
        }
    }
    MessageCoreNotificationService.prototype.getUnreadMessages = function () {
        var _this = this;
        if (JSON.parse(this.config.configuration.messages_enabled)) {
            return this.load$
                .pipe(concatMap(function (_) { return _this.poll$; }), tap(function (data) {
                _this.unreadMessageCount.next(data.length);
            }))
                .pipe(map(function (messages) {
                return messages.map(function (m) {
                    return {
                        id: m.id,
                        type: m.type,
                        title: m.title,
                        body: m.body,
                    };
                });
            }));
        }
    };
    MessageCoreNotificationService.prototype.getUnreadMessageCount = function () {
        return this.unreadMessageCount$;
    };
    MessageCoreNotificationService.prototype.refresh = function () {
        this.refresh$.next('');
        this.load$.next('');
    };
    MessageCoreNotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessageCoreNotificationService_Factory() { return new MessageCoreNotificationService(i0.ɵɵinject(i1.MessageCoreProxyService), i0.ɵɵinject(i2.AppConfigurationService)); }, token: MessageCoreNotificationService, providedIn: i3.MessageCoreServicesModule });
    return MessageCoreNotificationService;
}());
export { MessageCoreNotificationService };
