import { of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { NotificationService } from 'crmcloud-core';
import { WeatherStationCoreProxyService } from './weather-station-core-proxy.service';
import * as i0 from "@angular/core";
import * as i1 from "./weather-station-core-proxy.service";
import * as i2 from "crmcloud-core";
import * as i3 from "../weather-station-core-services.module";
var WeatherStationCoreService = /** @class */ (function () {
    function WeatherStationCoreService(weatherStationCoreProxyService, notificationService) {
        this.weatherStationCoreProxyService = weatherStationCoreProxyService;
        this.notificationService = notificationService;
    }
    WeatherStationCoreService.prototype.getAll = function (deviceType) {
        var requestList = {
            filter: {},
        };
        return this.find(deviceType, requestList).pipe(map(function (result) { return result.items; }));
    };
    WeatherStationCoreService.prototype.find = function (deviceType, requestList) {
        return this.weatherStationCoreProxyService.find(deviceType, requestList);
    };
    WeatherStationCoreService.prototype.get = function (deviceType, id) {
        return this.weatherStationCoreProxyService.get(deviceType, id);
    };
    WeatherStationCoreService.prototype.create = function (deviceType, model) {
        var _this = this;
        model.id = uuid();
        return this.weatherStationCoreProxyService.create(deviceType, model).pipe(tap(function () { return _this.notificationService.success("WEATHERSTATION." + deviceType + ".create:success"); }), catchError(function (error) {
            _this.notificationService.error(error, "WEATHERSTATION." + deviceType + ".create:errorMessage");
            return of(null);
        }));
    };
    WeatherStationCoreService.prototype.update = function (deviceType, id, model) {
        var _this = this;
        return this.weatherStationCoreProxyService.update(deviceType, id, model).pipe(tap(function () { return _this.notificationService.success("WEATHERSTATION." + deviceType + ".update:success"); }), map(function (_) { return true; }), catchError(function (error) {
            _this.notificationService.error(error, "WEATHERSTATION." + deviceType + ".update:errorMessage");
            return of(false);
        }));
    };
    WeatherStationCoreService.prototype.delete = function (deviceType, id) {
        var _this = this;
        return this.weatherStationCoreProxyService.delete(deviceType, id).pipe(tap(function () { return _this.notificationService.success("WEATHERSTATION." + deviceType + ".delete:success"); }), map(function (_) { return true; }), catchError(function (error) {
            _this.notificationService.error(error, "WEATHERSTATION." + deviceType + ".delete:errorMessage");
            return of(false);
        }));
    };
    WeatherStationCoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WeatherStationCoreService_Factory() { return new WeatherStationCoreService(i0.ɵɵinject(i1.WeatherStationCoreProxyService), i0.ɵɵinject(i2.NotificationService)); }, token: WeatherStationCoreService, providedIn: i3.WeatherStationCoreServicesModule });
    return WeatherStationCoreService;
}());
export { WeatherStationCoreService };
