<app-treatment-pesticides-form
  [(treatmentPesticides)]="treatmentPesticides"
  [translateKey]="translateKey"
  [totalAreaInHa]="totalAreaInHa"
  [tenantId]="tenantId"
  [cultivationCodeName]="cultivationCodeName"
  [cultivationTypeCodeName]="cultivationTypeCodeName"
  [minCountRequired]="minCountRequired"
  [touched]="touched">
</app-treatment-pesticides-form>
