<div
  class="container-fluid px-0"
  [appSpinner]="displaySpinner$ | async">
  <label class="px-0">
    <h5 class="m-0">
      <i class="ft ft-target"></i>
      <span *ngIf="fieldAuditArea">{{ fieldAuditArea.crop | dictionaryTranslateKey | translate }} - {{ fieldAuditArea.areaName }}</span>
    </h5>
  </label>
  <hr class="mt-0" />

  <form
    class="form-horizontal ng-dynamic-form"
    [formGroup]="formGroup"
    *ngIf="formGroup && formModel$ | async as formModel">
    <fieldset>
      <dynamic-ng-bootstrap-form
        class="row fieldAuditAreaFormRow"
        [group]="formGroup"
        [layout]="formLayout"
        [model]="formModel">
        <ng-template
          modelType="GROUP"
          align="START"
          let-legend="legend">
          <hr
            class="mt-0"
            [ngClass]="{ 'mt-0': true, 'mb-0': legend }" />
          <p
            *ngIf="legend"
            class="form-group-legend m-0 mb-1">
            {{ legend }}
          </p>
        </ng-template>
      </dynamic-ng-bootstrap-form>
    </fieldset>
  </form>
</div>
