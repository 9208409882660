import { of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { NotificationService } from 'crmcloud-core';
import { OrganizationCoreProxyService } from './organization-core-proxy.service';
import * as i0 from "@angular/core";
import * as i1 from "./organization-core-proxy.service";
import * as i2 from "crmcloud-core";
import * as i3 from "../organization-core-services.module";
var OrganizationCoreService = /** @class */ (function () {
    function OrganizationCoreService(organizationCoreProxyService, notificationService) {
        this.organizationCoreProxyService = organizationCoreProxyService;
        this.notificationService = notificationService;
    }
    OrganizationCoreService.prototype.getAll = function () {
        return this.organizationCoreProxyService.getAll();
    };
    OrganizationCoreService.prototype.find = function (requestList) {
        return this.organizationCoreProxyService.find(requestList);
    };
    OrganizationCoreService.prototype.get = function (id) {
        return this.organizationCoreProxyService.get(id);
    };
    OrganizationCoreService.prototype.getOrganizationSuppliers = function (id) {
        return this.organizationCoreProxyService.getOrganizationSuppliers(id);
    };
    OrganizationCoreService.prototype.create = function (organizationCommand) {
        var _this = this;
        organizationCommand.id = uuid();
        return this.organizationCoreProxyService.create(organizationCommand).pipe(tap(function () { return _this.notificationService.success('ORGANIZATION.SERVICE.create:success'); }), catchError(function (error) {
            _this.notificationService.error(error, 'ORGANIZATION.SERVICE.create:errorMessage');
            return of(null);
        }));
    };
    OrganizationCoreService.prototype.update = function (id, organizationCommand) {
        var _this = this;
        return this.organizationCoreProxyService.update(id, organizationCommand).pipe(tap(function () { return _this.notificationService.success('ORGANIZATION.SERVICE.update:success'); }), map(function () { return true; }), catchError(function (error) {
            _this.notificationService.error(error, 'ORGANIZATION.SERVICE.update:errorMessage');
            throw of(false);
        }));
    };
    OrganizationCoreService.prototype.delete = function (id) {
        var _this = this;
        return this.organizationCoreProxyService.delete(id).pipe(tap(function () { return _this.notificationService.success('ORGANIZATION.SERVICE.delete:success'); }), map(function () { return true; }), catchError(function (error) {
            _this.notificationService.error(error, 'ORGANIZATION.SERVICE.delete:errorMessage');
            return of(false);
        }));
    };
    OrganizationCoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrganizationCoreService_Factory() { return new OrganizationCoreService(i0.ɵɵinject(i1.OrganizationCoreProxyService), i0.ɵɵinject(i2.NotificationService)); }, token: OrganizationCoreService, providedIn: i3.OrganizationCoreServicesModule });
    return OrganizationCoreService;
}());
export { OrganizationCoreService };
