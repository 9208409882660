import { DashboardComponent } from '@crm-portal/modules/dashboard';
import { FullLayoutComponent } from '@crm-portal/core/layout';
import { DeviceType, ProductCatalogAuditViewType } from 'crmcloud-core';
import { LoadUserDataComponent } from './core/layout/load-user-data/load-user-data.component';
import { IdpAuthGuard } from './core/auth/idp-auth.guard';
import { IdpOrganizationGuard } from './core/auth/idp-organization.guard';
import { Permissons } from './core/auth/models/permissons.enum';
var ɵ0 = {
    componentKey: 'Organization.Dashboard',
    permissions: [Permissons.Read, Permissons.List],
}, ɵ1 = {
    componentKey: 'Organization.Dashboard',
    permissions: [Permissons.Read, Permissons.List],
}, ɵ2 = { viewType: ProductCatalogAuditViewType.Technologist }, ɵ3 = { viewType: ProductCatalogAuditViewType.Technologist }, ɵ4 = { viewType: ProductCatalogAuditViewType.SecurityOffice }, ɵ5 = { deviceType: DeviceType.WeatherStation }, ɵ6 = { deviceType: DeviceType.MoistureSensor };
var appRoutes = [
    {
        path: 'load-user-data',
        component: LoadUserDataComponent,
        canActivate: [IdpAuthGuard],
    },
    {
        path: 'auth',
        loadChildren: './modules/authorization/authorization.module#AuthorizationModule',
    },
    {
        path: '',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                component: DashboardComponent,
                data: ɵ0,
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
                data: ɵ1,
            },
            {
                path: 'users',
                loadChildren: './modules/user/user.module#UserModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'suppliers',
                loadChildren: './modules/supplier/supplier.module#SupplierModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'contracts',
                loadChildren: './modules/contract/contract.module#ContractModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'deliveries',
                loadChildren: './modules/delivery/delivery.module#DeliveryModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'organizations',
                loadChildren: './modules/organization/organization.module#OrganizationModule',
                canLoad: [IdpAuthGuard],
            },
            {
                path: 'contacts',
                loadChildren: './modules/contact/contact.module#ContactModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'product-catalog-infos',
                loadChildren: './modules/product-catalog-info/product-catalog-info.module#ProductCatalogInfoModule',
                data: ɵ2,
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'employee-tasks',
                loadChildren: './modules/employee-task/employee-task.module#EmployeeTaskModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'farm-audits',
                loadChildren: './modules/farm-audit/farm-audit.module#FarmAuditModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'field-audits',
                loadChildren: './modules/field-audit/field-audit.module#FieldAuditModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'product-catalog-audits',
                loadChildren: './modules/product-catalog-audit/product-catalog-audit.module#ProductCatalogAuditModule',
                data: ɵ3,
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'product-catalog-audit-results',
                loadChildren: './modules/product-catalog-audit/product-catalog-audit.module#ProductCatalogAuditModule',
                data: ɵ4,
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'weatherstations',
                loadChildren: './modules/weather-station/weather-station.module#WeatherStationModule',
                data: ɵ5,
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'moisturesensors',
                loadChildren: './modules/weather-station/weather-station.module#WeatherStationModule',
                data: ɵ6,
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'messages',
                loadChildren: './modules/message/message.module#MessageModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'notes',
                loadChildren: './modules/note/note.module#NoteModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'vehtel',
                loadChildren: './modules/vehtel/vehtel.module#VehTelModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'support',
                loadChildren: './modules/support/support.module#SupportModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'pesticides',
                loadChildren: './modules/pesticide/pesticide.module#PesticideModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'seeds',
                loadChildren: './modules/seed/seed.module#SeedModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'fertilizers-db',
                loadChildren: './modules/fertilizer-db/fertilizer-db.module#FertilizerDbModule',
            },
            {
                path: 'catalog-definitions',
                loadChildren: './modules/catalog-definition/catalog-definition.module#CatalogDefinitionModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'products',
                loadChildren: './modules/product/product.module#ProductModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'fertilizer-stock-needs',
                loadChildren: './modules/fertilizer-stock-needs/fertilizer-stock-needs.module#FertilizerStockNeedsModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'transport-calculations',
                loadChildren: './modules/transport-calculation/transport-calculation.module#TransportCalculationModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'migration',
                loadChildren: './modules/migration/migration.module#MigrationModule',
            },
            {
                path: 'news',
                loadChildren: './modules/news/news.module#NewsModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: 'report',
                loadChildren: './modules/report/report.module#ReportModule',
                canLoad: [IdpAuthGuard],
                canActivateChild: [IdpOrganizationGuard],
            },
            {
                path: '**',
                redirectTo: 'dashboard',
            },
        ],
        canActivate: [IdpAuthGuard],
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
