<div
  class="ground-list mb-2"
  [appSpinner]="dataSource.loading$ | async">
  <div
    class="card"
    appPanelFullscreen>
    <div class="card-header">
      <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
    </div>
    <div
      class="card-content"
      [appSpinner]="displaySpinner$ | async">
      <div class="card-body pt-0">
        <div class="container-fluid px-0">
          <div class="row">
            <div class="col-12">
              <app-pure-map [height]="420"></app-pure-map>
              <div
                class="scale"
                *ngIf="this.scale">
                <div
                  class="element"
                  *ngFor="let color of scale"
                  [style.backgroundColor]="color.color">
                  <div>{{ color.limit }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="card"
    appPanelFullscreen>
    <div class="card-header pt-3">
      <label class="px-0">
        <h5 class="m-0">
          <i class="ft ft-list"></i>
          {{ schema.headerKey | translate }}
        </h5>
      </label>
      <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
    </div>
    <div class="card-content">
      <div class="card-body">
        <div class="container-fluid">
          <cdk-table
            [dataSource]="dataSource"
            cdkSort
            cdkSortActive="name"
            cdkSortDirection="desc">
            <ng-container cdkColumnDef="generatedDate">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                cdk-sort-header
                class="order-2 order-md-1 col-9 col-md-6 col-lg-3 pr-0">
                {{ schema.generatedDateKey | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="order-2 order-md-1 col-9 col-md-6 col-lg-3 pr-0">
                {{ element.generatedDate * 1000 | date: 'dd.MM.yyyy' }}
              </div>
            </ng-container>

            <ng-container cdkColumnDef="vehicle">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                cdk-sort-header
                class="order-3 order-md-2 col-md-3 d-none d-md-block pr-0">
                {{ schema.vehicleNameKey | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="order-3 order-md-2 col-md-3 d-none d-md-block pr-0">
                {{ element.vehicleName }}
              </div>
            </ng-container>

            <ng-container cdkColumnDef="machine">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                cdk-sort-header
                class="order-4 order-md-3 col-6 col-md-1 col-lg-2 d-none d-lg-block pr-0">
                {{ schema.machineNameKey | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="order-4 order-md-3 col-6 col-md-1 col-lg-2 d-none d-lg-block pr-0">
                {{ element.machineName }}
              </div>
            </ng-container>

            <ng-container cdkColumnDef="mapType">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                cdk-sort-header
                class="order-4 order-md-3 col-6 col-md-1 col-lg-2 d-none d-lg-block pr-0">
                {{ schema.mapTypeKey | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="order-4 order-md-3 col-6 col-md-1 col-lg-2 d-none d-lg-block pr-0">
                {{ 'APPLICATION.DICTIONARIES.CustomMapTypes.' + element.mapType | translate }}
              </div>
            </ng-container>

            <ng-container cdkColumnDef="action">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="order-1 order-md-4 col-3 col-md-3 col-lg-2 text-md-right">
                {{ schema.actionKey | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="order-1 order-md-4 col-3 col-md-3 col-lg-2 p-0 pl-1 pl-md-0 pr-0 pr-md-1 w-100">
                <div class="row no-gutters justify-content-md-end">
                  <button
                    (click)="showOnMapClick(element)"
                    title="{{ schema.showOnMapButtonKey | translate }}"
                    class="d-none d-md-block btn btn-raised m-0 my-1 mr-1 btn-sm btn-info">
                    <i
                      class="ft ft-search"
                      aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </ng-container>
            <cdk-header-row
              class="row header-navbar extra_bg-light-grey"
              *cdkHeaderRowDef="displayedColumns"></cdk-header-row>
            <cdk-row
              class="row row-striped align-items-center"
              *cdkRowDef="let row; columns: displayedColumns"></cdk-row>
          </cdk-table>
          <div
            class="row"
            *ngIf="dataSource.noData$ | async">
            <div class="col text-center py-3">
              {{ schema.listEmptyKey | translate }}
            </div>
          </div>
          <div
            class="row"
            *ngIf="dataSource.error$ | async">
            <div class="col text-center py-3">
              {{ schema.listErrorKey | translate }}
            </div>
          </div>
          <cdk-paginator
            class="row header-navbar d-flex justify-content-end pr-2 py-1"
            [pageSizeOptions]="[10, 25, 50, 75, 100]"
            showFirstLastButtons></cdk-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
