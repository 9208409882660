<div
  class="card"
  appPanelFullscreen>
  <div class="card-header">
    <div class="card-header d-flex pl-0">
      <div>
        <form class="form-inline">
          <div class="form-group">
            <div class="input-group col-9 col-md-5 mx-0 mr-md-1">
              <input
                class="form-control"
                placeholder="yyyy-mm-dd"
                name="dpFrom"
                [(ngModel)]="dateFrom"
                ngbDatepicker
                #d1="ngbDatepicker"
                (dateSelect)="onDateSelect($event)" />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d1.toggle()"
                  type="button"></button>
              </div>
            </div>
            <div class="input-group col-9 col-md-5 mx-0">
              <input
                class="form-control"
                placeholder="yyyy-mm-dd"
                name="dpTo"
                [(ngModel)]="dateTo"
                ngbDatepicker
                #d2="ngbDatepicker"
                (dateSelect)="onDateSelect($event)" />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d2.toggle()"
                  type="button"></button>
              </div>
            </div>
          </div>

          <div *ngIf="dateShowValidationError">
            <small class="form-text text-danger">
              {{ 'WEATHERSTATION.validation:dateFromAfterDateTo' | translate }}
            </small>
          </div>
        </form>
      </div>
      <div class="ml-auto">
        <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
      </div>
    </div>
  </div>
  <div class="card-content">
    <div class="card-body">
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-12 col-lg-7 order-lg-2 chart-container"
            [appSpinner]="displaySpinner$ | async">
            <canvas
              baseChart
              height="200"
              [datasets]="lineChartData"
              [options]="lineChartOptions"
              [colors]="lineChartColors"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"
              *ngIf="dataReady"></canvas>
            <div
              *ngIf="selectedMeasures.length === 0"
              class="text-center align-self-center">
              {{ 'WEATHERSTATION.chart:empty' | translate }}
            </div>
          </div>
          <div class="col-12 col-lg-5 order-lg-1 mt-3 mt-lg-2">
            <dl class="row">
              <dt class="col-9">
                {{ 'APPLICATION.DICTIONARIES.WeatherStationMeasurementTypes.AirTemperature' | translate }}
              </dt>
              <dd class="col-3">
                <ui-switch
                  size="small"
                  (valueChange)="onValueChange($event, WeatherStationMeasurementTypes.AirTemperature)"
                  [disabled]="!isMeasureSelected(WeatherStationMeasurementTypes.AirTemperature) && !activateMeasureSelection"></ui-switch>
              </dd>

              <dt class="col-9">
                {{ 'APPLICATION.DICTIONARIES.WeatherStationMeasurementTypes.AirHumidity' | translate }}
              </dt>
              <dd class="col-3">
                <ui-switch
                  size="small"
                  (valueChange)="onValueChange($event, WeatherStationMeasurementTypes.AirHumidity)"
                  [disabled]="!isMeasureSelected(WeatherStationMeasurementTypes.AirHumidity) && !activateMeasureSelection"></ui-switch>
              </dd>

              <dt class="col-9">
                {{ 'APPLICATION.DICTIONARIES.WeatherStationMeasurementTypes.SoilTemperature' | translate }}
              </dt>
              <dd class="col-3">
                <ui-switch
                  size="small"
                  (valueChange)="onValueChange($event, WeatherStationMeasurementTypes.SoilTemperature)"
                  [disabled]="!isMeasureSelected(WeatherStationMeasurementTypes.SoilTemperature) && !activateMeasureSelection"></ui-switch>
              </dd>

              <dt class="col-9">
                {{ 'APPLICATION.DICTIONARIES.WeatherStationMeasurementTypes.SoilHumidityLevel1' | translate }}
              </dt>
              <dd class="col-3">
                <ui-switch
                  size="small"
                  (valueChange)="onValueChange($event, WeatherStationMeasurementTypes.SoilHumidityLevel1)"
                  [disabled]="
                    !isMeasureSelected(WeatherStationMeasurementTypes.SoilHumidityLevel1) && !activateMeasureSelection
                  "></ui-switch>
              </dd>

              <dt class="col-9">
                {{ 'APPLICATION.DICTIONARIES.WeatherStationMeasurementTypes.SoilHumidityLevel2' | translate }}
              </dt>
              <dd class="col-3">
                <ui-switch
                  size="small"
                  (valueChange)="onValueChange($event, WeatherStationMeasurementTypes.SoilHumidityLevel2)"
                  [disabled]="
                    !isMeasureSelected(WeatherStationMeasurementTypes.SoilHumidityLevel2) && !activateMeasureSelection
                  "></ui-switch>
              </dd>

              <dt class="col-9">
                {{ 'APPLICATION.DICTIONARIES.WeatherStationMeasurementTypes.SoilHumidityLevel3' | translate }}
              </dt>
              <dd class="col-3">
                <ui-switch
                  size="small"
                  (valueChange)="onValueChange($event, WeatherStationMeasurementTypes.SoilHumidityLevel3)"
                  [disabled]="
                    !isMeasureSelected(WeatherStationMeasurementTypes.SoilHumidityLevel3) && !activateMeasureSelection
                  "></ui-switch>
              </dd>

              <dt
                class="col-9"
                *ngIf="isWeatherStationDeviceType">
                {{ 'APPLICATION.DICTIONARIES.WeatherStationMeasurementTypes.WindSpeed' | translate }}
              </dt>
              <dd
                class="col-3"
                *ngIf="isWeatherStationDeviceType">
                <ui-switch
                  size="small"
                  (valueChange)="onValueChange($event, WeatherStationMeasurementTypes.WindSpeed)"
                  [disabled]="!isMeasureSelected(WeatherStationMeasurementTypes.WindSpeed) && !activateMeasureSelection"></ui-switch>
              </dd>

              <dt
                class="col-9"
                *ngIf="isWeatherStationDeviceType">
                {{ 'APPLICATION.DICTIONARIES.WeatherStationMeasurementTypes.WindDirection' | translate }}
              </dt>
              <dd
                class="col-3"
                *ngIf="isWeatherStationDeviceType">
                <ui-switch
                  size="small"
                  (valueChange)="onValueChange($event, WeatherStationMeasurementTypes.WindDirection)"
                  [disabled]="!isMeasureSelected(WeatherStationMeasurementTypes.WindDirection) && !activateMeasureSelection"></ui-switch>
              </dd>

              <dt
                class="col-9"
                *ngIf="isWeatherStationDeviceType">
                {{ 'APPLICATION.DICTIONARIES.WeatherStationMeasurementTypes.RainCount' | translate }}
              </dt>
              <dd
                class="col-3"
                *ngIf="isWeatherStationDeviceType">
                <ui-switch
                  size="small"
                  (valueChange)="onValueChange($event, WeatherStationMeasurementTypes.RainCount)"
                  [disabled]="!isMeasureSelected(WeatherStationMeasurementTypes.RainCount) && !activateMeasureSelection"></ui-switch>
              </dd>

              <dt
                class="col-9"
                *ngIf="isWeatherStationDeviceType">
                {{ 'APPLICATION.DICTIONARIES.WeatherStationMeasurementTypes.SunLevel' | translate }}
              </dt>
              <dd
                class="col-3"
                *ngIf="isWeatherStationDeviceType">
                <ui-switch
                  size="small"
                  (valueChange)="onValueChange($event, WeatherStationMeasurementTypes.SunLevel)"
                  [disabled]="!isMeasureSelected(WeatherStationMeasurementTypes.SunLevel) && !activateMeasureSelection"></ui-switch>
              </dd>

              <dt
                class="col-9"
                *ngIf="isWeatherStationDeviceType">
                {{ 'APPLICATION.DICTIONARIES.WeatherStationMeasurementTypes.AirPressure' | translate }}
              </dt>
              <dd
                class="col-3"
                *ngIf="isWeatherStationDeviceType">
                <ui-switch
                  size="small"
                  (valueChange)="onValueChange($event, WeatherStationMeasurementTypes.AirPressure)"
                  [disabled]="!isMeasureSelected(WeatherStationMeasurementTypes.AirPressure) && !activateMeasureSelection"></ui-switch>
              </dd>

              <dt class="col-9">
                {{ 'APPLICATION.DICTIONARIES.WeatherStationMeasurementTypes.BatteryPercentage' | translate }}
              </dt>
              <dd class="col-3">
                <ui-switch
                  size="small"
                  (valueChange)="onValueChange($event, WeatherStationMeasurementTypes.BatteryPercentage)"
                  [disabled]="
                    !isMeasureSelected(WeatherStationMeasurementTypes.BatteryPercentage) && !activateMeasureSelection
                  "></ui-switch>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
