<div class="row">
  <div
    class="col-1"
    *ngFor="let vehTel of filteredElements().vehTel">
    <div [ngClass]="['card', 'device-card', getDeviceStatus(vehTel.lastOnline)]">
      <div class="serial">
        {{ vehTel.imei }}
      </div>
      <i class="ft ft-navigation"></i>
      <div class="last-online">
        <span *ngIf="vehTel.lastOnline">{{ vehTel.lastOnline | date: 'dd-MM-yyyy HH:mm' }}</span>
      </div>
      <div class="owner">
        <span *ngIf="vehTel.machine">{{ vehTel.machine.organizationName }}</span>
      </div>
      <div class="machine">
        <span *ngIf="vehTel.machine">{{ vehTel.machine.name }}</span>
      </div>
    </div>
  </div>
  <div
    class="col-1"
    *ngFor="let weather of filteredElements().weather">
    <div [ngClass]="['card', 'device-card', getDeviceStatus(weather.lastActiveDate)]">
      <div class="serial">
        {{ weather.serialNumber }}
      </div>
      <i
        class="ft"
        [class]="getDeviceIcon(weather)"></i>
      <div class="last-online">
        <span *ngIf="weather.lastActiveDate">{{ weather.lastActiveDate | date: 'medium' }}</span>
      </div>
      <div class="owner">
        <span>{{ weather.organizationName }}</span>
      </div>
    </div>
  </div>
</div>
