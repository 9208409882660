import { Injectable } from '@angular/core';
import { AuthStoreService } from './services/auth-store.service';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  constructor(private authStoreService: AuthStoreService) {}

  get currentTenantId() {
    return this.authStoreService.getUserOrganizationId;
  }
}
