<div [appSpinner]="displaySpinner$ | async">
  <div
    class="card"
    appPanelFullscreen>
    <div class="card-header">
      <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
    </div>
    <div class="card-content">
      <div class="card-body">
        <app-form
          *ngIf="formModel"
          #formDetail
          [startFromIsEditing]="startFromIsEditing"
          [formModel]="formModel"
          [formLayout]="formLayout"
          [model]="initValue"
          [translateKey]="'MACHINE'"
          (save)="save($event)">
        </app-form>
      </div>
    </div>
  </div>
</div>
