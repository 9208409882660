<div [appSpinner]="displaySpinner$ | async">
  <div class="d-flex">
    <h2>
      <span class="d-none d-md-inline">{{ header | translate }}</span>
    </h2>
    <div class="ml-auto">
      <a
        class="btn btn-raised btn-warning m-0"
        (click)="onCancel()"
        href="javascript:void(0)">
        <i class="ft-x"></i> {{ 'APPLICATION.FORM.cancel' | translate }}
      </a>
    </div>
  </div>
  <div
    class="card"
    appPanelFullscreen>
    <div class="card-header clearfix">
      <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
    </div>
    <div class="card-content">
      <div class="card-body">
        <div style="height: 400px">
          <app-map
            [features]="features"
            [mapConfig]="mapConfig"
            [markerConfig]="markerConfig"
            [polygonConfig]="polygonConfig">
          </app-map>
        </div>
        <div class="float-right mt-2 mb-2">
          <input
            type="file"
            #file
            style="display: none"
            (change)="onFileAdded($event)"
            onclick="this.value=null"
            accept=".json" />
          <a
            *ngIf="!isLoaded"
            class="btn btn-raised btn-primary m-0"
            (click)="onAddFile()"
            href="javascript:void(0)">
            <i class="ft-plus-circle"></i>
            {{ 'GROUND.import.selectFile' | translate }}
          </a>

          <a
            *ngIf="isLoaded"
            class="btn btn-raised btn-warning m-0 ml-1"
            (click)="onChangeFile()"
            href="javascript:void(0)">
            <i class="ft-edit-2"></i>
            {{ 'GROUND.import.change' | translate }}
          </a>

          <a
            *ngIf="isLoaded"
            class="btn btn-raised btn-primary m-0 ml-1"
            (click)="onSaveFile()"
            href="javascript:void(0)">
            <i class="ft-save"></i>
            {{ 'GROUND.import.save' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
