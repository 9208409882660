<form
  class="form-inline"
  [formGroup]="formGroup">
  <div class="form-group">
    <div class="input-group">
      <input
        class="form-control"
        name="dfrom"
        formControlName="from"
        ngbDatepicker
        #dfrom="ngbDatepicker"
        [minDate]="minDate"
        [maxDate]="to || maxDate" />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary calendar"
          (click)="dfrom.toggle()"
          type="button"></button>
      </div>
    </div>
  </div>
  <div class="form-group ml-1">
    <div class="input-group">
      <input
        class="form-control"
        name="dto"
        formControlName="to"
        ngbDatepicker
        #dto="ngbDatepicker"
        [minDate]="from || minDate"
        [maxDate]="maxDate" />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary calendar"
          (click)="dto.toggle()"
          type="button"></button>
      </div>
    </div>
  </div>
</form>
