import { ContactProxyService } from '@crm-portal/core/contact';
import { NotificationService } from 'crmcloud-core';
import { of } from 'rxjs';
import { map, catchError, switchMap, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { OrganizationProxyService } from './organization-proxy.service';
import * as i0 from "@angular/core";
import * as i1 from "./organization-proxy.service";
import * as i2 from "../../../../core/contact/contact-proxy.service";
import * as i3 from "crmcloud-core";
var OrganizationListService = /** @class */ (function () {
    function OrganizationListService(organizationProxyService, contactProxyService, notificationService) {
        this.organizationProxyService = organizationProxyService;
        this.contactProxyService = contactProxyService;
        this.notificationService = notificationService;
    }
    OrganizationListService.prototype.getList = function (requestList) {
        return this.organizationProxyService.getList(requestList);
    };
    OrganizationListService.prototype.getAssignedContactList = function (id) {
        return this.organizationProxyService.getAssignedContactList(id);
    };
    OrganizationListService.prototype.getContactList = function (id, term) {
        return this.organizationProxyService.getContactList(id, term);
    };
    OrganizationListService.prototype.assignToContact = function (id, contactId) {
        return this.organizationProxyService.assignToContact(id, contactId);
    };
    OrganizationListService.prototype.createContact = function (model, organizationId) {
        var _this = this;
        model.contactId = uuid();
        return this.contactProxyService.create(model).pipe(switchMap(function (id) { return _this.assignToContact(organizationId, model.contactId).pipe(map(function () { return id; })); }), map(function () {
            _this.notificationService.success("CONTACT.create:success");
            return model.contactId;
        }), catchError(function (error) {
            _this.notificationService.error(error, "CONTACT.create:errorMessage");
            return of(null);
        }));
    };
    OrganizationListService.prototype.getMachines = function (requestList, id) {
        return this.organizationProxyService.getMachines(id, requestList).pipe(map(function (x) { return ({
            items: x.items,
            pager: x.pager,
        }); }));
    };
    OrganizationListService.prototype.getMachine = function (organizationId, id) {
        return this.organizationProxyService.getMachine(organizationId, id);
    };
    OrganizationListService.prototype.updateMachine = function (organizationId, id, model) {
        var _this = this;
        return this.organizationProxyService.updateMachine(organizationId, id, model).pipe(tap(function () { return _this.notificationService.success("MACHINE.update:success"); }), catchError(function (error) {
            _this.notificationService.error(error, "MACHINE.update:errorMessage");
            throw error;
        }));
    };
    OrganizationListService.prototype.deleteMachine = function (organizationId, id) {
        var _this = this;
        return this.organizationProxyService.deleteMachine(organizationId, id).pipe(tap(function () { return _this.notificationService.success("MACHINE.delete:success"); }), catchError(function (error) {
            _this.notificationService.error(error, "MACHINE.delete:errorMessage");
            throw error;
        }));
    };
    OrganizationListService.prototype.createMachine = function (organizationId, model) {
        var _this = this;
        return this.organizationProxyService.createMachine(organizationId, model).pipe(tap(function () { return _this.notificationService.success("MACHINE.create:success"); }), catchError(function (error) {
            _this.notificationService.error(error, "MACHINE.create:errorMessage");
            throw error;
        }));
    };
    OrganizationListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrganizationListService_Factory() { return new OrganizationListService(i0.ɵɵinject(i1.OrganizationProxyService), i0.ɵɵinject(i2.ContactProxyService), i0.ɵɵinject(i3.NotificationService)); }, token: OrganizationListService, providedIn: "root" });
    return OrganizationListService;
}());
export { OrganizationListService };
