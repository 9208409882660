<div
  class="card"
  appPanelFullscreen>
  <div class="card-header pt-2">
    <h4 class="card-title mb-0">
      <i class="ft ft-home"></i>
      {{ 'ORGANIZATION.PROFILE.statistics' | translate }}
      <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
      <app-panel-hide-button
        [hidden]="false"
        (hiddenChange)="panelHide.setHidden($event)"
        class="float-right mr-1"></app-panel-hide-button>
    </h4>
  </div>
  <div class="card-content">
    <div class="card-body py-1 pt-3">
      <div
        class="container-fluid px-0"
        [appPanelHide]="false">
        <div class="row">
          <div class="col-6">
            <p>
              <b>{{ 'ORGANIZATION.PROFILE.cultivationsArea' | translate }}:</b>
              {{ summaryOfAreaInHa | number: '1.2-2' }}ha
            </p>
            <p>
              <b>{{ 'ORGANIZATION.PROFILE.dominantCrop' | translate }}:</b>
              {{ dominantCrop | dictionaryTranslateKey | translate }}
            </p>
          </div>
          <div class="col-6">
            <p>
              <b>{{ 'ORGANIZATION.PROFILE.ecologicalType' | translate }}:</b>
              {{ 'ORGANIZATION.PROFILE.' + isEcological | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
