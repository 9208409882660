/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/widget-location/widget-location.component.ngfactory";
import * as i3 from "../../components/widget-location/widget-location.component";
import * as i4 from "../../../../core/weather-station/services/weather-station-core.service";
import * as i5 from "crmcloud-core";
import * as i6 from "../../components/device-dashboard/device-dashboard.component.ngfactory";
import * as i7 from "../../components/device-dashboard/device-dashboard.component";
import * as i8 from "../../../../core/vehtel/vehtel-list.service";
import * as i9 from "../../../../core/organization/services/organization-core.service";
import * as i10 from "../../../../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i11 from "@ng-bootstrap/ng-bootstrap";
import * as i12 from "@ngx-translate/core";
import * as i13 from "./dashboard.component";
var styles_DashboardComponent = [i0.styles];
var RenderType_DashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardComponent, data: {} });
export { RenderType_DashboardComponent as RenderType_DashboardComponent };
function View_DashboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-widget-location", [], null, null, null, i2.View_WidgetLocationComponent_0, i2.RenderType_WidgetLocationComponent)), i1.ɵdid(1, 245760, null, 0, i3.WidgetLocationComponent, [i4.WeatherStationCoreService, i5.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_DashboardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-device-dashboard", [], null, null, null, i6.View_DeviceDashboardComponent_0, i6.RenderType_DeviceDashboardComponent)), i1.ɵdid(1, 114688, null, 0, i7.DeviceDashboardComponent, [i8.VehTelListService, i4.WeatherStationCoreService, i9.OrganizationCoreService, i5.SpinnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_DashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "ngb-tabset", [], null, null, null, i10.View_NgbTabset_0, i10.RenderType_NgbTabset)), i1.ɵdid(1, 2146304, null, 1, i11.NgbTabset, [i11.NgbTabsetConfig], null, null), i1.ɵqud(603979776, 1, { tabs: 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 6, "ngb-tab", [], null, null, null, null, null)), i1.ɵdid(4, 2113536, [[1, 4]], 2, i11.NgbTab, [], { title: [0, "title"] }, null), i1.ɵqud(603979776, 2, { titleTpls: 1 }), i1.ɵqud(603979776, 3, { contentTpls: 1 }), i1.ɵpid(131072, i12.TranslatePipe, [i12.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 1, null, View_DashboardComponent_1)), i1.ɵdid(9, 16384, [[3, 4]], 0, i11.NgbTabContent, [i1.TemplateRef], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 6, "ngb-tab", [], null, null, null, null, null)), i1.ɵdid(11, 2113536, [[1, 4]], 2, i11.NgbTab, [], { title: [0, "title"] }, null), i1.ɵqud(603979776, 4, { titleTpls: 1 }), i1.ɵqud(603979776, 5, { contentTpls: 1 }), i1.ɵpid(131072, i12.TranslatePipe, [i12.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 1, null, View_DashboardComponent_2)), i1.ɵdid(16, 16384, [[5, 4]], 0, i11.NgbTabContent, [i1.TemplateRef], null, null)], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 7).transform("DASHBOARD.TABS.locations")), ""); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 14).transform("DASHBOARD.TABS.devices")), ""); _ck(_v, 11, 0, currVal_1); }, null); }
export function View_DashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i1.ɵdid(1, 49152, null, 0, i13.DashboardComponent, [], null, null)], null, null); }
var DashboardComponentNgFactory = i1.ɵccf("app-dashboard", i13.DashboardComponent, View_DashboardComponent_Host_0, {}, {}, []);
export { DashboardComponentNgFactory as DashboardComponentNgFactory };
