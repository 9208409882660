<div [appSpinner]="displaySpinner$ | async">
  <app-add-form
    *ngIf="formModel"
    #addForm
    [formModel]="formModel"
    [formLayout]="formLayout"
    [model]="initValue"
    [translateKey]="translationKeyPrefix"
    (add)="onAdd($event)"
    (afterCancel)="onAfterCancel()">
  </app-add-form>
</div>
