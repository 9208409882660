import { DatePickerValue } from './date-picker-value.model';
import { DateTime } from 'luxon';

export function mapDatePickerValueToISOString(value: DatePickerValue) {
  if (value == null || typeof value === 'string') {
    return undefined;
  }

  return new Date(value.year, value.month - 1, value.day).toISOString();
}

export function mapDatePickerValueToUnixTimestamp(value: DatePickerValue) {
  if (value == null) {
    return undefined;
  }

  // tslint:disable-next-line:no-magic-numbers
  return new Date(value.year, value.month - 1, value.day).getTime() / 1000;
}

export function mapISOStringToDatePickerValue(value: string): DatePickerValue | null {
  const dateTime: DateTime = value == null ? null : DateTime.fromISO(value);

  if (dateTime == null) {
    return null;
  }

  return {
    year: dateTime.year,
    month: dateTime.month,
    day: dateTime.day,
  };
}

export function mapUnixTimestampToDatePickerValue(value: number): DatePickerValue | null {
  // tslint:disable-next-line:no-magic-numbers
  const date: Date = value == null ? null : new Date(value * 1000);

  if (date == null) {
    return null;
  }

  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
}
