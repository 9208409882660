<div class="paginator-bg">
  <div class="paginator-range-actions">
    <div class="paginator-range-label">
      {{ getRangeLabel(pageIndex, pageSize, length) }} {{ 'APPLICATION.LIST.of' | translate }} {{ getRangeLengthLabel(length) }}
    </div>
    <div class="btn-group btn-group-justified btn-group-raised btn-group-sm">
      <button
        type="button"
        class="btn btn-outline mr-1 mb-0"
        (click)="firstPage()"
        [disabled]="_previousButtonsDisabled()"
        *ngIf="showFirstLastButtons">
        <i class="ft ft-chevrons-left"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline mr-1 mb-0"
        (click)="previousPage()"
        [disabled]="_previousButtonsDisabled()">
        <i class="ft ft-chevron-left"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline mr-1 mb-0"
        (click)="nextPage()"
        [disabled]="_nextButtonsDisabled()">
        <i class="ft ft-chevron-right"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline mb-0"
        (click)="lastPage()"
        [disabled]="_nextButtonsDisabled()"
        *ngIf="showFirstLastButtons">
        <i class="ft ft-chevrons-right"></i>
      </button>
    </div>
  </div>
  <div class="break"></div>
  <div class="paginator-range-actions">
    <div class="paginator-range-label-count">{{ 'APPLICATION.PAGINATION.itemsCount' | translate}}</div>
    <div class="btn-group btn-group-justified btn-group-raised btn-group-sm">
      <select
        [value]="pageSize"
        class="form-control ng-pristine ng-valid ng-touched"
        (change)="_changePageSize($event.target.value)">
        <option
          *ngFor="let pageSizeOption of _displayedPageSizeOptions"
          [value]="pageSizeOption">
          {{pageSizeOption}}
        </option>
      </select>
    </div>
  </div>
</div>
