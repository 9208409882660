<ng-container [formGroup]="group">
  <app-treatment-pesticides-form-control
    formControlName="{{ model.id }}"
    [translateKey]="model.translateKey"
    [totalAreaInHa]="model.totalAreaInHa"
    [tenantId]="model.tenantId"
    [cultivationCodeName]="model.cultivationCodeName"
    [cultivationTypeCodeName]="model.cultivationTypeCodeName"
    [minCountRequired]="model.minCountRequired"
    [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
    [touched]="control.touched"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)">
  </app-treatment-pesticides-form-control>
</ng-container>
