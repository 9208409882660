import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigurationService } from 'crmcloud-core';

import { AuthenticateResultDto, UserAuth, UserAuthDto, UserLogin } from '@crm-portal/core/auth/models/auth-models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private httpClient: HttpClient,
    private config: AppConfigurationService,
  ) {}

  getUserStatus(tenantId: string): Observable<string> {
    const url = `${this.config.configuration.api_url}/v1/organizations/${tenantId}/access`;
    return this.httpClient.get<string>(url).pipe(
      map(access => {
        const token = JSON.stringify(access);
        return btoa(unescape(encodeURIComponent(token)));
      }),
    );
  }

  public userRegister(user: UserAuth): Observable<UserAuthDto> {
    const url = `${this.config.configuration.api_url}/api/User`;
    return this.httpClient.post<UserAuthDto>(url, user);
  }

  public userLogin(user: UserLogin): Observable<AuthenticateResultDto> {
    const url = `${this.config.configuration.api_url}/api/TokenAuth/Authenticate`;
    return this.httpClient.post<AuthenticateResultDto>(url, user);
  }
}
