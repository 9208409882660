import { HttpClient } from '@angular/common/http';
import { AppConfigurationService, prepareListUrlWithFilter, prepareListUrl } from 'crmcloud-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "crmcloud-core";
var OrganizationProxyService = /** @class */ (function () {
    function OrganizationProxyService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
        this.apiUrl = this.config.configuration.api_url + "/v1/organizations";
    }
    OrganizationProxyService.prototype.getList = function (requestList) {
        return this.httpClient.get(prepareListUrlWithFilter(this.apiUrl, requestList, this.prepareFilterUrl));
    };
    OrganizationProxyService.prototype.getAssignedContactList = function (id) {
        return this.httpClient.get(this.config.configuration.api_url + "/api/contacts/Organization/" + id + "?assignedOnly=true");
    };
    OrganizationProxyService.prototype.getContactList = function (id, term) {
        return this.httpClient.get(this.config.configuration.api_url + "/api/contacts/Organization/" + id + "/?term=" + term);
    };
    OrganizationProxyService.prototype.assignToContact = function (id, contactId) {
        return this.httpClient.patch(this.config.configuration.api_url + "/api/contacts/" + contactId + "/Organization/" + id, {});
    };
    OrganizationProxyService.prototype.get = function (id) {
        return this.httpClient.get(this.apiUrl + "/" + id);
    };
    OrganizationProxyService.prototype.delete = function (id) {
        return this.httpClient.delete(this.apiUrl + "/" + id);
    };
    OrganizationProxyService.prototype.update = function (id, model) {
        return this.httpClient.put(this.apiUrl + "/" + id, model);
    };
    OrganizationProxyService.prototype.create = function (model) {
        return this.httpClient.post("" + this.apiUrl, model);
    };
    OrganizationProxyService.prototype.getMachines = function (id, requestList) {
        return this.httpClient.get(prepareListUrl(this.config.configuration.api_url + "/v1/organizations/" + id + "/machines", requestList));
    };
    OrganizationProxyService.prototype.getMachine = function (organizationId, id) {
        return this.httpClient.get(this.config.configuration.api_url + "/v1/organizations/" + organizationId + "/machines/" + id);
    };
    OrganizationProxyService.prototype.updateMachine = function (organizationId, id, model) {
        return this.httpClient.put(this.config.configuration.api_url + "/v1/organizations/" + organizationId + "/machines/" + id, model);
    };
    OrganizationProxyService.prototype.deleteMachine = function (organizationId, id) {
        return this.httpClient.delete(this.config.configuration.api_url + "/v1/organizations/" + organizationId + "/machines/" + id);
    };
    OrganizationProxyService.prototype.createMachine = function (organizationId, model) {
        return this.httpClient.post(this.config.configuration.api_url + "/v1/organizations/" + organizationId + "/machines", model);
    };
    OrganizationProxyService.prototype.getServiceEntries = function (organizationId, id) {
        return this.httpClient.get(this.config.configuration.api_url + "/v1/organizations/" + organizationId + "/machines/" + id + "/serviceCard");
    };
    OrganizationProxyService.prototype.createServiceEntry = function (organizationId, id, data) {
        return this.httpClient.post(this.config.configuration.api_url + "/v1/organizations/" + organizationId + "/machines/" + id + "/serviceCard", data);
    };
    OrganizationProxyService.prototype.prepareFilterUrl = function (filter) {
        var params = [];
        if (filter.name != null && filter.name !== '') {
            params.push("name=" + filter.name);
        }
        if (filter.city != null && filter.city !== '') {
            params.push("city=" + filter.city);
        }
        return params;
    };
    OrganizationProxyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrganizationProxyService_Factory() { return new OrganizationProxyService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfigurationService)); }, token: OrganizationProxyService, providedIn: "root" });
    return OrganizationProxyService;
}());
export { OrganizationProxyService };
