<ng-container [formGroup]="group">
  <lib-warehouse-inflow-items-form-control
    formControlName="{{ model.id }}"
    [translateKey]="model.translateKey"
    [productUnit]="model.productUnit"
    [minCountRequired]="model.minCountRequired"
    [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
    [touched]="control.touched"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)">
  </lib-warehouse-inflow-items-form-control>
</ng-container>
