<!-- Sidebar Header starts -->
<div class="sidebar">
  <div class="sidebar-header">
    <div class="logo clearfix">
      <a
        class="nav-toggle d-none d-sm-none d-md-none d-lg-block"
        id="sidebarToggle"
        href="javascript:;">
        <i
          #toggleIcon
          appSidebarToggle
          class="ft-toggle-right toggle-icon"
          data-toggle="expanded"></i>
      </a>
      <a
        class="nav-close d-block d-md-block d-lg-none d-xl-none"
        id="sidebarClose"
        href="javascript:;">
        <i class="ft-x"></i>
      </a>
    </div>
  </div>
  <!-- Sidebar Header Ends -->
  <!-- Sidebar Content starts -->
  <div
    class="sidebar-content"
    [perfectScrollbar]>
    <div class="nav-container">
      <ul
        class="navigation"
        appSidebarList>
        <!-- First level menu -->
        <li
          appSidebarlink
          level="{{ depth + 1 }}"
          (toggleEmit)="handleToggle($event)"
          (click)="toggleSlideInOut()"
          [routePath]="menuItem.path"
          [classes]="menuItem.class"
          [title]="menuItem.title"
          [parent]=""
          *ngFor="let menuItem of menuItems"
          [ngClass]="{
            'has-sub': menuItem.class === 'has-sub' ? true : false,
            open: activeTitles.includes(menuItem.title) && !nav_collapsed_open ? true : false,
            'nav-collapsed-open': nav_collapsed_open && activeTitles.includes(menuItem.title)
          }"
          [routerLinkActive]="menuItem.submenu.length != 0 ? '' : 'active'"
          [routerLinkActiveOptions]="{ exact: true }">
          <a
            class="1 hasSubmenuAndHasNotExternalLink"
            appSidebarAnchorToggle
            [routerLink]="menuItem.class === '' ? [menuItem.path] : null"
            *ngIf="hasSubmenuAndHasNotExternalLink(menuItem)">
            <i [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{ menuItem.title | translate }}</span>
            <span
              *ngIf="menuItem.badge != ''"
              [ngClass]="[menuItem.badgeClass]"
              >{{ menuItem.badge }}</span
            >
          </a>
          <a
            class="1 hasNotSubmenuAndExternalLink"
            appSidebarAnchorToggle
            [routerLink]="menuItem.class === '' ? [menuItem.path] : null"
            *ngIf="hasNotSubmenuAndExternalLink(menuItem)">
            <i [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{ menuItem.title | translate }}</span>
            <span
              *ngIf="menuItem.badge != ''"
              [ngClass]="[menuItem.badgeClass]"
              >{{ menuItem.badge }}</span
            >
          </a>
          <a
            class="1 hasMenuExternalLink"
            *ngIf="hasMenuExternalLink(menuItem)"
            [href]="[menuItem.path]"
            target="_blank">
            <i [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{ menuItem.title | translate }}</span>
            <span
              *ngIf="menuItem.badge != ''"
              [ngClass]="[menuItem.badgeClass]"
              >{{ menuItem.badge }}</span
            >
          </a>
          <!-- Second level menu -->
          <ul
            class="menu-content"
            *ngIf="menuItem.submenu.length > 0"
            [@slideInOut]="activeTitles.includes(menuItem.title) ? true : false">
            <li
              appSidebarlink
              level="{{ depth + 2 }}"
              (toggleEmit)="handleToggle($event)"
              [routePath]="menuSubItem.path"
              [classes]="menuSubItem.class"
              [title]="menuSubItem.title"
              [parent]="menuItem.title"
              *ngFor="let menuSubItem of menuItem.submenu"
              [routerLinkActive]="menuSubItem.submenu.length > 0 ? '' : 'active'"
              [ngClass]="{
                'has-sub': menuSubItem.class === 'has-sub' ? true : false,
                open: activeTitles.includes(menuSubItem.title) && !nav_collapsed_open ? true : false,
                'nav-collapsed-open': nav_collapsed_open && activeTitles.includes(menuSubItem.title)
              }">
              <a
                appSidebarAnchorToggle
                class="2 hasSubmenuAndHasNotExternalLink"
                [routerLink]="null"
                *ngIf="hasSubmenuAndHasNotExternalLink(menuSubItem)">
                <i [ngClass]="[menuSubItem.icon]"></i>
                <span class="menu-title">{{ menuSubItem.title | translate }}</span>
                <span
                  *ngIf="menuSubItem.badge != ''"
                  [ngClass]="[menuSubItem.badgeClass]"
                  >{{ menuSubItem.badge }}</span
                >
              </a>
              <a
                appSidebarAnchorToggle
                [routerLink]="[menuSubItem.path]"
                class="2 hasNotSubmenuAndExternalLink"
                *ngIf="hasNotSubmenuAndExternalLink(menuSubItem)">
                <i [ngClass]="[menuSubItem.icon]"></i>
                <span class="menu-title">{{ menuSubItem.title | translate }}</span>
                <span
                  *ngIf="menuSubItem.badge != ''"
                  [ngClass]="[menuSubItem.badgeClass]"
                  >{{ menuSubItem.badge }}</span
                >
              </a>
              <a
                class="2 hasMenuExternalLink"
                *ngIf="hasMenuExternalLink(menuSubItem)"
                [href]="[menuSubItem.path]">
                <i [ngClass]="[menuSubItem.icon]"></i>
                <span class="menu-title">{{ menuSubItem.title | translate }}</span>
                <span
                  *ngIf="menuSubItem.badge != ''"
                  [ngClass]="[menuSubItem.badgeClass]"
                  >{{ menuSubItem.badge }}</span
                >
              </a>
              <!-- Third level menu -->
              <ul
                class="menu-content"
                *ngIf="menuSubItem.submenu.length > 0"
                [@slideInOut]="activeTitles.includes(menuSubItem.title) ? true : false">
                <li
                  appSidebarlink
                  level="{{ depth + 3 }}"
                  [routePath]="menuSubsubItem.path"
                  [classes]="menuSubsubItem.class"
                  [title]="menuSubsubItem.title"
                  [parent]="menuSubItem.title"
                  *ngFor="let menuSubsubItem of menuSubItem.submenu"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  [ngClass]="[menuSubsubItem.class]">
                  <a
                    class="3 not hasMenuExternalLink"
                    appSidebarAnchorToggle
                    [routerLink]="[menuSubsubItem.path]"
                    *ngIf="!hasMenuExternalLink(menuSubsubItem)">
                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                    <span class="menu-title">{{ menuSubsubItem.title | translate }}</span>
                    <span
                      *ngIf="menuSubsubItem.badge != ''"
                      [ngClass]="[menuSubsubItem.badgeClass]"
                      >{{ menuSubsubItem.badge }}</span
                    >
                  </a>
                  <a
                    class="3 hasMenuExternalLink"
                    *ngIf="hasMenuExternalLink(menuSubsubItem)"
                    [href]="[menuSubsubItem.path]">
                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                    <span class="menu-title">{{ menuSubsubItem.title | translate }}</span>
                    <span
                      *ngIf="menuSubsubItem.badge != ''"
                      [ngClass]="[menuSubsubItem.badgeClass]"
                      >{{ menuSubsubItem.badge }}</span
                    >
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <!-- Sidebar Content Ends -->
  <div class="sidebar-footer">
    <div class="logo-footer">
      <ng-content select="[partnerLogo]"></ng-content>
    </div>
    <div
      class="version-footer"
      *ngIf="version">
      <p>
        {{ ('APPLICATION.MENU.version' | translate) + ': ' + version }}
      </p>
    </div>
  </div>
</div>
