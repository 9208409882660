import { OnInit } from '@angular/core';
import { TranslateService, LoggerService } from 'crmcloud-core';
var AppComponent = /** @class */ (function () {
    function AppComponent(translateService, loggerService) {
        this.translateService = translateService;
        this.loggerService = loggerService;
    }
    AppComponent.prototype.ngOnInit = function () {
        this.translateService.initialize();
        this.loggerService.initialize();
    };
    return AppComponent;
}());
export { AppComponent };
