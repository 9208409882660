import { OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppConfigurationService, FcTokenService } from 'crmcloud-core';
import { TenantService } from '@crm-portal/core/auth/tenant.service';
import { UserService } from '@crm-portal/core/auth/user.service';
var LoadUserDataComponent = /** @class */ (function () {
    function LoadUserDataComponent(config, userService, tenantService, fcTokenService, route, router, changeDetectorRef) {
        this.config = config;
        this.userService = userService;
        this.tenantService = tenantService;
        this.fcTokenService = fcTokenService;
        this.route = route;
        this.router = router;
        this.changeDetectorRef = changeDetectorRef;
        this.messageTranslationKey = 'APPLICATION.loadingData';
        this.organizationsUrlTemp = '/organizations';
    }
    LoadUserDataComponent.prototype.ngOnInit = function () {
        var _this = this;
        var returnUrl = this.route.snapshot.queryParams.returnUrl;
        if (returnUrl == null) {
            returnUrl = this.config.configuration.portal_start_url || this.organizationsUrlTemp;
        }
        if (returnUrl === '/') {
            returnUrl = this.config.configuration.portal_start_url || this.organizationsUrlTemp;
        }
        if (returnUrl.includes('id_token')) {
            returnUrl = this.config.configuration.portal_start_url || this.organizationsUrlTemp;
        }
        this.userService
            .getUserStatus(this.tenantService.currentTenantId)
            .pipe(tap(function (token) {
            _this.fcTokenService.setFcToken(token);
        }))
            .subscribe(function () {
            returnUrl == null ? _this.router.navigate([_this.organizationsUrlTemp]) : _this.router.navigateByUrl(returnUrl);
        }, function (_) {
            _this.messageTranslationKey = 'APPLICATION.ERRORS.loadingData';
            _this.changeDetectorRef.detectChanges();
        });
    };
    return LoadUserDataComponent;
}());
export { LoadUserDataComponent };
