import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, throwError, of, forkJoin } from 'rxjs';
import { catchError, filter, take, switchMap, finalize, mergeMap } from 'rxjs/operators';

import { AppConfigurationService, NotificationService } from 'crmcloud-core';

import { AuthProxyService } from './auth-proxy.service';
import { AuthStorageService } from './auth-storage.service';

import { RefreshTokenInputDto } from '../models/refresh-token-input.dto';
//TODO This import causes errors, but I cannot find a way to fix it
// import { FARM_PORTAL_TOKEN } from '../models/user.dto';
import { StorageKey } from '../models/storage-key.enum';
//NOTE This is a temporary solution, is should be replaced with import (see lines 13-14)
const AGRICRM_TOKEN = 'agricrm';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  private AUTH_HEADER = 'Authorization';
  private readonly apiRefreshUrl: string;

  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authProxyService: AuthProxyService,
    private storageService: AuthStorageService,
    private config: AppConfigurationService,
    private router: Router,
    private notificationService: NotificationService,
  ) {
    this.apiRefreshUrl = `${config.configuration.api_url}/api/TokenAuth/RefreshToken`;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.addAuthenticationToken(req).pipe(
      mergeMap((auhtorizerReq: HttpRequest<any>) => {
        return next.handle(auhtorizerReq).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error && error.status === 401) {
              // 401 errors are most likely going to be because we have an expired token that we need to refresh.
              if (this.refreshTokenInProgress) {
                //This is scenario when request refresh access token fails
                if (req.url.includes(this.apiRefreshUrl)) {
                  return throwError(error);
                }

                // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                // which means the new token is ready and we can retry the request again
                return this.refreshTokenSubject.pipe(
                  filter(result => result !== null),
                  take(1),
                  switchMap(() => this.addAuthenticationToken(req).pipe(mergeMap(res => next.handle(res)))),
                );
              } else {
                this.refreshTokenInProgress = true;

                // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                this.refreshTokenSubject.next(null);

                return this.refreshAccessToken().pipe(
                  switchMap((success: boolean) => {
                    this.refreshTokenSubject.next(success);
                    return this.addAuthenticationToken(req).pipe(mergeMap(res => next.handle(res)));
                  }),
                  // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                  // for the next time the token needs to be refreshed
                  finalize(() => (this.refreshTokenInProgress = false)),
                );
              }
            } else {
              return throwError(error);
            }
          }),
        );
      }),
    );
  }

  private refreshAccessToken(): Observable<any> {
    const getAccessToken = this.storageService.getItem(StorageKey.ACCESS_TOKEN_KEY);
    const getRefreshToken = this.storageService.getItem(StorageKey.REFRESH_TOKEN_KEY);

    return forkJoin([getAccessToken, getRefreshToken]).pipe(
      switchMap(([accessToken, refreshToken]) => {
        const refreshTokenInput = {
          accessToken,
          refreshToken,
          host: AGRICRM_TOKEN,
        } as RefreshTokenInputDto;
        return this.authProxyService.refreshToken(refreshTokenInput).pipe(
          catchError(err => {
            if (err && err.status && err.status === 401) {
              console.error(err, 'Error while refreshing token -> logout()');
              this.storageService.clearAll();
              this.notificationService.error(err, `AUTH.logoutError:message`);
              this.router.navigate(['auth', 'login']);
              return throwError(err);
            }
            return throwError(err);
          }),
        );
      }),
      mergeMap(res => {
        return this.storageService.setItem(StorageKey.ACCESS_TOKEN_KEY, res.accessToken).pipe(mergeMap(() => of(res)));
      }),
      catchError(err => {
        if (err && err.status && err.status === 401) {
          console.error(err, 'Error while refreshing token -> logout()');
          this.storageService.clearAll();
          this.router.navigate(['auth', 'login']);
          this.notificationService.error(err, `AUTH.logoutError:message`);
          return of(false);
        }
        console.error(err, 'Error while refreshing token -> err');
        return of(err);
      }),
    );
  }

  private addAuthenticationToken(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.

    return this.storageService.getItem<any>(StorageKey.ACCESS_TOKEN_KEY).pipe(
      mergeMap(res => {
        const token = res;
        if (!token) {
          return of(request);
        }

        // If you are calling for refreshing token.
        if (request.url.includes(this.apiRefreshUrl)) {
          return of(request);
        }

        // If you are calling an outside domain then do not add the token.
        if (!request.url.includes(this.config.configuration.api_url)) {
          return of(request);
        }

        return of(
          request.clone({
            headers: request.headers.set(this.AUTH_HEADER, `Bearer ${token}`),
          }),
        );
      }),
    );
  }
}

export let AuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptorService,
  multi: true,
};
