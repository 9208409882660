<div class="image-container">
  <div class="match-parent">
    <div
      class="not-selected"
      [ngClass]="{ over: isDraggingOverDropZone }"
      *ngIf="!isFile">
      <button
        type="button"
        class="add-image-btn"
        (click)="onImageClicked()">
        <div>
          <p class="plus">
            <i
              class="ft"
              [ngClass]="{
                'ft-plus': !readonly,
                'ft-image': readonly
              }"></i>
          </p>
          <span
            *ngIf="errorMessageTranslationKey"
            class="text-danger font-small-2"
            >{{ errorMessageTranslationKey | translate }}{{ errorMessageInfo }}</span
          >
        </div>
      </button>
    </div>
    <div
      class="selected-status-wrapper"
      *ngIf="isFile">
      <div class="hide">
        <div class="buttons-wrapper">
          <i
            role="button"
            tabindex="0"
            *ngIf="!noImageFile"
            (click)="openLightbox()"
            class="btn btn-raised btn-primary mb-0 ng-star-inserted custom-button zoom-button">
            <p class="button-icon">
              <i class="ft ft-zoom-in"></i>
            </p>
          </i>
          <i
            *ngIf="this._imageFile.link"
            role="button"
            tabindex="0"
            class="btn btn-raised btn-primary mb-0 ng-star-inserted custom-button"
            (click)="downloadFile()">
            <p class="button-icon">
              <i class="ft ft-download"></i>
            </p>
          </i>
        </div>
      </div>
      <img
        [src]="imageThumbnail"
        #imageElement />
      <span
        *ngIf="noImageFile"
        class="no-image-file-span"
        >{{ imageName.length > 15 ? (imageName | slice: 0 : 15) + '...' : imageName }}</span
      >
      <button
        type="button"
        class="remove"
        (click)="removeImage()"
        *ngIf="!readonly"
        [disabled]="isDisabled">
        ×
      </button>
    </div>
  </div>
  <fieldset [disabled]="isDisabled">
    <input
      type="file"
      #fileInput
      (change)="onFileChanged()" />
  </fieldset>
  <div
    class="drag-overlay"
    [hidden]="true"
    #dragOverlay></div>
</div>
