<ng-container [formGroup]="group">
  <app-treatment-fertilizer-form-control
    formControlName="{{ model.id }}"
    [translateKey]="model.translateKey"
    [totalAreaInHa]="model.totalAreaInHa"
    [fertilizerOptions]="model.fertilizerOptions"
    [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
    [touched]="control.touched"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)">
  </app-treatment-fertilizer-form-control>
</ng-container>
