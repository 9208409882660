import { HttpClient } from '@angular/common/http';
import { AppConfigurationService, prepareListUrlWithFilter } from 'crmcloud-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "crmcloud-core";
var VehTelProxyService = /** @class */ (function () {
    function VehTelProxyService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
        this.apiUrl = this.config.configuration.api_url + "/v1";
    }
    VehTelProxyService.prototype.getDeviceList = function (requestList) {
        return this.httpClient.get(prepareListUrlWithFilter(this.apiUrl + "/devices", requestList, this.prepareDeviceFilterUrl));
    };
    VehTelProxyService.prototype.getTagList = function (requestList) {
        return this.httpClient.get(prepareListUrlWithFilter(this.apiUrl + "/tags", requestList, this.prepareTagFilterUrl));
    };
    VehTelProxyService.prototype.getBeaconList = function (requestList) {
        return this.httpClient.get(prepareListUrlWithFilter(this.apiUrl + "/beacons", requestList, this.prepareTagFilterUrl));
    };
    VehTelProxyService.prototype.getMachines = function (trackable) {
        if (trackable === void 0) { trackable = true; }
        return this.httpClient.get(this.config.configuration.api_url + "/v1/organizations/machines?trackable=" + trackable);
    };
    VehTelProxyService.prototype.createDevice = function (model) {
        return this.httpClient.post(this.apiUrl + "/devices", model);
    };
    VehTelProxyService.prototype.createTag = function (model) {
        return this.httpClient.post(this.apiUrl + "/tags", model);
    };
    VehTelProxyService.prototype.createBeacon = function (model) {
        return this.httpClient.post(this.apiUrl + "/beacons", model);
    };
    VehTelProxyService.prototype.getDevice = function (imei) {
        return this.httpClient.get(this.apiUrl + "/devices/" + imei);
    };
    VehTelProxyService.prototype.getTag = function (serialNumber) {
        return this.httpClient.get(this.apiUrl + "/tags/" + serialNumber);
    };
    VehTelProxyService.prototype.getBeacon = function (serialNumber) {
        return this.httpClient.get(this.apiUrl + "/beacons/" + serialNumber);
    };
    VehTelProxyService.prototype.updateDevice = function (imei, model) {
        return this.httpClient.put(this.apiUrl + "/devices/" + imei, model);
    };
    VehTelProxyService.prototype.updateTag = function (serialNumber, model) {
        return this.httpClient.put(this.apiUrl + "/tags/" + serialNumber, model);
    };
    VehTelProxyService.prototype.updateBeacon = function (serialNumber, model) {
        return this.httpClient.put(this.apiUrl + "/beacons/" + serialNumber, model);
    };
    VehTelProxyService.prototype.getDeviceTypes = function () {
        return this.httpClient.get(this.apiUrl + "/devices/dictionaries/types");
    };
    VehTelProxyService.prototype.getExtraDevices = function () {
        return this.httpClient.get(this.apiUrl + "/devices/dictionaries/extra_devices");
    };
    VehTelProxyService.prototype.getTagTypes = function () {
        return this.httpClient.get(this.apiUrl + "/tags/dictionaries/types");
    };
    VehTelProxyService.prototype.getBeaconTypes = function () {
        return this.httpClient.get(this.apiUrl + "/beacons/dictionaries/types");
    };
    VehTelProxyService.prototype.getVehiclePosition = function (vehRefId) {
        return this.httpClient.get(this.apiUrl + "/vehicle/" + vehRefId + "/position");
    };
    VehTelProxyService.prototype.getVehicleStatus = function (vehRefId) {
        return this.httpClient.get(this.apiUrl + "/vehicle/" + vehRefId + "/status");
    };
    VehTelProxyService.prototype.getVehicleSessions = function (vehRefId) {
        return this.httpClient.get(this.apiUrl + "/vehicle/" + vehRefId + "/session");
    };
    VehTelProxyService.prototype.deleteDevice = function (imei) {
        return this.httpClient.delete(this.apiUrl + "/devices/" + imei);
    };
    VehTelProxyService.prototype.deleteTag = function (serialNumber) {
        return this.httpClient.delete(this.apiUrl + "/tags/" + serialNumber);
    };
    VehTelProxyService.prototype.getPermissions = function (imeiOrSn, deviceType) {
        return this.httpClient.get(this.apiUrl + "/" + deviceType + "/" + imeiOrSn + "/permissions");
    };
    VehTelProxyService.prototype.updatePermissions = function (imeiOrSn, deviceType, data) {
        return this.httpClient.put(this.apiUrl + "/" + deviceType + "/" + imeiOrSn + "/permissions", data);
    };
    VehTelProxyService.prototype.prepareDeviceFilterUrl = function (filter) {
        var params = [];
        if (filter.imei != null && filter.imei !== '') {
            params.push("imei=" + filter.imei);
        }
        return params;
    };
    VehTelProxyService.prototype.prepareTagFilterUrl = function (filter) {
        var params = [];
        if (filter.SerialNumber != null && filter.SerialNumber !== 0) {
            params.push("SerialNumber=" + filter.SerialNumber);
        }
        return params;
    };
    VehTelProxyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VehTelProxyService_Factory() { return new VehTelProxyService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfigurationService)); }, token: VehTelProxyService, providedIn: "root" });
    return VehTelProxyService;
}());
export { VehTelProxyService };
