<div
  class="card"
  appPanelFullscreen>
  <div class="card-header pt-3">
    <label class="px-0">
      <h5 class="m-0">
        <i class="ft ft-sliders"></i>
        {{ 'APPLICATION.SEARCH.title' | translate }}
      </h5>
    </label>
    <app-panel-fullscreen-button class="d-print-none float-right"></app-panel-fullscreen-button>
    <app-panel-hide-button
      [hidden]="!showOnStart"
      (hiddenChange)="panelHide.setHidden($event)"
      class="d-print-none float-right mr-1"></app-panel-hide-button>
  </div>
  <div class="card-content">
    <div class="card-body">
      <div
        class="container-fluid px-0"
        *ngIf="formModel$ | async as formModelx"
        [appPanelHide]="!showOnStart">
        <hr class="mt-0" />
        <form
          class="form-horizontal ng-dynamic-form"
          [formGroup]="formGroup"
          (keydown.enter)="searchClick()">
          <fieldset>
            <dynamic-ng-bootstrap-form
              class="row no-gutters"
              [group]="formGroup"
              [layout]="formLayout"
              [model]="formModelx">
              <ng-template
                modelType="GROUP"
                align="START"
                let-legend="legend">
                <hr
                  class="mt-0"
                  [ngClass]="{ 'mt-0': true, 'mb-0': legend }" />
                <p
                  *ngIf="legend"
                  class="form-group-legend m-0 mb-1">
                  {{ legend }}
                </p>
              </ng-template>
            </dynamic-ng-bootstrap-form>
          </fieldset>
          <div class="actions text-right pb-1 d-print-none d-flex justify-content-end">
            <button
              class="btn btn-raised btn-secondary mb-0"
              type="button"
              (click)="searchClick()">
              <i class="ft-search"></i>
              {{ 'APPLICATION.SEARCH.button' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
