<div
  class="mb-2"
  [appSpinner]="dataSource.loading$ | async">
  <div
    class="card"
    appPanelFullscreen>
    <div class="card-header pt-3">
      <label class="px-0">
        <h5 class="m-0">
          <i class="ft ft-list"></i>
          {{ 'MACHINE.generatedAlarms:header' | translate }}
        </h5>
      </label>
      <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
    </div>
    <div class="card-content">
      <div class="card-body">
        <div class="container-fluid">
          <cdk-table
            [dataSource]="dataSource"
            cdkSort
            cdkSortActive="name"
            cdkSortDirection="desc">
            <ng-container cdkColumnDef="alarmType">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-2 pr-0">
                {{ 'MACHINE.ALARMS.alarmType' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-2 pr-0">
                {{ element.alarmTypeTranslateKey | dictionaryTranslateKey | translate }}
              </div>
            </ng-container>

            <ng-container cdkColumnDef="date">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                cdk-sort-header
                class="col-2 pr-0">
                {{ 'MACHINE.ALARMS.date' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-2 pr-0">
                {{ element.timestamp | date: 'dd-MM-yyyy HH:mm' }}
              </div>
            </ng-container>

            <ng-container cdkColumnDef="location">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-2 pr-0">
                {{ 'MACHINE.ALARMS.location' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-2 pr-0">
                {{ element.ground?.name }}
              </div>
            </ng-container>

            <ng-container cdkColumnDef="machine">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-2 col-xl-3 pr-0">
                {{ 'MACHINE.ALARMS.machine' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-2 col-xl-3 pr-0">
                {{ element.machine?.name }}
              </div>
            </ng-container>

            <ng-container cdkColumnDef="worker">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-1 pr-0">
                {{ 'MACHINE.ALARMS.worker' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-1 pr-0">
                {{ element.worker?.fullName }}
              </div>
            </ng-container>

            <ng-container cdkColumnDef="action">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-3 col-xl-2 text-md-right">
                {{ 'APPLICATION.LIST.action' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-3 col-xl-2 p-0 pl-1 pl-md-0 pr-0 pr-md-1 w-100">
                <div class="row no-gutters justify-content-md-end">
                  <button
                    (click)="onDetailClick(element)"
                    title="{{ 'MACHINE.ALARMS.detail:button' | translate }}"
                    class="btn btn-raised m-0 my-1 mr-1 btn-sm btn-info">
                    <i
                      class="ft ft-info"
                      aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </ng-container>
            <cdk-header-row
              class="row header-navbar extra_bg-light-grey"
              *cdkHeaderRowDef="displayedColumns">
            </cdk-header-row>
            <cdk-row
              class="row row-striped align-items-center"
              *cdkRowDef="let row; columns: displayedColumns"></cdk-row>
          </cdk-table>
          <div
            class="row"
            *ngIf="dataSource.noData$ | async">
            <div class="col text-center py-3">
              {{ 'MACHINE.ALARMS.list:empty' | translate }}
            </div>
          </div>
          <div
            class="row"
            *ngIf="dataSource.error$ | async">
            <div class="col text-center py-3">
              {{ 'MACHINE.ALARMS.list:error' | translate }}
            </div>
          </div>
          <cdk-paginator
            class="row header-navbar d-flex justify-content-end pr-2 py-1"
            [pageSizeOptions]="[10, 25, 50, 75, 100]"
            showFirstLastButtons></cdk-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
