<ng-container *ngIf="drawingIsReady">
  <agm-polygon
    *ngFor="let polygon of polygons$ | async"
    [clickable]="polygon.clickable"
    [polyDraggable]="polygon.draggable"
    [editable]="polygon.editable"
    [fillColor]="polygon.fillColor"
    [fillOpacity]="polygon.fillOpacity"
    [geodesic]="polygon.geodesic"
    [paths]="polygon.paths"
    [strokeColor]="polygon.strokeColor"
    [strokeOpacity]="polygon.strokeOpacity"
    [strokeWeight]="polygon.strokeWeight"
    [visible]="polygon.visible"
    [zIndex]="polygon.zIndex"
    (polyClick)="onPolygonClick(polygon, $event)"
    (polyPathsChange)="onPolygonPathsChange(polygon, $event)">
    <agm-snazzy-info-window
      [maxWidth]="320"
      [closeWhenOthersOpen]="true"
      [latitude]="polygon.center.lat"
      [longitude]="polygon.center.lng"
      [isOpen]="polygon.showInfoWindow"
      (afterClose)="polygon.showInfoWindow = false">
      <ng-template>
        <div class="border">
          <div class="d-flex align-items-center justify-content-between py-1 pl-2 pr-1">
            <div
              class="polygon-content"
              [innerHTML]="polygon.name"></div>

            <div
              *ngIf="polygon.editable"
              class="align-self-start ml-2">
              <button
                (click)="deletePolygon(polygon)"
                class="d-none d-md-block btn btn-raised m-0 my-1 mr-1 btn-sm btn-danger">
                <i
                  class="ft ft-trash-2"
                  aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div
            *ngIf="polygon.navigate"
            class="d-flex align-items-center justify-content-between py-1 pl-2 pr-1">
            <a
              (click)="displayDetails(polygon)"
              class="d-none d-md-block btn btn-raised m-0 my-1 mr-1 btn-sm btn-info">
              <i
                class="ft ft-zoom-in"
                aria-hidden="true"></i>
              {{ 'APPLICATION.DETAILS.details:button' | translate }}
            </a>
          </div>
        </div>
      </ng-template>
    </agm-snazzy-info-window>
  </agm-polygon>
</ng-container>
