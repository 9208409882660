import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'apex-shared';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslationModule, SpinnerModule } from 'crmcloud-core';
import { LocationMapSharedModule, PanelSharedModule } from 'crmcloud-shared';
import { OrganizationCoreServicesModule } from '@crm-portal/core/organization';
import { WeatherStationCoreServicesModule } from '@crm-portal/core/weather-station';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { WidgetLocationComponent } from './components/widget-location/widget-location.component';
import { DeviceDashboardComponent } from './components/device-dashboard/device-dashboard.component';

@NgModule({
  declarations: [DashboardComponent, WidgetLocationComponent, DeviceDashboardComponent],
  imports: [
    CommonModule,
    NgbModule,
    SharedModule,
    TranslationModule,
    SpinnerModule,
    LocationMapSharedModule,
    PanelSharedModule,
    OrganizationCoreServicesModule,
    WeatherStationCoreServicesModule,
  ],
  exports: [DashboardComponent],
})
export class DashboardModule {}
