<div [appSpinner]="displaySpinner$ | async">
  <div class="row">
    <div class="col-4">
      <div class="row mb-4">
        <div class="col">
          <span>{{ currentStateLabel | translate }}</span>
          <span class="font-weight-bold pl-1"
            >{{ totalQuantity | number: '1.3-3' }}
            {{ productUnit | enumTranslateKey: 'APPLICATION.DICTIONARIES.WAREHOUSE_PRODUCT_UNIT_SHORT' | translate }}</span
          >
        </div>
      </div>

      <dl
        class="row"
        *ngFor="let detail of details">
        <dt class="col-sm-5 font-weight-light">
          {{ detail.warehouseName }}
        </dt>
        <dd class="col-sm-7">
          {{ detail.warehouseTotalQuantity | number: '1.3-3' }}
          {{ productUnit | enumTranslateKey: 'APPLICATION.DICTIONARIES.WAREHOUSE_PRODUCT_UNIT_SHORT' | translate }}
        </dd>
      </dl>
    </div>
    <div
      class="col-8"
      *ngIf="charData"
      style="height: 500px">
      <ngx-charts-pie-chart
        [scheme]="colorScheme"
        [results]="charData"
        [gradient]="true"
        [legend]="false"
        [legendPosition]="'below'"
        [labels]="true"
        [doughnut]="false">
      </ngx-charts-pie-chart>
    </div>
  </div>
</div>
