<div [appSpinner]="displaySpinner$ | async">
  <div
    class="card"
    appPanelFullscreen>
    <div class="card-header d-flex">
      <h2>{{ 'APPLICATION.PERMISSIONS.header' | translate }}</h2>
      <div class="ml-auto">
        <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
      </div>
    </div>
    <div class="card-content">
      <div class="card-body">
        <app-add-form
          *ngIf="formModel"
          #addForm
          [formModel]="formModel"
          [formLayout]="formLayout"
          [model]="initValue"
          (add)="add($event)"
          [translateKey]="'APPLICATION.PERMISSIONS'"></app-add-form>
      </div>
    </div>
  </div>
</div>
