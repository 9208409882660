<div [appSpinner]="displaySpinner$ | async">
  <div
    class="card"
    appPanelFullscreen>
    <div class="card-header">
      <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
    </div>
    <div class="card-content">
      <div class="card-body pt-0">
        <div class="container-fluid px-0">
          <div class="d-flex">
            <div class="ml-auto mt-2">
              <a
                class="btn btn-raised mr-1 btn-danger"
                (click)="removeParcels()">
                <i class="ft-save mr-1"></i>
                {{ schema.parcelsDeleteButtonKey | translate }}
              </a>
              <a
                class="btn btn-raised mr-1 btn-primary"
                (click)="saveParcels()"
                [class.disabled]="!(allParcelsHaveName$ | async)">
                <i class="ft-save mr-1"></i>
                {{ schema.parcelsSaveButtonKey | translate }}
              </a>
            </div>
          </div>
          <form class="form-horizontal ng-dynamic-form">
            <label class="col-12 px-0">
              <h5 class="m-0">
                <i class="ft ft-home"></i>
                {{ schema.mapHeaderKey | translate }}
              </h5>
              <hr class="mt-1" />
            </label>
          </form>
          <div class="row">
            <div class="col-12">
              <div class="float-left">
                <a
                  class="btn btn-raised mr-1"
                  (click)="startSplitGround()"
                  [class.disabled]="!isPolygonSelected"
                  [class.btn-outline-warning]="!splitingToolEnabled"
                  [class.btn-warning]="splitingToolEnabled">
                  <i class="ft-edit-2 mr-1"></i>
                  {{ schema.splitParcelButtonKey | translate }}
                </a>
                <a
                  class="btn btn-raised mr-1 btn-warning"
                  (click)="openPolygonInfoModal()"
                  [class.disabled]="!isPolygonSelected">
                  <i class="ft-info mr-1"></i>
                  {{ schema.infoParcelButtonKey | translate }}
                </a>
                <a
                  class="btn btn-raised mr-1 btn-success"
                  (click)="submitSplitGround()"
                  *ngIf="splitingToolEnabled"
                  [class.disabled]="!(isDrawCompleted$ | async)">
                  <i class="ft-save mr-1"></i>
                  {{ schema.confirmSplittingParcelKey | translate }}
                </a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div
                class="alert alert-warning"
                *ngIf="!(allParcelsHaveName$ | async)">
                {{ schema.parcelNameMissingWarningKey | translate }}
              </div>
              <div
                class="alert alert-info"
                *ngIf="!splitingToolEnabled">
                {{ schema.parcelStartingSplitInfoKey | translate }}
              </div>
              <div
                class="alert alert-info"
                *ngIf="splitingToolEnabled && !(isDrawCompleted$ | async)">
                {{ schema.parcelDrawingSplitInfoKey | translate }}
              </div>
              <div
                class="alert alert-info"
                *ngIf="isDrawCompleted$ | async">
                {{ schema.parcelEndingSplitInfoKey | translate }}
              </div>
            </div>
            <div class="col-12">
              <app-pure-map [height]="420"></app-pure-map>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-content">
      <div class="card-body">
        <form class="form-horizontal ng-dynamic-form">
          <label class="col-12 px-0">
            <h5 class="m-0">
              <i class="ft ft-map-pin"></i>
              {{ schema.listHeaderKey | translate }}
            </h5>
            <hr class="mt-1" />
          </label>
        </form>
        <div class="container-fluid">
          <cdk-table [dataSource]="dataSource">
            <ng-container cdkColumnDef="name">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-9 col-md-5 col-lg-5 pr-0">
                {{ schema.parcelNameKey | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-9 col-md-5 col-lg-5 pr-0">
                {{ groundName }}
                {{ element.name ? ' - ' + element.name : '' }}
              </div>
            </ng-container>
            <ng-container cdkColumnDef="areaInHa">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-md-4 col-lg-5 d-none d-md-block pr-0">
                {{ schema.parcelAreaInHaKey | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-md-4 col-lg-5 d-none d-md-block pr-0">
                {{ element.areaInHa }}
              </div>
            </ng-container>
            <ng-container cdkColumnDef="action">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-3 col-md-3 col-lg-2 text-md-right">
                {{ schema.actionsKey | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-3 col-md-3 col-lg-2 p-0 pl-1 pl-md-0 pr-0 pr-md-1 w-100">
                <div class="row no-gutters justify-content-md-end">
                  <button
                    (click)="showParcelOnMapClick(element)"
                    title="{{ schema.showOnMapButtonKey | translate }}"
                    class="btn btn-raised m-0 my-1 mr-1 btn-sm btn-info">
                    <i
                      class="ft ft-search"
                      aria-hidden="true"></i>
                  </button>
                  <button
                    (click)="openParcelInfoModal(element)"
                    title="{{ schema.editParcelButtonKey | translate }}"
                    class="btn btn-raised m-0 my-1 mr-1 btn-sm btn-success">
                    <i
                      class="ft ft-edit-2"
                      aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </ng-container>
            <cdk-header-row
              class="row header-navbar extra_bg-light-grey"
              *cdkHeaderRowDef="displayedColumns"></cdk-header-row>
            <cdk-row
              class="row row-striped align-items-center"
              *cdkRowDef="let row; columns: displayedColumns"></cdk-row>
          </cdk-table>
        </div>
      </div>
    </div>
  </div>
</div>
