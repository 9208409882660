<div
  class="ground-list mb-2"
  [appSpinner]="dataSource.loading$ | async">
  <div
    class="card"
    appPanelFullscreen>
    <div class="card-header pt-3">
      <label class="px-0">
        <h5 class="m-0">
          <i class="ft ft-list"></i>
          {{ 'BUILDINGS.infoBuilding' | translate }}
        </h5>
      </label>
      <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
    </div>
    <div class="card-content">
      <div class="card-body">
        <div class="container-fluid table-container">
          <cdk-table
            [dataSource]="dataSource"
            cdkSort
            cdkSortActive="name"
            cdkSortDirection="desc">
            <ng-container cdkColumnDef="name">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                cdk-sort-header
                class="col-4 pr-0">
                {{ 'BUILDINGS.name' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-4 pr-0">
                {{ element.name }}
              </div>
            </ng-container>
            <ng-container cdkColumnDef="buildingType">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                cdk-sort-header
                class="col-4 pr-0">
                {{ 'BUILDINGS.buildingType' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-4 pr-0">
                {{ element.buildingType | dictionaryTranslateKey | translate }}
              </div>
            </ng-container>
            <ng-container cdkColumnDef="action">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-4 text-md-right">
                {{ 'APPLICATION.LIST.action' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-4 p-0 pl-1 pl-md-0 pr-0 pr-md-1 w-100">
                <div class="row no-gutters justify-content-md-end">
                  <button
                    *ngIf="hasBuildingLocalization(element)"
                    (click)="showOnMapClick(element)"
                    title="{{ 'BUILDINGS.showOnMap:button' | translate }}"
                    class="d-none d-md-block btn btn-raised m-0 my-1 mr-1 btn-sm btn-info">
                    <i
                      class="ft ft-search"
                      aria-hidden="true"></i>
                  </button>
                  <button
                    *ngIf="deletable"
                    (click)="onDeleteClick(element)"
                    title="{{ 'BUILDINGS.delete:button' | translate }}"
                    class="d-none d-md-block btn btn-raised m-0 my-1 mr-1 btn-sm btn-danger">
                    <i
                      class="ft ft-trash-2"
                      aria-hidden="true"></i>
                  </button>
                  <a
                    *ngIf="editable"
                    [routerLink]="['..', element.id, 'edit']"
                    title="{{ 'BUILDINGS.update:button' | translate }}"
                    class="d-none d-md-block btn btn-raised m-0 my-1 mr-1 btn-sm btn-success">
                    <i
                      class="ft ft-edit-2"
                      aria-hidden="true"></i>
                  </a>
                  <a
                    [routerLink]="[detailsLink, element.id]"
                    title="{{ 'BUILDINGS.details:button' | translate }}"
                    class="btn btn-raised m-0 my-1 mr-1 btn-sm btn-info">
                    <i
                      class="ft ft-info"
                      aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </ng-container>
            <cdk-header-row
              class="row header-navbar extra_bg-light-grey"
              *cdkHeaderRowDef="displayedColumns">
            </cdk-header-row>
            <cdk-row
              class="row row-striped align-items-center"
              *cdkRowDef="let row; columns: displayedColumns"
              (click)="handleDetail(row)"></cdk-row>
          </cdk-table>
          <div
            class="row"
            *ngIf="dataSource.noData$ | async">
            <div class="col text-center py-3">
              {{ 'BUILDINGS.list:empty' | translate }}
            </div>
          </div>
          <div
            class="row"
            *ngIf="dataSource.error$ | async">
            <div class="col text-center py-3">
              {{ 'BUILDINGS.list:error' | translate }}
            </div>
          </div>
          <cdk-paginator
            class="row header-navbar d-flex justify-content-end pr-2 py-1"
            [pageSizeOptions]="[10, 25, 50, 75, 100]"
            showFirstLastButtons></cdk-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
