<div
  class="card"
  appPanelFullscreen>
  <div class="card-header pt-3">
    <label class="px-0">
      <h5 class="m-0">
        <i class="ft ft-sliders"></i>
        {{ 'APPLICATION.SEARCH.title' | translate }}
      </h5>
    </label>
    <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
    <app-panel-hide-button
      [hidden]="false"
      (hiddenChange)="panelHide.setHidden($event)"
      class="float-right mr-1">
    </app-panel-hide-button>
  </div>
  <div class="card-content">
    <div class="card-body py-1">
      <div
        class="container-fluid px-0"
        [appPanelHide]="false">
        <hr class="mt-0" />
        <app-simple-form
          #mapFilter
          [formModel]="formModel"
          [formLayout]="formLayout"
          [model]="initValue"
          [translateKey]="'CULTIVATION.list.filter'">
        </app-simple-form>
      </div>
    </div>
  </div>
</div>
