import { LoggerService } from 'crmcloud-core';
import { AuthStoreService } from './services/auth-store.service';
var IdpPartnerOrganizationGuard = /** @class */ (function () {
    function IdpPartnerOrganizationGuard(authStore, logger) {
        this.authStore = authStore;
        this.logger = logger;
    }
    IdpPartnerOrganizationGuard.prototype.canActivate = function (route, state) {
        // use separate interface, do not reuse CoponentClaimsDto
        var requiredPermission = route.data;
        return this.checkPermissions(requiredPermission);
    };
    IdpPartnerOrganizationGuard.prototype.canActivateChild = function (childRoute, state) {
        var requiredPermission = childRoute.data;
        return this.checkPermissions(requiredPermission);
    };
    IdpPartnerOrganizationGuard.prototype.checkPermissions = function (requiredPermission) {
        if (!requiredPermission.componentKey) {
            // if there are no requirements defined
            // assume that the route can be freely accessed
            return true;
        }
        var partnerAccessComponents = this.authStore.getPartnerOrganizationAccess;
        if (partnerAccessComponents == null || partnerAccessComponents.length === 0) {
            var message = '[IdpPartnerOrganizationGuard] No groups defined in user claims.';
            this.logger.trackException(new Error(message));
            return false;
        }
        return partnerAccessComponents
            .filter(function (c) {
            // extract only components required by the specified route
            return c.name === requiredPermission.componentKey;
        })
            .some(function (c) {
            // check if at least one of the user's components
            // has all the permissions required by the route
            return requiredPermission.permissions.every(function (reqPerm) {
                return c.permissions.includes(reqPerm);
            });
        });
    };
    return IdpPartnerOrganizationGuard;
}());
export { IdpPartnerOrganizationGuard };
