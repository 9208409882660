<div
  class="container-fluid px-0"
  *ngIf="formModel$ | async as formModelx">
  <form
    class="form-horizontal ng-dynamic-form"
    [formGroup]="formGroup">
    <fieldset>
      <dynamic-ng-bootstrap-form
        class="row no-gutters"
        [group]="formGroup"
        [layout]="formLayout"
        [model]="formModelx">
        <ng-template
          modelType="GROUP"
          align="START"
          let-legend="legend">
          <hr
            class="mt-0"
            [ngClass]="{ 'mt-0': true, 'mb-0': legend }" />
          <p
            *ngIf="legend"
            class="form-group-legend m-0 mb-1">
            {{ legend }}
          </p>
        </ng-template>
      </dynamic-ng-bootstrap-form>
    </fieldset>
    <div class="actions text-right pb-1">
      <button
        class="btn btn-raised btn-primary mb-0"
        type="button"
        (click)="searchClick()">
        <i class="ft-search"></i>
        {{ 'APPLICATION.SEARCH.button' | translate }}
      </button>
    </div>
  </form>
</div>
