<div class="modal-header">
  <h4
    class="modal-title"
    id="modal-title">
    {{ titleTranslateKey | translate }}
  </h4>
  <button
    type="button"
    class="close"
    aria-describedby="modal-title"
    (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-simple-form
    #modalDateForm
    [formModel]="formModel"
    [formLayout]="formLayout"
    [model]="initValue"
    [translateKey]="formTranslateKey">
  </app-simple-form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="onClose()">
    {{ 'APPLICATION.MODAL.cancel' | translate }}
  </button>
  <button
    type="button"
    class="btn btn-danger"
    (click)="onSave()"
    [disabled]="modalDateForm.formGroup.invalid">
    {{ saveTranslateKey || 'APPLICATION.MODAL.save' | translate }}
  </button>
</div>
