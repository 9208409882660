<div [appSpinner]="displaySpinner$ | async">
  <div
    class="card"
    appPanelFullscreen>
    <div class="card-header d-flex">
      <h2>
        {{ 'MACHINE.ALARMS_CONFIGURATION.update:header' | translate }}
      </h2>
      <div class="ml-auto">
        <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
      </div>
    </div>
    <div class="card-content">
      <div class="card-body">
        <app-form
          *ngIf="formModel"
          #addForm
          [formModel]="formModel"
          [formLayout]="formLayout"
          [startFromIsEditing]="startFromIsEditing"
          [model]="formData"
          (save)="add($event)"
          [translateKey]="'MACHINE.ALARMS_CONFIGURATION'">
        </app-form>
      </div>
    </div>
  </div>
</div>
