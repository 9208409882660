<div
  ngbDropdown
  [placement]="'bottom-right'">
  <a
    class="nav-link position-relative"
    id="dropdownBasic3"
    ngbDropdownToggle>
    <i class="ft-user font-medium-3 blue-grey darken-4"></i>
    <p class="d-none">User Settings</p>
  </a>
  <div
    ngbDropdownMenu
    aria-labelledby="dropdownBasic3"
    class="text-left">
    <div
      class="text-center user-info"
      *ngIf="user">
      <span class="font-small-2">Signed in as </span>
      <strong class="font-small-2 text-center">{{ user.name }}</strong>
    </div>
    <!-- <div class="dropdown-divider"></div>
        <a class="dropdown-item py-1" href="javascript:;">
            <i class="ft-settings mr-2"></i>
            <span>{{ 'APPLICATION.NAVBAR.settings' | translate }}</span>
        </a> -->
    <div class="dropdown-divider"></div>
    <a
      class="dropdown-item"
      href="javascript:;"
      (click)="logoutClick($event)">
      <i class="ft-power mr-2"></i>
      <span>{{ 'APPLICATION.NAVBAR.logout' | translate }}</span>
    </a>
  </div>
</div>
