/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./load-user-data.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./load-user-data.component";
import * as i4 from "crmcloud-core";
import * as i5 from "../../auth/user.service";
import * as i6 from "../../auth/tenant.service";
import * as i7 from "@angular/router";
var styles_LoadUserDataComponent = [i0.styles];
var RenderType_LoadUserDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadUserDataComponent, data: {} });
export { RenderType_LoadUserDataComponent as RenderType_LoadUserDataComponent };
export function View_LoadUserDataComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "page-loading spinner-backdrop"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "circle-spinner loading-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "spinner-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.messageTranslationKey)); _ck(_v, 4, 0, currVal_0); }); }
export function View_LoadUserDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-load-user-data", [], null, null, null, View_LoadUserDataComponent_0, RenderType_LoadUserDataComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoadUserDataComponent, [i4.AppConfigurationService, i5.UserService, i6.TenantService, i4.FcTokenService, i7.ActivatedRoute, i7.Router, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadUserDataComponentNgFactory = i1.ɵccf("app-load-user-data", i3.LoadUserDataComponent, View_LoadUserDataComponent_Host_0, {}, {}, []);
export { LoadUserDataComponentNgFactory as LoadUserDataComponentNgFactory };
