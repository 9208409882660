<div
  class="card"
  appPanelFullscreen>
  <div class="card-header">
    <div class="card-header d-flex pl-0">
      <div>
        <form class="form-inline">
          <div class="form-group">
            <div class="input-group col-9 col-md-5 mx-0 mr-md-1">
              <input
                class="form-control"
                placeholder="yyyy-mm-dd"
                name="dpFrom"
                [(ngModel)]="dateFrom"
                ngbDatepicker
                #d1="ngbDatepicker"
                (dateSelect)="onDateSelect($event)" />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d1.toggle()"
                  type="button"></button>
              </div>
            </div>
            <div class="input-group col-9 col-md-5 mx-0">
              <input
                class="form-control"
                placeholder="yyyy-mm-dd"
                name="dpTo"
                [(ngModel)]="dateTo"
                ngbDatepicker
                #d2="ngbDatepicker"
                (dateSelect)="onDateSelect($event)" />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d2.toggle()"
                  type="button"></button>
              </div>
            </div>
          </div>

          <div *ngIf="dateShowValidationError">
            <small class="form-text text-danger">
              {{ 'WEATHERSTATION.validation:dateFromAfterDateTo' | translate }}
            </small>
          </div>
        </form>
      </div>
      <div class="ml-auto">
        <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
      </div>
    </div>
  </div>
  <br />
  <div class="card-content">
    <div class="card-body">
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-12 chart-container"
            [appSpinner]="displaySpinner$ | async">
            <canvas
              baseChart
              height="100"
              [datasets]="lineChartAirTemperatureData"
              [options]="lineChartOptions"
              [colors]="lineChartColors"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"
              *ngIf="isWeatherStationDeviceType && dataReady"></canvas>
            <canvas
              baseChart
              height="100"
              [datasets]="lineChartAirHumidityData"
              [options]="lineChartOptions"
              [colors]="lineChartColors"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"
              *ngIf="dataReady"></canvas>
            <canvas
              baseChart
              height="100"
              [datasets]="lineChartSoilTemperatureData"
              [options]="lineChartOptions"
              [colors]="lineChartColors"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"
              *ngIf="dataReady"></canvas>
            <canvas
              baseChart
              height="100"
              [datasets]="lineChartWindSpeedData"
              [options]="lineChartOptions"
              [colors]="lineChartColors"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"
              *ngIf="isWeatherStationDeviceType && dataReady"></canvas>
            <canvas
              baseChart
              height="100"
              [datasets]="lineChartWindDirectionData"
              [options]="lineChartOptions"
              [colors]="lineChartColors"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"
              *ngIf="isWeatherStationDeviceType && dataReady"></canvas>
            <canvas
              baseChart
              height="100"
              [datasets]="lineChartSoilHumidityData"
              [options]="lineChartOptions"
              [colors]="lineChartColors"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"
              *ngIf="dataReady"></canvas>
            <canvas
              baseChart
              height="100"
              [datasets]="lineChartRainCountData"
              [options]="lineChartOptions"
              [colors]="lineChartColors"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"
              *ngIf="isWeatherStationDeviceType && dataReady"></canvas>
            <canvas
              baseChart
              height="100"
              [datasets]="lineChartSunLevelData"
              [options]="lineChartOptions"
              [colors]="lineChartColors"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"
              *ngIf="isWeatherStationDeviceType && dataReady"></canvas>
            <canvas
              baseChart
              height="100"
              [datasets]="lineChartAirPressureData"
              [options]="lineChartOptions"
              [colors]="lineChartColors"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"
              *ngIf="isWeatherStationDeviceType && dataReady"></canvas>
            <canvas
              baseChart
              height="100"
              [datasets]="lineChartBatteryPercentageData"
              [options]="lineChartOptions"
              [colors]="lineChartColors"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"
              *ngIf="dataReady"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
