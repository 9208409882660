<div
  class="card"
  appPanelFullscreen>
  <div class="card-header pt-2">
    <h4 class="card-title mb-0">
      <i class="ft ft-map"></i>
      {{ 'DASHBOARD.locationMap.header' | translate }}
      <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
      <app-panel-hide-button
        [hidden]="false"
        (hiddenChange)="panelHide.setHidden($event)"
        class="float-right mr-1"></app-panel-hide-button>
    </h4>
  </div>
  <div class="card-content">
    <div class="card-body py-1">
      <div
        class="container-fluid px-0"
        [appPanelHide]="false"
        [appSpinner]="displaySpinner$ | async">
        <app-location-map
          [items]="locationMapItems"
          [schema]="locationMapSchema"></app-location-map>
      </div>
    </div>
  </div>
</div>
