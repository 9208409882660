<div
  class="card"
  appPanelFullscreen>
  <div class="card-header pt-3">
    <label class="px-0">
      <h5 class="m-0">
        <i class="ft ft-list"></i>
        {{ 'CULTIVATION.list.cultivations' | translate }}
      </h5>
    </label>
    <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
  </div>
  <div class="card-content">
    <div class="card-body py-1">
      <div class="container-fluid px-0 table-container">
        <hr class="mt-0" />
        <div>
          <cdk-table
            [dataSource]="dataSource"
            cdkSort
            cdkSortActive="parcelName"
            cdkSortDirection="desc">
            <ng-container cdkColumnDef="crop">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-4 text-truncate">
                {{ 'CULTIVATION.list.crop' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let parcel"
                class="col-4 text-truncate pr-0">
                <span
                  [ngClass]="{
                    'font-weight-light': parcel.isDeleted
                  }"
                  (click)="handleDetail(parcel)"
                  style="cursor: pointer"
                  *ngIf="parcel.cultivations != null && parcel.cultivations.length > 0">
                  <span>{{ parcel.cultivations[0].crop | dictionaryTranslateKey | translate }}</span>
                  <span *ngIf="parcel.cultivations[0].cultivationType != cultivationTypeConventional">
                    ({{
                      parcel.cultivations[0].cultivationType | enumTranslateKey: 'APPLICATION.DICTIONARIES.CULTIVATION_TYPE' | translate
                    }})
                  </span>
                  <span *ngIf="parcel.isDraft"> ({{ 'CULTIVATION.list.cropPlanned' | translate }})</span>
                </span>
              </div>
            </ng-container>
            <ng-container cdkColumnDef="parcelName">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                cdk-sort-header
                class="col-4 text-truncate">
                {{ 'CULTIVATION.list.parcelName' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let parcel"
                class="col-4 text-truncate pr-0">
                <span
                  [ngClass]="{
                    'font-weight-light': parcel.isDeleted
                  }"
                  >{{ parcel.name }}</span
                >
              </div>
            </ng-container>
            <ng-container cdkColumnDef="fieldCardEndDate">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-2 text-truncate">
                {{ 'CULTIVATION.list.fieldCardEndDate' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let parcel"
                class="col-2 text-truncate pr-0">
                <span
                  class="font-italic size-smaller"
                  [ngClass]="{
                    'font-weight-light': parcel.isDeleted
                  }"
                  *ngIf="parcel.fieldCardExpectedEndDate && !parcel.fieldCardEndDate">
                  {{ parcel.fieldCardExpectedEndDate | i18nDate: 'shortDate' }}
                  <i
                    class="ft ft-info"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="{{ 'CULTIVATION.fieldCardForm.expectedEndDate' | translate }}"></i>
                </span>
                <span
                  [ngClass]="{
                    'font-weight-light': parcel.isDeleted
                  }"
                  *ngIf="parcel.fieldCardEndDate">
                  {{ parcel.fieldCardEndDate | i18nDate: 'shortDate' }}
                </span>
              </div>
            </ng-container>
            <ng-container cdkColumnDef="action">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-2 p-0 text-center">
                {{ 'APPLICATION.LIST.action' | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let parcel"
                class="col-2 p-0 pl-1 pl-md-0 pr-0 pr-md-1 w-100">
                <div class="row no-gutters float-md-right">
                  <button
                    (click)="onShowOnMap(parcel)"
                    title="{{ 'CULTIVATION.list.showOnMap:button' | translate }}"
                    class="btn btn-raised m-0 my-1 mr-1 btn-sm btn-info">
                    <i
                      class="ft ft-search"
                      aria-hidden="true"></i>
                  </button>
                  <a
                    *ngIf="parcel.fieldCardId == null"
                    [routerLink]="['parcels', parcel.id, 'fieldCards']"
                    title="{{ 'CULTIVATION.list.addFieldCard:button' | translate }}"
                    class="btn btn-raised m-0 my-1 mr-1 btn-sm btn-success">
                    Dodaj kartę pola
                  </a>
                  <a
                    *ngIf="parcel.fieldCardId != null"
                    [routerLink]="['parcels', parcel.id, 'fieldCards', parcel.fieldCardId]"
                    title="{{ 'CULTIVATION.list.fieldCardDetails:button' | translate }}"
                    class="btn btn-raised m-0 my-1 mr-1 btn-sm btn-info">
                    <i
                      class="ft ft-info"
                      aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </ng-container>
            <cdk-header-row
              class="row header-navbar m-0 py-2 extra_bg-light-grey"
              *cdkHeaderRowDef="displayedColumns">
            </cdk-header-row>
            <cdk-row
              class="row row-striped align-items-center m-0"
              *cdkRowDef="let row; columns: displayedColumns"
              (click)="handleDetail(row)"></cdk-row>
          </cdk-table>
          <div
            class="row"
            *ngIf="dataSource.noData$ | async">
            <div class="col text-center py-3">
              {{ 'CULTIVATION.list:empty' | translate }}
            </div>
          </div>
          <div
            class="row"
            *ngIf="dataSource.error$ | async">
            <div class="col text-center py-3">
              {{ 'CULTIVATION.list:error' | translate }}
            </div>
          </div>
          <cdk-paginator
            class="row header-navbar d-flex justify-content-end pr-2 py-1"
            [pageSizeOptions]="[10, 25, 50, 75, 100]"
            showFirstLastButtons>
          </cdk-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
