<section id="timeline">
  <article *ngFor="let entry of serviceEntries">
    <div class="inner">
      <span [ngClass]="hasExpirationDate(entry) ? 'date-expiration' : 'date'">
        <span class="day">{{ entry.date | date: 'dd' }}</span>
        <span class="month">{{ entry.date | date: 'MMM' }}</span>
        <span class="year">{{ entry.date | date: 'yyyy' }}</span>
      </span>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title text-center">
            {{ entry.serviceType | enumTranslateKey: 'MACHINE.SERVICE_CARD.SERVICE_TYPE' | translate }}
          </h4>
          <h5
            *ngIf="hasExpirationDate(entry); else defaultText"
            class="text-center">
            {{ 'MACHINE.SERVICE_CARD.expirationDate' | translate }}
          </h5>
          <ng-template #defaultText>
            <h5 class="text-center">
              {{ 'MACHINE.SERVICE_CARD.mileage' | translate }}:
              {{ entry.mileage | number }}
            </h5>
          </ng-template>
        </div>
        <div class="card-content">
          <div class="description">{{ entry.description }}</div>
          <table
            *ngIf="!hasExpirationDate(entry) && entry.servicesList.length"
            class="table table-responsive table-striped table-hover">
            <tr>
              <th></th>
              <th>
                {{ 'MACHINE.SERVICE_CARD.TABLE.name' | translate }}
              </th>
              <th>
                {{ 'MACHINE.SERVICE_CARD.TABLE.quantity' | translate }}
              </th>
              <th>
                {{ 'MACHINE.SERVICE_CARD.TABLE.unit' | translate }}
              </th>
              <th>
                {{ 'MACHINE.SERVICE_CARD.TABLE.unitPrice' | translate }}
              </th>
            </tr>
            <tr *ngFor="let element of entry.servicesList">
              <td>
                {{ element.icon }}
                <i class="ft {{ element.icon }}"></i>
              </td>
              <td>
                {{ element.name }}
                <span *ngIf="element.catalogNumber"><br />({{ element.catalogNumber }})</span>
                <span *ngIf="element.manufacturer"
                  ><br /><u>{{ element.manufacturer }}</u></span
                >
              </td>
              <td>{{ element.quantity }}</td>
              <td>
                {{ element.unit | enumTranslateKey: 'MACHINE.SERVICE_CARD.QUANTITY' | translate }}
              </td>
              <td>{{ element.unitPrice }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </article>
  <div class="clearfix"></div>
  <div class="line"></div>
</section>
