<div [appSpinner]="displaySpinner$ | async">
  <div
    class="card"
    appPanelFullscreen>
    <div class="card-content">
      <div class="card-body">
        <app-add-form
          *ngIf="formModel"
          #addForm
          [formModel]="formModel"
          [formLayout]="formLayout"
          [model]="initValue"
          [translateKey]="translationKeyPrefix"
          (add)="onAdd($event)"
          (afterCancel)="onAfterCancel()"></app-add-form>
      </div>
    </div>
  </div>
</div>
