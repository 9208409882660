import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { LoggerService } from 'crmcloud-core';

import { AuthStoreService } from './services/auth-store.service';

import { RoutePermissionsDto } from './models/route-permissions.dto';

@Injectable()
export class IdpPartnerOrganizationGuard implements CanActivate, CanActivateChild {
  constructor(
    private authStore: AuthStoreService,
    private logger: LoggerService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    // use separate interface, do not reuse CoponentClaimsDto
    const requiredPermission = route.data as RoutePermissionsDto;
    return this.checkPermissions(requiredPermission);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const requiredPermission = childRoute.data as RoutePermissionsDto;
    return this.checkPermissions(requiredPermission);
  }

  private checkPermissions(requiredPermission: RoutePermissionsDto): boolean {
    if (!requiredPermission.componentKey) {
      // if there are no requirements defined
      // assume that the route can be freely accessed
      return true;
    }

    const partnerAccessComponents = this.authStore.getPartnerOrganizationAccess;

    if (partnerAccessComponents == null || partnerAccessComponents.length === 0) {
      const message = '[IdpPartnerOrganizationGuard] No groups defined in user claims.';
      this.logger.trackException(new Error(message));
      return false;
    }

    return partnerAccessComponents
      .filter(c => {
        // extract only components required by the specified route
        return c.name === requiredPermission.componentKey;
      })
      .some(c => {
        // check if at least one of the user's components
        // has all the permissions required by the route

        return requiredPermission.permissions.every(reqPerm => {
          return c.permissions.includes(reqPerm);
        });
      });
  }
}
