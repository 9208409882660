<div
  [appSpinner]="displaySpinner$ | async"
  class="wrapper main-spinner"
  [ngClass]="{
    'nav-collapsed': iscollapsed,
    'menu-collapsed': iscollapsed
  }"
  [dir]="'ltr'">
  <div
    appSidebar
    class="app-sidebar d-print-none"
    (toggleHideSidebar)="toggleHideSidebar($event)"
    [ngClass]="{ 'hide-sidebar': hideSidebar }">
    <app-sidebar
      [menuItems]="routes"
      [logoUrl]="'assets/logo.svg'"
      [userClaims]="groupClaims">
      <span
        mainLogo
        class="text align-middle"
        >AgriCRM</span
      >
    </app-sidebar>
    <div class="sidebar-background"></div>
  </div>
  <app-navbar
    (toggleHideSidebar)="toggleHideSidebar($event)"
    [logoUrl]="'assets/images/agricrm_logo.svg'">
    <img
      mainLogo
      class="logo-farmportal d-none d-lg-block"
      src="assets/images/agricrm_logo.svg" />
    <ul class="navbar-nav">
      <li class="nav-item mr-2">
        <app-toggle-fullscreen></app-toggle-fullscreen>
      </li>
      <li class="nav-item">
        <app-lang-selector></app-lang-selector>
      </li>
      <li
        class="nav-item"
        *ngIf="hasAccess('Organization.Messages')">
        <app-message-unread-notification></app-message-unread-notification>
      </li>
      <li class="nav-item">
        <app-user-nav-item
          [user]="user"
          (logout)="logout()"></app-user-nav-item>
      </li>
    </ul>
  </app-navbar>
  <div class="main-panel">
    <div class="main-content">
      <div class="content-wrapper">
        <div class="container-fluid">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
