<div [appSpinner]="displaySpinner$ | async">
  <app-form
    *ngIf="formModel"
    #formDetail
    [startFromIsEditing]="startFromIsEditing"
    [formModel]="formModel"
    [formLayout]="formLayout"
    [model]="initValue"
    [translateKey]="translationKeyPrefix"
    (formGroupInitialized)="onFormGroupInitialized($event)"
    (save)="onSave($event)">
  </app-form>
</div>
