import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigurationService } from 'crmcloud-core';
import { TenantService } from '@crm-portal/core/auth/tenant.service';
import { CreateMessageCommand } from '../models/message-command.model';
import { IMessageDto } from '../models/message.dto';

@Injectable({
  providedIn: 'root',
})
export class MessageCoreProxyService {
  private readonly baseApiUrl: string;
  constructor(
    config: AppConfigurationService,
    private tenantService: TenantService,
    private httpClient: HttpClient,
  ) {
    this.baseApiUrl = `${config.configuration.api_url}/v1/organizations`;
  }

  public getAll(): Observable<IMessageDto[]> {
    return this.httpClient.get<IMessageDto[]>(this.apiUrl);
  }

  public getAllUnread(): Observable<IMessageDto[]> {
    return this.httpClient.get<IMessageDto[]>(`${this.apiUrl}?IsRead=false`);
  }

  public createMessage(messageCommand: CreateMessageCommand): Observable<void> {
    return this.httpClient.post<void>(this.apiUrl, messageCommand);
  }

  public markAsRead(id: string): Observable<IMessageDto> {
    const url = `${this.apiUrl}/${id}`;
    const body = { isRead: true };
    return this.httpClient.patch<IMessageDto>(url, body);
  }

  private get apiUrl(): string {
    return `${this.baseApiUrl}/${this.tenantService.currentTenantId}/messages`;
  }
}
