<div [appSpinner]="displaySpinner$ | async">
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-basic-title">
      {{ schema.modalHeaderKey | translate }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="onCancel($event)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <lib-warehouse-inflow-harvest-filter
      [translationKeyPrefix]="schema.filterTranslationPrefixKey"
      [treatmentStartDate]="filterTreatmentStartDate"
      [treatmentEndDate]="filterTreatmentEndDate"
      (search)="onSearch($event)"></lib-warehouse-inflow-harvest-filter>
  </div>
</div>
