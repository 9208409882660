<div
  class="flex-grow-1"
  style="height: 400px">
  <app-map
    [features]="features"
    [mapConfig]="mapConfig"
    [markerConfig]="markerConfig"
    [polygonConfig]="polygonConfig">
  </app-map>
</div>
<div class="row mt-3 mb-2">
  <div class="col-4 text-center">
    <img
      [src]="schema.icons.moistureSensorMarkerIconUrl"
      [alt]="schema.translations.moistureSensors | translate" />
    {{ schema.translations.moistureSensors | translate }}
  </div>
  <div class="col-4 text-center">
    <img
      [src]="schema.icons.weatherStationMarkerIconUrl"
      [alt]="schema.translations.weatherStations | translate" />
    {{ schema.translations.weatherStations | translate }}
  </div>
  <div class="col-4 text-center">
    <img
      [src]="schema.icons.vehicleMarkerIconUrl"
      [alt]="schema.translations.vehicles | translate" />
    {{ schema.translations.vehicles | translate }}
  </div>
</div>
