<div>
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-basic-title">
      {{ editParcelFormHeaderKey | translate }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-add-form
      #addForm
      *ngIf="formModel"
      [formModel]="formModel"
      [formLayout]="formLayout"
      [model]="initValue"
      [translateKey]="editParcelFormTranslateKey"
      (add)="add($event)"
      (afterCancel)="cancel()">
    </app-add-form>
  </div>
</div>
