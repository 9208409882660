<div
  [perfectScrollbar]
  class="email-app-list">
  <div id="users-list">
    <div class="list-group">
      <div class="users-list-padding">
        <a
          class="list-group-item list-group-item-action"
          *ngFor="let message of messages; trackBy: trackByFn"
          [ngClass]="message.id === selectedMessage?.id ? 'bg-blue-grey bg-lighten-5 border-right-primary border-right-2' : 'no-border'"
          (click)="selectMessage($event, message)">
          <span class="media">
            <app-message-type-avatar [messageType]="message.type"></app-message-type-avatar>
            <div class="media-body text-left">
              <h6
                [ngClass]="message.isRead === true ? 'list-group-item-heading' : 'list-group-item-heading text-bold-400'"
                class="optional-text">
                {{ message.sender }}
                <span class="float-right">
                  <span [ngClass]="message.isRead === true ? 'font-small-2 float-right' : 'font-small-2 primary'">
                    {{ message.creationDate | i18nDate: 'shortDate' }}
                  </span>
                </span>
              </h6>
              <p
                [ngClass]="
                  message.isRead === true ? 'list-group-item-text text-truncate' : 'list-group-item-text text-truncate text-bold-500'
                ">
                {{ message.title | htmlTextShort: 20 }}
              </p>
              <p class="list-group-item-text">
                {{ message.body | htmlTextShort: 50 }}
              </p>
            </div>
          </span>
        </a>
        <p
          class="primary text-center"
          *ngIf="messages.length === 0">
          {{ 'MESSAGE.SHARED.list:empty' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
