<div
  class="container-fluid px-0"
  [appSpinner]="displaySpinner$ | async">
  <button
    class="btn btn-raised btn-primary mb-2 mr-2 d-inline"
    (click)="initDrawingMode()">
    {{ 'CULTIVATION.fieldCardForm.draw' | translate }}
  </button>
  <button
    class="btn btn-raised btn-primary mb-2 mr-2 d-inline"
    (click)="initSelectGroundsMode()">
    {{ 'CULTIVATION.fieldCardForm.selectGrounds' | translate }}
  </button>
  <div class="map-container">
    <app-pure-map
      [height]="420"
      [tilt]="0"></app-pure-map>
    <div
      *ngIf="isDrawingMode()"
      class="map-buttons">
      <div class="icon-button">
        <button (click)="showCadastreFeatures()">
          {{ 'CULTIVATION.fieldCardForm.showCadastre' | translate }}
        </button>
      </div>
      <div
        style="
          position: relative;
          overflow: hidden;
          width: 110px;
          height: 1px;
          margin: 0px 5px;
          background-color: rgb(230, 230, 230);
          top: 0px;
        "></div>
      <div class="icon-button icon-button__search">
        <button (click)="centerMapToMarker()">
          {{ 'CULTIVATION.fieldCardForm.centerMap' | translate }}
        </button>
      </div>
    </div>
  </div>
  <ng-container
    *ngIf="this.selectedGroundFeatures.length > 0"
    class="mt-2">
    <h5 class="mt-1">
      {{ 'CULTIVATION.fieldCardForm.selectedGrounds' | translate }}
    </h5>
    <div
      *ngFor="let groundFeature of this.selectedGroundFeatures"
      class="mb-1">
      <p>
        {{ groundFeature.properties.name }} -
        {{ groundFeature.properties.area + ' ha' }}
      </p>
    </div>
  </ng-container>
</div>
