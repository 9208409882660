import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { DeviceType, NotificationService } from 'crmcloud-core';
import { LocationMapItem, LocationMapSchema, LocationMapItemType, PanelHideDirective } from 'crmcloud-shared';
import { WeatherStationCoreService, IWeatherStationListItemDto } from '@crm-portal/core/weather-station';

@Component({
  selector: 'app-widget-location',
  templateUrl: './widget-location.component.html',
  styleUrls: ['./widget-location.component.scss'],
})
export class WidgetLocationComponent implements OnInit, OnDestroy {
  @ViewChild(PanelHideDirective, { static: false }) panelHide;

  displaySpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  locationMapItems: LocationMapItem[];
  locationMapSchema: LocationMapSchema;

  // tslint:disable-next-line: no-any
  private locationData: Observable<any>;

  constructor(
    private weatherStationCoreService: WeatherStationCoreService,
    private notificationService: NotificationService,
  ) {
    this.initSchema();

    this.locationData = forkJoin({
      moistureSensors: this.weatherStationCoreService.getAll(DeviceType.MoistureSensor).pipe(
        catchError(error => {
          this.notificationService.error(error, `DASHBOARD.getMoisturesSensors:error`);
          return of(null);
        }),
      ),
      weatherStations: this.weatherStationCoreService.getAll(DeviceType.WeatherStation).pipe(
        catchError(error => {
          this.notificationService.error(error, `DASHBOARD.getWeatherStationsSensors:error`);
          return of(null);
        }),
      ),
    });
  }

  ngOnInit(): void {
    this.loadLocationMapItems();
  }
  ngOnDestroy(): void {
    this.displaySpinner$.next(false);
    this.displaySpinner$.complete();
  }

  private loadLocationMapItems() {
    this.displaySpinner$.next(true);
    this.locationData.pipe(finalize(() => this.displaySpinner$.next(false))).subscribe(x => {
      const moistureSensors = this.createMoistureSensorLocationMapItem(x.moistureSensors);
      const weatherStations = this.createWeatherStationLocationMapItem(x.weatherStations);

      this.locationMapItems = moistureSensors.concat(weatherStations);
    });
  }

  private createMoistureSensorLocationMapItem = (result: IWeatherStationListItemDto[]): LocationMapItem[] => {
    return (result || [])
      .filter(x => x.location != null)
      .map(x => new LocationMapItem(LocationMapItemType.MoistureSensor, x.location, x.serialNumber, x.lastActiveDate));
  };

  private createWeatherStationLocationMapItem = (result: IWeatherStationListItemDto[]): LocationMapItem[] => {
    return (result || [])
      .filter(x => x.location != null)
      .map(x => new LocationMapItem(LocationMapItemType.WeatherStation, x.location, x.serialNumber, x.lastActiveDate));
  };

  private initSchema = () => {
    this.locationMapSchema = {
      translations: {
        moistureSensors: 'DASHBOARD.locationMap.moistureSensors',
        weatherStations: 'DASHBOARD.locationMap.weatherStations',
        vehicles: 'DASHBOARD.locationMap.vehicles',
        marker: {
          airTemperature: 'DASHBOARD.locationMap.marker.airTemperature',
          airHumidity: 'DASHBOARD.locationMap.marker.airHumidity',
          soilHumidityLevel1: 'DASHBOARD.locationMap.marker.soilHumidityLevel1',
          soilHumidityLevel2: 'DASHBOARD.locationMap.marker.soilHumidityLevel2',
          soilHumidityLevel3: 'DASHBOARD.locationMap.marker.soilHumidityLevel3',
          date: 'DASHBOARD.locationMap.marker.date',
        },
      },
      icons: {
        moistureSensorMarkerIconUrl: 'assets/markers/marker-moisture-sensor.png',
        weatherStationMarkerIconUrl: 'assets/markers/marker-weather-station.png',
        vehicleMarkerIconUrl: 'assets/markers/marker-vehicle.png',
      },
    };
  };
}
