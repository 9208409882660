<div
  class="container-fluid px-0"
  [appSpinner]="displaySpinner$ | async">
  <form
    class="form-horizontal ng-dynamic-form"
    [formGroup]="formGroup"
    *ngIf="formGroup && formModel$ | async as formModel">
    <fieldset>
      <dynamic-ng-bootstrap-form
        class="row"
        [group]="formGroup"
        [layout]="formLayout"
        [model]="formModel">
        <ng-template
          modelType="GROUP"
          align="START"
          let-legend="legend">
          <hr
            class="mt-0"
            [ngClass]="{ 'mt-0': true, 'mb-0': legend }" />
          <p
            *ngIf="legend"
            class="form-group-legend m-0 mb-1">
            {{ legend }}
          </p>
        </ng-template>
      </dynamic-ng-bootstrap-form>
    </fieldset>
  </form>
</div>
