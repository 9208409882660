<div
  class="pt-3 d-flex flex-column flex-md-row align-items-md-center justify-content-md-between"
  [ngClass]="{ 'pt-2 col-12': showInTab }">
  <h2>{{ schema.headerKey | translate }}</h2>
  <div>
    <button
      class="btn btn-raised btn-secondary ng-star-inserted w-100"
      (click)="addDocument()"
      type="button">
      <i class="ft ft-plus"></i> {{ schema.createButtonKey | translate }}
    </button>
  </div>
</div>
<div [appSpinner]="dataSource.loading$ | async">
  <div
    class="card"
    appPanelFullscreen>
    <div class="card-header pt-3">
      <label class="px-0">
        <h5
          class="m-0"
          *ngIf="schema.listHeaderKey">
          <i class="ft ft-list"></i>
          {{ schema.listHeaderKey | translate }}
        </h5>
      </label>
      <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
    </div>
    <div class="card-content">
      <div class="card-body">
        <div class="container-fluid table-container">
          <cdk-table
            [dataSource]="dataSource"
            cdkSort
            cdkSortActive="title"
            cdkSortDirection="desc">
            <ng-container cdkColumnDef="title">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                cdk-sort-header
                class="col-10 col-md-4 pr-0">
                {{ schema.listColumnTitleKey | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-10 col-md-4 pr-0">
                {{ element.title }}
                <span *ngIf="groundId == null && element.groundName != null">
                  <i
                    class="ft ft-info"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="{{ element.groundName }}"></i>
                </span>
              </div>
            </ng-container>
            <ng-container cdkColumnDef="description">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                [ngClass]="{
                  'col-md-5': showDocumentType,
                  'col-md-7': !showDocumentType
                }"
                class="pr-0 d-none d-md-block">
                {{ schema.listColumnDescriptionKey | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                [ngClass]="{
                  'col-md-5': showDocumentType,
                  'col-md-7': !showDocumentType
                }"
                class="pr-0 d-none d-md-block">
                <span
                  *ngIf="groundId == null && element.groundName != null"
                  class="font-italic">
                  [{{ element.groundName }}]
                </span>
                <span
                  *ngIf="fieldCardId == null && element.fieldCardName != null"
                  class="font-italic">
                  [{{ element.fieldCardName }}]
                </span>
                {{ element.description }}
              </div>
            </ng-container>
            <ng-container
              cdkColumnDef="documentType"
              *ngIf="showDocumentType">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-md-2 pr-0 d-none d-md-block">
                {{ schema.listColumnDocumentTypeKey | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-md-2 pr-0 d-none d-md-block">
                {{ schema.listColumnDocumentTypeTranslationPrefix + element.documentType | translate }}
              </div>
            </ng-container>
            <ng-container cdkColumnDef="action">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-2 col-md-1 p-0 text-center">
                {{ schema.listColumnActionsKey | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-2 col-md-1 p-0 pl-1 pl-md-0 pr-0 pr-md-1 w-100">
                <div class="d-flex">
                  <button
                    (click)="onDelete(element)"
                    title="{{ schema.listDeleteButtonKey | translate }}"
                    class="d-none d-md-block btn btn-raised m-0 my-1 mr-1 btn-sm shadow-none">
                    <i
                      class="ft ft-trash-2"
                      aria-hidden="true"></i>
                  </button>
                  <a
                    *ngIf="element.attachment"
                    href="{{ element.attachment.link }}"
                    title="{{ schema.listDownloadButtonKey | translate }}"
                    target="_blank"
                    class="btn btn-raised m-0 my-1 mr-1 btn-sm shadow-none">
                    <i
                      class="ft ft-download"
                      aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </ng-container>
            <cdk-header-row
              class="row header-navbar extra_bg-light-grey"
              *cdkHeaderRowDef="displayedColumns">
            </cdk-header-row>
            <cdk-row
              class="row row-striped align-items-center"
              *cdkRowDef="let row; columns: displayedColumns"></cdk-row>
          </cdk-table>
          <div
            class="row"
            *ngIf="dataSource.noData$ | async">
            <div class="col text-center py-3">
              {{ schema.listEmptyKey | translate }}
            </div>
          </div>
          <div
            class="row"
            *ngIf="dataSource.error$ | async">
            <div class="col text-center py-3">
              {{ schema.listErrorKey | translate }}
            </div>
          </div>
          <cdk-paginator
            class="row header-navbar d-flex justify-content-end pr-2 py-1"
            [pageSizeOptions]="[10, 25, 50, 75, 100]"
            showFirstLastButtons></cdk-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
