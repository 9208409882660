import { RouteInfo } from 'apex-shared';

// tslint:disable: no-duplicate-string
// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'APPLICATION.MENU.dashboard',
    icon: 'ft-monitor',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    components: ['Organization.Dashboard'],
  },
  {
    path: '',
    title: 'APPLICATION.MENU.supplierPortal',
    icon: 'ft-airplay',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: true,
    submenu: [],
    components: ['Organization.ManageOrganizations.Suppliers'],
  },
  {
    path: '/suppliers',
    title: 'APPLICATION.MENU.suppliers',
    icon: 'ft-briefcase',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    components: ['Organization.ManageOrganizations.Suppliers'],
  },
  {
    path: '',
    title: 'APPLICATION.MENU.contracts',
    icon: 'ft-clipboard',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/contracts',
        title: 'APPLICATION.MENU.CONTRACTS.agreements',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.ManageOrganizations.Contracts'],
      },
      {
        path: '/contracts/reports/suppliers',
        title: 'APPLICATION.MENU.CONTRACTS.reportSuppliers',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.ManageOrganizations.Contracts'],
      },
      {
        path: '/contracts/reports/product-catalogs',
        title: 'APPLICATION.MENU.CONTRACTS.reportProductCatalogs',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.ManageOrganizations.Contracts'],
      },
      {
        path: '/contracts/reports/deliveries',
        title: 'APPLICATION.MENU.CONTRACTS.reportDeliveries',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.ManageOrganizations.Contracts'],
      },
      {
        path: '/product-catalog-infos',
        title: 'APPLICATION.MENU.CONTRACTS.productCatalogInfos',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.ManageOrganizations.Contracts'],
      },
    ],
    components: ['Organization.ManageOrganizations.Contracts'],
  },
  {
    path: '',
    title: 'APPLICATION.MENU.deliveries',
    icon: 'ft-airplay',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/deliveries',
        title: 'APPLICATION.MENU.DELIVERIES.list',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Transport'],
      },
      {
        path: '/deliveries/reports/suppliers',
        title: 'APPLICATION.MENU.DELIVERIES.reportSuppliers',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Transport'],
      },
      {
        path: '/deliveries/reports/products',
        title: 'APPLICATION.MENU.DELIVERIES.reportProducts',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Transport'],
      },
    ],
    components: ['Organization.Transport'],
  },
  {
    path: '/organizations',
    title: 'APPLICATION.MENU.ogranizations',
    icon: 'ft-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/contacts',
    title: 'APPLICATION.MENU.contacts',
    icon: 'ft-users',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    components: ['Organization.ManageOrganizations'],
  },
  {
    path: '/messages',
    title: 'APPLICATION.MENU.messages',
    icon: 'ft-mail',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    components: ['Organization.Messages '],
  },
  {
    path: '/users',
    title: 'APPLICATION.MENU.organizationManagement',
    icon: 'ft-users',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    components: ['Organization.Administration'],
  },
  {
    path: '/notes',
    title: 'APPLICATION.MENU.notes',
    icon: 'ft-file',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    components: ['Organization.ManageOrganizations'],
  },
  {
    path: '',
    title: 'APPLICATION.MENU.audits',
    icon: 'ft-award',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    components: ['Organization.Lab', 'Organization.Audits', 'Organization.Staff.Tasks'],
    submenu: [
      {
        path: '/employee-tasks',
        title: 'APPLICATION.MENU.AUDITS.employeeTasks',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Staff.Tasks'],
      },
      {
        path: '/farm-audits',
        title: 'APPLICATION.MENU.AUDITS.farmAudits',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Audits'],
      },
      {
        path: '/field-audits',
        title: 'APPLICATION.MENU.AUDITS.fieldAudits',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Audits'],
      },
      {
        path: '/product-catalog-audits',
        title: 'APPLICATION.MENU.AUDITS.productCatalogAuditsForTechnologist',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Lab'],
      },
      {
        path: '/product-catalog-audit-results',
        title: 'APPLICATION.MENU.AUDITS.productCatalogAuditsForSecurityOffice',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Lab'],
      },
    ],
  },
  {
    path: '/support',
    title: 'APPLICATION.MENU.support',
    icon: 'ft-pocket',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    components: ['Organization.ManageOrganizations'],
  },
  {
    path: '/products',
    title: 'APPLICATION.MENU.products',
    icon: 'ft-box',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    components: ['Organization.Trade.Products'],
  },
  {
    path: '/weatherstations',
    title: 'APPLICATION.MENU.weatherStations',
    icon: 'ft-sun',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    components: ['Organization.Cultivations.WeatherStation'],
  },
  {
    path: '/moisturesensors',
    title: 'APPLICATION.MENU.moistureSensors',
    icon: 'ft-cloud-drizzle',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    components: ['Organization.Cultivations.WeatherStation'],
  },
  {
    path: '',
    title: 'APPLICATION.MENU.vehtel',
    icon: 'ft-navigation',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    components: ['Organization.Machine.Monitoring'],
    submenu: [
      {
        path: '/vehtel/device',
        title: 'APPLICATION.MENU.VEHTEL.devices',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/vehtel/tag',
        title: 'APPLICATION.MENU.VEHTEL.tags',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/vehtel/beacon',
        title: 'APPLICATION.MENU.VEHTEL.tags',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'APPLICATION.MENU.databases',
    icon: 'ft-list',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    components: ['Organization.Trade', 'Organization.Trade.Catalog'],
    submenu: [
      {
        path: '/pesticides',
        title: 'APPLICATION.MENU.pesticides',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Trade'],
      },
      {
        path: '/seeds',
        title: 'APPLICATION.MENU.seeds',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Trade'],
      },
      {
        path: '/fertilizers-db',
        title: 'APPLICATION.MENU.fertilizersDb',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/catalog-definitions',
        title: 'APPLICATION.MENU.catalogDefinitions',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Trade.Catalog'],
      },
    ],
  },
  {
    path: '',
    title: 'APPLICATION.MENU.reports',
    icon: 'ft-trending-up',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    components: ['Organization.Report'],
    submenu: [
      {
        path: '/report/fertilizer-report',
        title: 'APPLICATION.MENU.fertilizerReport',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Report.Fertilizers'],
      },
    ],
  },
  {
    path: '/fertilizer-stock-needs',
    title: 'APPLICATION.MENU.fertilizerStockNeeds',
    icon: 'ft-layers',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    components: ['Organization.ManageOrganizations'],
  },
  {
    path: '/transport-calculations',
    title: 'APPLICATION.MENU.transportCalculations',
    icon: 'ft-inbox',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    components: ['Organization.ManageOrganizations.Transport'],
  },
  {
    path: '/news',
    title: 'APPLICATION.MENU.news',
    icon: 'ft-file-text',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    components: ['Organization.News'],
  },
];
