import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { ApiListResponse, RequestList, NotificationService } from 'crmcloud-core';
import { OrganizationCoreServicesModule } from '../organization-core-services.module';
import { OrganizationCoreProxyService } from './organization-core-proxy.service';
import { IOrganizationDto } from '../models/organization.dto';
import { IOrganizationListFilter } from '../models/organization-list-filter';
import { IOrganizationListItemDto } from '../models/organization-list-item.dto';
import { ICreateOrganizationCommand, IUpdateOrganizationCommand } from '../models/organization-command';
import { IOrganizationSupplierDto } from '../models/organization-supplier.dto';

@Injectable({
  providedIn: OrganizationCoreServicesModule,
})
export class OrganizationCoreService {
  constructor(
    private organizationCoreProxyService: OrganizationCoreProxyService,
    private notificationService: NotificationService,
  ) {}

  getAll(): Observable<IOrganizationListItemDto[]> {
    return this.organizationCoreProxyService.getAll();
  }

  find(requestList: RequestList<IOrganizationListFilter>): Observable<ApiListResponse<IOrganizationListItemDto>> {
    return this.organizationCoreProxyService.find(requestList);
  }

  get(id: string): Observable<IOrganizationDto> {
    return this.organizationCoreProxyService.get(id);
  }

  getOrganizationSuppliers(id: string): Observable<IOrganizationSupplierDto[]> {
    return this.organizationCoreProxyService.getOrganizationSuppliers(id);
  }

  create(organizationCommand: ICreateOrganizationCommand): Observable<string> {
    organizationCommand.id = uuid();
    return this.organizationCoreProxyService.create(organizationCommand).pipe(
      tap(() => this.notificationService.success('ORGANIZATION.SERVICE.create:success')),
      catchError(error => {
        this.notificationService.error(error, 'ORGANIZATION.SERVICE.create:errorMessage');
        return of(null);
      }),
    );
  }

  update(id: string, organizationCommand: IUpdateOrganizationCommand): Observable<boolean> {
    return this.organizationCoreProxyService.update(id, organizationCommand).pipe(
      tap(() => this.notificationService.success('ORGANIZATION.SERVICE.update:success')),
      map(() => true),
      catchError(error => {
        this.notificationService.error(error, 'ORGANIZATION.SERVICE.update:errorMessage');
        throw of(false);
      }),
    );
  }

  delete(id: string): Observable<boolean> {
    return this.organizationCoreProxyService.delete(id).pipe(
      tap(() => this.notificationService.success('ORGANIZATION.SERVICE.delete:success')),
      map(() => true),
      catchError(error => {
        this.notificationService.error(error, 'ORGANIZATION.SERVICE.delete:errorMessage');
        return of(false);
      }),
    );
  }
}
