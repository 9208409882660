import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { FcTokenService } from 'crmcloud-core';

@Injectable()
export class UserGuard implements CanActivate {
  constructor(
    private router: Router,
    private fcService: FcTokenService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    // tslint:disable-next-line: max-union-size
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.fcService.currentFcToken != null
      ? true
      : this.router.createUrlTree(['/load-user-data'], {
          queryParams: {
            returnUrl: state.url,
          },
          skipLocationChange: true,
        });
  }
}
