<div
  class="flex-grow-1"
  style="height: 400px">
  <app-map
    [features]="features"
    [mapConfig]="mapConfig"
    [markerConfig]="markerConfig"
    [polygonConfig]="polygonConfig">
  </app-map>
</div>
