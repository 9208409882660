<div
  class="card"
  appPanelFullscreen>
  <div class="card-header pt-2">
    <h4 class="card-title mb-0">
      <i class="ft ft-bar-chart"></i>
      {{ 'ORGANIZATION.PROFILE.cultivations' | translate }}
      <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
      <app-panel-hide-button
        [hidden]="false"
        (hiddenChange)="panelHide.setHidden($event)"
        class="float-right mr-1"></app-panel-hide-button>
    </h4>
  </div>
  <div class="card-content">
    <div class="card-body py-1 pt-2">
      <div
        class="container-fluid px-0"
        [appPanelHide]="false">
        <div class="row justify-content-center">
          <div
            class="col-12 col-xs-12 col-sm-12 col-md-10 col-lg-9 chart-height"
            *ngIf="dominantCropArray.length > 0">
            <ngx-charts-bar-vertical
              [scheme]="colorScheme"
              [legend]="showLegend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxis]="xAxis"
              [yAxis]="yAxis"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              [results]="dominantCropArray"
              [schemeType]="schemeType"
              [showDataLabel]="showDataLabel"
              [dataLabelFormatting]="formatDataLabel"
              [barPadding]="barPadding">
              <ng-template
                #tooltipTemplate
                let-model="model">
                <span *ngIf="model">
                  <b>{{ model.name }}: {{ (model.value | number: '1.2-2') + 'ha' }}</b
                  ><br />
                </span>
              </ng-template>
            </ngx-charts-bar-vertical>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
