<div
  class="card"
  appPanelFullscreen>
  <div class="card-header pt-3">
    <label class="px-0">
      <h5 class="m-0"><i class="ft ft-map"></i> {{ schema.headerKey | translate }}</h5>
    </label>
    <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
    <app-panel-hide-button
      [hidden]="false"
      (hiddenChange)="panelHide.setHidden($event)"
      class="float-right mr-1"></app-panel-hide-button>
    <hr class="my-0" />
  </div>
  <div class="card-content">
    <div class="card-body py-1">
      <div
        class="container-fluid px-0 pb-2"
        [appPanelHide]="false">
        <app-pure-map></app-pure-map>
      </div>
    </div>
  </div>
</div>
