<div [appSpinner]="displaySpinner$ | async">
  <div
    class="mb-2"
    [appSpinner]="dataSource.loading$ | async">
    <div
      class="card"
      appPanelFullscreen>
      <div class="d-flex flex-column flex-md-row justify-content-md-between card-header pt-3">
        <label class="px-0">
          <h5 class="m-0">
            <i class="ft ft-list"></i>
            {{ schema.tableHeaderKey | translate }}
          </h5>
        </label>
        <app-panel-fullscreen-button></app-panel-fullscreen-button>
      </div>
      <div class="card-content">
        <div class="card-body">
          <div class="container-fluid table-container">
            <cdk-table
              [dataSource]="dataSource"
              cdkSort
              cdkSortActive="date"
              cdkSortDirection="desc">
              <ng-container cdkColumnDef="type">
                <div
                  cdk-header-cell
                  *cdkHeaderCellDef
                  class="col-2 pr-0">
                  {{ schema.flowTypeKey | translate }}
                </div>
                <div
                  cdk-cell
                  *cdkCellDef="let element"
                  class="col-2 pr-0">
                  {{ element.type | enumTranslateKey: schema.flowTypeDictionaryKey | translate }}
                </div>
              </ng-container>
              <ng-container cdkColumnDef="date">
                <div
                  cdk-header-cell
                  *cdkHeaderCellDef
                  cdk-sort-header
                  class="col-2 pr-0">
                  {{ schema.flowDateKey | translate }}
                </div>
                <div
                  cdk-cell
                  *cdkCellDef="let element"
                  class="col-2 pr-0">
                  {{ element.date | date: 'shortDate' }}
                </div>
              </ng-container>
              <ng-container cdkColumnDef="quantity">
                <div
                  cdk-header-cell
                  *cdkHeaderCellDef
                  class="col-2 pr-0">
                  {{ schema.quantityKey | translate }}
                </div>
                <div
                  cdk-cell
                  *cdkCellDef="let element"
                  class="col-2 pr-0">
                  {{ element.quantity | number: '1.3-3' }}
                  {{ element.productUnit | enumTranslateKey: 'APPLICATION.DICTIONARIES.WAREHOUSE_PRODUCT_UNIT_SHORT' | translate }}
                </div>
              </ng-container>
              <ng-container cdkColumnDef="lotNumber">
                <div
                  cdk-header-cell
                  *cdkHeaderCellDef
                  class="col-2 pr-0">
                  {{ schema.lotNumberKey | translate }}
                </div>
                <div
                  cdk-cell
                  *cdkCellDef="let element"
                  class="col-2 pr-0">
                  {{ element.lotNumber || '-' }}
                </div>
              </ng-container>
              <ng-container cdkColumnDef="warehouse">
                <div
                  cdk-header-cell
                  *cdkHeaderCellDef
                  class="col-2 pr-0">
                  {{ schema.warehouseKey | translate }}
                </div>
                <div
                  cdk-cell
                  *cdkCellDef="let element"
                  class="col-2 pr-0">
                  {{ element.warehouseName }}
                </div>
              </ng-container>
              <ng-container cdkColumnDef="action">
                <div
                  cdk-header-cell
                  *cdkHeaderCellDef
                  class="col-2 text-md-right">
                  {{ schema.actionsKey | translate | translate }}
                </div>
                <div
                  cdk-cell
                  *cdkCellDef="let element"
                  class="col-2 p-0 pl-1 pl-md-0 pr-0 pr-md-1 w-100">
                  <div class="sticky-action">
                    <div class="row no-gutters justify-content-md-end">
                      <button
                        (click)="onDelete(element)"
                        title="{{ schema.deleteButtonKey | translate }}"
                        class="btn btn-raised m-0 my-1 mr-1 btn-sm btn-danger">
                        <i
                          class="ft ft-trash-2"
                          aria-hidden="true"></i>
                      </button>
                      <button
                        (click)="onEdit(element)"
                        title="{{ schema.editButtonKey | translate }}"
                        class="btn btn-raised m-0 my-1 mr-1 btn-sm btn-success">
                        <i
                          class="ft ft-edit-2"
                          aria-hidden="true"></i>
                      </button>
                      <button
                        (click)="onDetails(element)"
                        title="{{ schema.detailsButtonKey | translate }}"
                        class="btn btn-raised m-0 my-1 mr-1 btn-sm btn-info">
                        <i
                          class="ft ft-info"
                          aria-hidden="true"></i>
                      </button>
                      <button
                        *ngIf="hasQrCode(element)"
                        (click)="onQrCode(element)"
                        title="{{ schema.qrCodeButton | translate }}"
                        class="btn btn-raised m-0 my-1 mr-1 btn-sm btn-success">
                        <i
                          class="ft ft-target"
                          aria-hidden="true"></i>
                      </button>
                      <button
                        *ngIf="hasOutflowQrCode(element)"
                        (click)="onOutflowQrCode(element)"
                        title="{{ schema.qrCodeButton | translate }}"
                        class="btn btn-raised m-0 my-1 mr-1 btn-sm btn-success">
                        <i
                          class="ft ft-target"
                          aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
              <cdk-header-row
                class="row header-navbar extra_bg-light-grey"
                *cdkHeaderRowDef="displayedColumns">
              </cdk-header-row>
              <cdk-row
                class="row row-striped align-items-center"
                *cdkRowDef="let row; columns: displayedColumns"></cdk-row>
            </cdk-table>
            <div
              class="row"
              *ngIf="dataSource.noData$ | async">
              <div class="col text-center py-3">
                {{ schema.listEmptyKey | translate }}
              </div>
            </div>
            <div
              class="row"
              *ngIf="dataSource.error$ | async">
              <div class="col text-center py-3">
                {{ schema.listErrorKey | translate }}
              </div>
            </div>
            <cdk-paginator
              class="row header-navbar d-flex justify-content-end pr-2 py-1"
              [pageSizeOptions]="[10, 25, 50, 75, 100]"
              showFirstLastButtons></cdk-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
