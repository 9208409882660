<div
  class="email-app-mail-content-detail pt-2"
  *ngIf="message; else notSelected">
  <div class="email-app-options card-body d-md-none my-0 py-0">
    <button
      class="btn btn-raised btn-primary back-to-inbox"
      (click)="onBack()">
      <i class="fa fa-angle-left"></i>{{ 'MESSAGE.SHARED.detail:back' | translate }}
    </button>
  </div>
  <div class="email-app-title card-body py-0">
    <h3 class="list-group-item-heading text-left">{{ message.title }}</h3>
    <div class="d-flex justify-content-between py-2">
      <span
        class="badge"
        [ngClass]="badgeBgClassName">
        {{ message.type | enumTranslateKey: 'APPLICATION.DICTIONARIES.MESSAGES_TYPES' | translate }}
      </span>
      <span class="primary text-right">{{ message.creationDate | i18nDate: 'fullDate' }}</span>
    </div>
  </div>
  <div
    id="headingCollapse2"
    class="card-header p-0">
    <div
      class="email-app-sender list-group-item list-group-item-action no-border"
      *ngIf="message.sender && message.sender.length > 0">
      <div class="media">
        <div class="avatar avatar-md mr-2 align-self-center">
          <span class="media-object rounded-circle text-circle d-flex mr-2 justify-content-center align-items-center bg-info">
            {{ message.sender[0] | uppercase }}
          </span>
        </div>
        <div class="media-body text-left align-self-center">
          <h6 class="list-group-item-heading my-0">
            {{ message.sender }}
          </h6>
        </div>
      </div>
    </div>
  </div>
  <div [id]="message.id">
    <div class="card-content">
      <div class="email-app-text card-body">
        <div class="email-app-message text-left">
          <div [innerHTML]="message.body | farmPortalWeatherStationsReplaceLink"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #notSelected>
  <div class="email-app-mail-content-detail pt-2">
    <p class="primary text-center">
      {{ 'MESSAGE.SHARED.detail:notSelected' | translate }}
    </p>
  </div>
</ng-template>
