<ng-container [formGroup]="group">
  <lib-map-cultivation-area-control
    formControlName="{{ model.id }}"
    [organizationLocations]="model.organizationLocations"
    [organizationGrounds]="model.organizationGrounds"
    [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)">
  </lib-map-cultivation-area-control>
</ng-container>
