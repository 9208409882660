<div
  class="container-fluid px-0"
  *ngIf="formModel$ | async as formModel">
  <form
    class="form-horizontal ng-dynamic-form"
    [formGroup]="formGroup">
    <fieldset class="w-100">
      <dynamic-ng-bootstrap-form
        class="row no-gutters"
        [group]="formGroup"
        [layout]="formLayout"
        [model]="formModel">
        <ng-template
          modelType="GROUP"
          align="START"
          let-legend="legend">
          <hr
            class="mt-0"
            [ngClass]="{ 'mt-0': true, 'mb-0': legend }" />
          <p
            *ngIf="legend"
            class="form-group-legend m-0 mb-1">
            {{ legend }}
          </p>
        </ng-template>
      </dynamic-ng-bootstrap-form>
    </fieldset>
  </form>
  <div class="w-100 text-right d-flex justify-content-end">
    <button
      class="btn btn-raised btn-secondary mr-1 mb-0"
      type="button"
      (click)="cancel()">
      <i class="ft-x"></i> {{ 'APPLICATION.FORM.cancel' | translate }}
    </button>
    <button
      class="btn btn-raised btn-primary mb-0"
      type="button"
      (click)="addClick()">
      <i class="ft-save"></i> {{ 'APPLICATION.FORM.add' | translate }}
    </button>
  </div>
</div>
