var WeatherStation = /** @class */ (function () {
    function WeatherStation(dto, deviceType) {
        this.id = dto.id;
        this.serialNumber = dto.serialNumber;
        this.status = dto.status;
        this.createdAt = dto.createdAt;
        this.registrationDate = dto.registrationDate;
        this.lastActiveDate = dto.lastActiveDate;
        this.organizationId = dto.organizationId;
        this.organizationName = dto.organizationName;
        this.location = dto.location;
        this.isDeleted = dto.isDeleted;
        this.deviceType = deviceType;
    }
    return WeatherStation;
}());
export { WeatherStation };
