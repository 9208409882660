import { OnInit, OnDestroy } from '@angular/core';
import { of, forkJoin, BehaviorSubject } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { DeviceType, NotificationService } from 'crmcloud-core';
import { LocationMapItem, LocationMapSchema, LocationMapItemType } from 'crmcloud-shared';
import { WeatherStationCoreService } from '@crm-portal/core/weather-station';
var WidgetLocationComponent = /** @class */ (function () {
    function WidgetLocationComponent(weatherStationCoreService, notificationService) {
        var _this = this;
        this.weatherStationCoreService = weatherStationCoreService;
        this.notificationService = notificationService;
        this.displaySpinner$ = new BehaviorSubject(true);
        this.createMoistureSensorLocationMapItem = function (result) {
            return (result || [])
                .filter(function (x) { return x.location != null; })
                .map(function (x) { return new LocationMapItem(LocationMapItemType.MoistureSensor, x.location, x.serialNumber, x.lastActiveDate); });
        };
        this.createWeatherStationLocationMapItem = function (result) {
            return (result || [])
                .filter(function (x) { return x.location != null; })
                .map(function (x) { return new LocationMapItem(LocationMapItemType.WeatherStation, x.location, x.serialNumber, x.lastActiveDate); });
        };
        this.initSchema = function () {
            _this.locationMapSchema = {
                translations: {
                    moistureSensors: 'DASHBOARD.locationMap.moistureSensors',
                    weatherStations: 'DASHBOARD.locationMap.weatherStations',
                    vehicles: 'DASHBOARD.locationMap.vehicles',
                    marker: {
                        airTemperature: 'DASHBOARD.locationMap.marker.airTemperature',
                        airHumidity: 'DASHBOARD.locationMap.marker.airHumidity',
                        soilHumidityLevel1: 'DASHBOARD.locationMap.marker.soilHumidityLevel1',
                        soilHumidityLevel2: 'DASHBOARD.locationMap.marker.soilHumidityLevel2',
                        soilHumidityLevel3: 'DASHBOARD.locationMap.marker.soilHumidityLevel3',
                        date: 'DASHBOARD.locationMap.marker.date',
                    },
                },
                icons: {
                    moistureSensorMarkerIconUrl: 'assets/markers/marker-moisture-sensor.png',
                    weatherStationMarkerIconUrl: 'assets/markers/marker-weather-station.png',
                    vehicleMarkerIconUrl: 'assets/markers/marker-vehicle.png',
                },
            };
        };
        this.initSchema();
        this.locationData = forkJoin({
            moistureSensors: this.weatherStationCoreService.getAll(DeviceType.MoistureSensor).pipe(catchError(function (error) {
                _this.notificationService.error(error, "DASHBOARD.getMoisturesSensors:error");
                return of(null);
            })),
            weatherStations: this.weatherStationCoreService.getAll(DeviceType.WeatherStation).pipe(catchError(function (error) {
                _this.notificationService.error(error, "DASHBOARD.getWeatherStationsSensors:error");
                return of(null);
            })),
        });
    }
    WidgetLocationComponent.prototype.ngOnInit = function () {
        this.loadLocationMapItems();
    };
    WidgetLocationComponent.prototype.ngOnDestroy = function () {
        this.displaySpinner$.next(false);
        this.displaySpinner$.complete();
    };
    WidgetLocationComponent.prototype.loadLocationMapItems = function () {
        var _this = this;
        this.displaySpinner$.next(true);
        this.locationData.pipe(finalize(function () { return _this.displaySpinner$.next(false); })).subscribe(function (x) {
            var moistureSensors = _this.createMoistureSensorLocationMapItem(x.moistureSensors);
            var weatherStations = _this.createWeatherStationLocationMapItem(x.weatherStations);
            _this.locationMapItems = moistureSensors.concat(weatherStations);
        });
    };
    return WidgetLocationComponent;
}());
export { WidgetLocationComponent };
