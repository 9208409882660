<div style="height: 620px">
  <app-map
    [features]="features"
    [mapConfig]="mapConfig"
    [markerConfig]="markerConfig"
    [polygonConfig]="polygonConfig">
  </app-map>
  <div
    class="legend"
    *ngIf="colors != null && colors.length > 0">
    <div *ngFor="let color of colors">
      <span
        class="color"
        [ngStyle]="{ 'background-color': color.color }"></span>
      <span class="label">{{ color.label | translate }}</span>
    </div>
  </div>
</div>
