<lib-warehouse-flow-filter
  [translationKeyPrefix]="schema.filterTranslationPrefixKey"
  (search)="onSearch($event)"></lib-warehouse-flow-filter>

<lib-warehouse-flow-table
  [showQrCodeButton]="showQrCodeButton"
  [productId]="productId"
  [schema]="schema"
  [filter]="searchFilter"
  (edit)="onEdit($event)"
  (details)="onDetails($event)"
  (qrCode)="onQrCode($event)"
  (qrOutflowCode)="onOutflowQrCode($event)"></lib-warehouse-flow-table>
