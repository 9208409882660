import { Injectable } from '@angular/core';
import { CreateContact, ContactProxyService } from '@crm-portal/core/contact';
import { ApiListResponse, CreateEntity, RequestList, NotificationService } from 'crmcloud-core';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { ContactAssignmentListItem } from '../models/contact-assignment-list-item.model';
import { OrganizationListFilter } from '../models/organization-list-filter.model';
import { OrganizationListItem } from '../models/organization-list-item.model';
import { OrganizationProxyService } from './organization-proxy.service';
import { MachineListFilter, MachineListItem } from 'projects/crmcloud-shared/src/public-api';
import { Machine } from 'projects/crmcloud-shared/src/lib/machine/common/models/machine';

@Injectable({
  providedIn: 'root',
})
export class OrganizationListService {
  constructor(
    private organizationProxyService: OrganizationProxyService,
    private contactProxyService: ContactProxyService,
    private notificationService: NotificationService,
  ) {}

  getList(requestList: RequestList<OrganizationListFilter>): Observable<ApiListResponse<OrganizationListItem>> {
    return this.organizationProxyService.getList(requestList);
  }

  getAssignedContactList(id: string): Observable<ContactAssignmentListItem[]> {
    return this.organizationProxyService.getAssignedContactList(id);
  }

  getContactList(id: string, term: string): Observable<ContactAssignmentListItem[]> {
    return this.organizationProxyService.getContactList(id, term);
  }

  assignToContact(id: string, contactId: string): Observable<void> {
    return this.organizationProxyService.assignToContact(id, contactId);
  }

  createContact(model: CreateEntity<CreateContact>, organizationId: string): Observable<null | string> {
    model.contactId = uuid();
    return this.contactProxyService.create(model).pipe(
      switchMap(id => this.assignToContact(organizationId, model.contactId).pipe(map(() => id))),
      map(() => {
        this.notificationService.success(`CONTACT.create:success`);
        return model.contactId;
      }),
      catchError(error => {
        this.notificationService.error(error, `CONTACT.create:errorMessage`);
        return of(null);
      }),
    );
  }

  getMachines(requestList: RequestList<MachineListFilter>, id: string): Observable<ApiListResponse<MachineListItem>> {
    return this.organizationProxyService.getMachines(id, requestList).pipe(
      map(x => ({
        items: x.items,
        pager: x.pager,
      })),
    );
  }

  getMachine(organizationId: string, id: string): Observable<Machine> {
    return this.organizationProxyService.getMachine(organizationId, id);
  }

  updateMachine(organizationId: string, id: string, model: FormData): Observable<void> {
    return this.organizationProxyService.updateMachine(organizationId, id, model).pipe(
      tap(() => this.notificationService.success(`MACHINE.update:success`)),
      catchError(error => {
        this.notificationService.error(error, `MACHINE.update:errorMessage`);
        throw error;
      }),
    );
  }

  deleteMachine(organizationId: string, id: string): Observable<void> {
    return this.organizationProxyService.deleteMachine(organizationId, id).pipe(
      tap(() => this.notificationService.success(`MACHINE.delete:success`)),
      catchError(error => {
        this.notificationService.error(error, `MACHINE.delete:errorMessage`);
        throw error;
      }),
    );
  }

  createMachine(organizationId: string, model: FormData): Observable<Machine> {
    return this.organizationProxyService.createMachine(organizationId, model).pipe(
      tap(() => this.notificationService.success(`MACHINE.create:success`)),
      catchError(error => {
        this.notificationService.error(error, `MACHINE.create:errorMessage`);
        throw error;
      }),
    );
  }
}
