<div class="d-flex align-items-center justify-content-between">
  <h2 class="m-0">{{ schema.headerKey | translate }}</h2>
  <div>
    <button
      class="btn btn-raised ml-3 btn-info"
      (click)="onImportFromShp()">
      <i class="ft-plus-circle"></i>
      {{ schema.importSHPButtonKey | translate }}
    </button>
    <button
      class="btn btn-raised ml-3 btn-info"
      (click)="onImportFromKml()">
      <i class="ft-plus-circle"></i>
      {{ schema.importKMLButtonKey | translate }}
    </button>
    <button
      class="btn btn-raised ml-3 btn-info d-none"
      (click)="onImportFromGeojson()">
      <i class="ft-plus-circle"></i>
      {{ schema.importGEOJSONButtonKey | translate }}
    </button>
  </div>
</div>

<div
  class="card"
  [ngClass]="{ 'd-none': showResult }"
  appPanelFullscreen>
  <div class="card-content">
    <div class="card-body">
      <div class="container-fluid">
        <h4 class="m-0">
          {{ schema.importSelectFileInfoKey | translate }}
        </h4>
      </div>
    </div>
  </div>
</div>

<div
  class="card"
  [ngClass]="{ 'd-none': !showResult }"
  appPanelFullscreen>
  <div class="card-header pt-3">
    <label class="px-0">
      <h5 class="m-0"><i class="ft ft-map"></i> {{ schema.mapHeaderKey | translate }}</h5>
    </label>
    <app-panel-hide-button
      [hidden]="false"
      (hiddenChange)="panelHide.setHidden($event)"
      class="float-right"></app-panel-hide-button>
    <hr class="my-0" />
  </div>
  <div class="card-content">
    <div class="card-body py-1">
      <div
        class="container-fluid px-0"
        [appPanelHide]="false">
        <div style="height: 450px">
          <app-map
            [mapConfig]="mapConfig"
            [polygonConfig]="polygonConfig"
            [markerConfig]="markerConfig"
            [features]="features">
          </app-map>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="mb-2"
  [ngClass]="{ 'd-none': !showResult }"
  [appSpinner]="dataSource.loading$ | async">
  <div
    class="card"
    appPanelFullscreen>
    <div class="card-header pt-3">
      <label class="px-0">
        <h5 class="m-0">
          <i class="ft ft-list"></i>
          {{ schema.listHeaderKey | translate }}
        </h5>
      </label>
      <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
    </div>
    <div class="card-content">
      <div class="card-body">
        <div class="container-fluid">
          <cdk-table [dataSource]="dataSource">
            <ng-container cdkColumnDef="choose">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-1 pr-0">
                <span class="d-none d-sm-block">{{ schema.listColumnChooseKey | translate }}</span>
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-1 pr-0">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="element.isSelected"
                    [disabled]="!element.isAvailableSelect"
                    (change)="element.isSelected = !element.isSelected" />
                </div>
              </div>
            </ng-container>

            <ng-container cdkColumnDef="name">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-8 col-sm-6 pr-0">
                {{ schema.listColumnNameKey | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-8 col-sm-6 pr-0">
                <span
                  [ngClass]="{
                    'font-italic': !element.isSelected
                  }"
                  >{{ element.name }}</span
                >
              </div>
            </ng-container>

            <ng-container cdkColumnDef="areaInHa">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="d-none d-sm-block col-sm-3 col-md-4 pr-0">
                {{ schema.listColumnAreaInHaKey | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="d-none d-sm-block col-sm-3 col-md-4 pr-0">
                <span
                  [ngClass]="{
                    'font-italic': !element.isSelected
                  }"
                  >{{ element.areaInHa }}</span
                >
              </div>
            </ng-container>

            <ng-container cdkColumnDef="action">
              <div
                cdk-header-cell
                *cdkHeaderCellDef
                class="col-3 col-sm-2 col-md-1 text-right">
                {{ schema.listColumnActionsKey | translate | translate }}
              </div>
              <div
                cdk-cell
                *cdkCellDef="let element"
                class="col-3 col-sm-2 col-md-1 p-0 pl-1 pl-md-0 pr-0 pr-md-1 w-100">
                <div class="row no-gutters justify-content-end">
                  <button
                    (click)="onShowOnMap(element)"
                    title="{{ schema.listShowOnMapButtonKey | translate }}"
                    class="btn btn-raised m-0 my-1 mr-1 btn-sm btn-info">
                    <i
                      class="ft ft-search"
                      aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </ng-container>

            <cdk-header-row
              class="row header-navbar extra_bg-light-grey"
              *cdkHeaderRowDef="displayedColumns"></cdk-header-row>
            <cdk-row
              class="row row-striped align-items-center"
              *cdkRowDef="let row; columns: displayedColumns"></cdk-row>
          </cdk-table>
          <div
            class="row"
            *ngIf="dataSource.noData$ | async">
            <div class="col text-center py-3">
              {{ schema.listEmptyKey | translate }}
            </div>
          </div>
          <div
            class="row"
            *ngIf="dataSource.error$ | async">
            <div class="col text-center py-3">
              {{ schema.listErrorKey | translate }}
            </div>
          </div>
          <div class="d-none">
            <cdk-paginator
              class="row header-navbar d-flex justify-content-end pr-2 py-1"
              [pageSizeOptions]="[10, 25, 50, 75, 100]"
              showFirstLastButtons></cdk-paginator>
          </div>

          <div class="row">
            <button
              (click)="onImport()"
              [disabled]="!isImportEnabled"
              title="{{ schema.listImportButtonKey | translate }}"
              class="btn btn-raised btn-lg btn-info w-100 mt-3">
              <span>{{ schema.listImportButtonKey | translate }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
