/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./full-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../dist/crmcloud-shared/crmcloud-shared.ngfactory";
import * as i3 from "crmcloud-shared";
import * as i4 from "@angular/common";
import * as i5 from "crmcloud-core";
import * as i6 from "apex-shared";
import * as i7 from "@angular/router";
import * as i8 from "../../../../../../../dist/apex-shared/apex-shared.ngfactory";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../navbar/user-nav-item/user-nav-item.component.ngfactory";
import * as i11 from "../navbar/user-nav-item/user-nav-item.component";
import * as i12 from "../../message/services/message-core-notification.service";
import * as i13 from "../../message/services/message-core-proxy.service";
import * as i14 from "./full-layout.component";
import * as i15 from "../../auth/services/auth.service";
import * as i16 from "../../auth/services/auth-store.service";
var styles_FullLayoutComponent = [i0.styles];
var RenderType_FullLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FullLayoutComponent, data: {} });
export { RenderType_FullLayoutComponent as RenderType_FullLayoutComponent };
function View_FullLayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-message-unread-notification", [], null, null, null, i2.View_MessageUnreadNotificationComponent_0, i2.RenderType_MessageUnreadNotificationComponent)), i1.ɵdid(2, 114688, null, 0, i3.MessageUnreadNotificationComponent, [i3.MESSAGE_UNREAD_SERVICE], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_FullLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 36, "div", [["class", "wrapper main-spinner"]], [[8, "dir", 0], [2, "position-relative", null]], null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "nav-collapsed": 0, "menu-collapsed": 1 }), i1.ɵdid(4, 671744, null, 0, i5.SpinnerDirective, [i1.ElementRef, i1.ViewContainerRef, i1.ComponentFactoryResolver, i1.Renderer2], { show: [0, "show"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 9, "div", [["appSidebar", ""], ["class", "app-sidebar d-print-none"]], null, [[null, "toggleHideSidebar"], [null, "mouseenter"], [null, "mouseleave"], [null, "click"], ["window", "resize"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onMouseOut($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10).onClick($event) !== false);
        ad = (pd_2 && ad);
    } if (("window:resize" === en)) {
        var pd_3 = (i1.ɵnov(_v, 10).onResize($event) !== false);
        ad = (pd_3 && ad);
    } if (("document:click" === en)) {
        var pd_4 = (i1.ɵnov(_v, 10).onOutsideClick($event) !== false);
        ad = (pd_4 && ad);
    } if (("toggleHideSidebar" === en)) {
        var pd_5 = (_co.toggleHideSidebar($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "hide-sidebar": 0 }), i1.ɵdid(10, 4276224, null, 0, i6.SidebarDirective, [i1.ElementRef, i1.Renderer2, i7.Router, i1.ChangeDetectorRef], null, { toggleHideSidebar: "toggleHideSidebar" }), (_l()(), i1.ɵeld(11, 0, null, null, 3, "app-sidebar", [], null, null, null, i8.View_SidebarComponent_0, i8.RenderType_SidebarComponent)), i1.ɵdid(12, 4440064, null, 0, i6.SidebarComponent, [i1.Renderer2, i9.TranslateService, i6.ConfigService, i6.LayoutService], { menuItems: [0, "menuItems"], userClaims: [1, "userClaims"], logoUrl: [2, "logoUrl"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["class", "text align-middle"], ["mainLogo", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["AgriCRM"])), (_l()(), i1.ɵeld(15, 0, null, null, 0, "div", [["class", "sidebar-background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 14, "app-navbar", [], null, [[null, "toggleHideSidebar"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("toggleHideSidebar" === en)) {
        var pd_0 = (_co.toggleHideSidebar($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_NavbarComponent_0, i8.RenderType_NavbarComponent)), i1.ɵdid(17, 49152, null, 0, i6.NavbarComponent, [i9.TranslateService], { logoUrl: [0, "logoUrl"] }, { toggleHideSidebar: "toggleHideSidebar" }), (_l()(), i1.ɵeld(18, 0, null, 0, 0, "img", [["class", "logo-farmportal d-none d-lg-block"], ["mainLogo", ""], ["src", "assets/images/agricrm_logo.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, 0, 11, "ul", [["class", "navbar-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "li", [["class", "nav-item mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "app-toggle-fullscreen", [], null, null, null, i2.View_ɵgm_0, i2.RenderType_ɵgm)), i1.ɵdid(22, 49152, null, 0, i3.ɵgm, [], null, null), (_l()(), i1.ɵeld(23, 0, null, null, 2, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "app-lang-selector", [], null, null, null, i2.View_LangSelectorComponent_0, i2.RenderType_LangSelectorComponent)), i1.ɵdid(25, 114688, null, 0, i3.LangSelectorComponent, [i5.TranslateService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullLayoutComponent_1)), i1.ɵdid(27, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(28, 0, null, null, 2, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 1, "app-user-nav-item", [], null, [[null, "logout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("logout" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_UserNavItemComponent_0, i10.RenderType_UserNavItemComponent)), i1.ɵdid(30, 49152, null, 0, i11.UserNavItemComponent, [], { user: [0, "user"] }, { logout: "logout" }), (_l()(), i1.ɵeld(31, 0, null, null, 5, "div", [["class", "main-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 4, "div", [["class", "main-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 3, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(35, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(36, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "wrapper main-spinner"; var currVal_3 = _ck(_v, 3, 0, _co.iscollapsed, _co.iscollapsed); _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.displaySpinner$)); _ck(_v, 4, 0, currVal_4); var currVal_5 = "app-sidebar d-print-none"; var currVal_6 = _ck(_v, 9, 0, _co.hideSidebar); _ck(_v, 8, 0, currVal_5, currVal_6); _ck(_v, 10, 0); var currVal_7 = _co.routes; var currVal_8 = _co.groupClaims; var currVal_9 = "assets/logo.svg"; _ck(_v, 12, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = "assets/images/agricrm_logo.svg"; _ck(_v, 17, 0, currVal_10); _ck(_v, 25, 0); var currVal_11 = _co.hasAccess("Organization.Messages"); _ck(_v, 27, 0, currVal_11); var currVal_12 = _co.user; _ck(_v, 30, 0, currVal_12); _ck(_v, 36, 0); }, function (_ck, _v) { var currVal_0 = "ltr"; var currVal_1 = i1.ɵnov(_v, 4).elementClass; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_FullLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-full-layout", [], null, null, null, View_FullLayoutComponent_0, RenderType_FullLayoutComponent)), i1.ɵprd(4608, null, i3.MESSAGE_UNREAD_SERVICE, i12.MessageCoreNotificationService, [i13.MessageCoreProxyService, i5.AppConfigurationService]), i1.ɵdid(2, 245760, null, 0, i14.FullLayoutComponent, [i5.SpinnerService, i15.AuthService, i16.AuthStoreService, i5.AppConfigurationService, i5.FeatureService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var FullLayoutComponentNgFactory = i1.ɵccf("app-full-layout", i14.FullLayoutComponent, View_FullLayoutComponent_Host_0, {}, {}, []);
export { FullLayoutComponentNgFactory as FullLayoutComponentNgFactory };
