import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, throwError, of, forkJoin } from 'rxjs';
import { catchError, filter, take, switchMap, finalize, mergeMap } from 'rxjs/operators';
import { AppConfigurationService, NotificationService } from 'crmcloud-core';
import { AuthProxyService } from './auth-proxy.service';
import { AuthStorageService } from './auth-storage.service';
//TODO This import causes errors, but I cannot find a way to fix it
// import { FARM_PORTAL_TOKEN } from '../models/user.dto';
import { StorageKey } from '../models/storage-key.enum';
import * as i0 from "@angular/core";
import * as i1 from "./auth-proxy.service";
import * as i2 from "./auth-storage.service";
import * as i3 from "crmcloud-core";
import * as i4 from "@angular/router";
//NOTE This is a temporary solution, is should be replaced with import (see lines 13-14)
var AGRICRM_TOKEN = 'agricrm';
var AuthInterceptorService = /** @class */ (function () {
    function AuthInterceptorService(authProxyService, storageService, config, router, notificationService) {
        this.authProxyService = authProxyService;
        this.storageService = storageService;
        this.config = config;
        this.router = router;
        this.notificationService = notificationService;
        this.AUTH_HEADER = 'Authorization';
        this.refreshTokenInProgress = false;
        this.refreshTokenSubject = new BehaviorSubject(null);
        this.apiRefreshUrl = config.configuration.api_url + "/api/TokenAuth/RefreshToken";
    }
    AuthInterceptorService.prototype.intercept = function (req, next) {
        var _this = this;
        return this.addAuthenticationToken(req).pipe(mergeMap(function (auhtorizerReq) {
            return next.handle(auhtorizerReq).pipe(catchError(function (error) {
                if (error && error.status === 401) {
                    // 401 errors are most likely going to be because we have an expired token that we need to refresh.
                    if (_this.refreshTokenInProgress) {
                        //This is scenario when request refresh access token fails
                        if (req.url.includes(_this.apiRefreshUrl)) {
                            return throwError(error);
                        }
                        // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                        // which means the new token is ready and we can retry the request again
                        return _this.refreshTokenSubject.pipe(filter(function (result) { return result !== null; }), take(1), switchMap(function () { return _this.addAuthenticationToken(req).pipe(mergeMap(function (res) { return next.handle(res); })); }));
                    }
                    else {
                        _this.refreshTokenInProgress = true;
                        // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                        _this.refreshTokenSubject.next(null);
                        return _this.refreshAccessToken().pipe(switchMap(function (success) {
                            _this.refreshTokenSubject.next(success);
                            return _this.addAuthenticationToken(req).pipe(mergeMap(function (res) { return next.handle(res); }));
                        }), 
                        // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                        // for the next time the token needs to be refreshed
                        finalize(function () { return (_this.refreshTokenInProgress = false); }));
                    }
                }
                else {
                    return throwError(error);
                }
            }));
        }));
    };
    AuthInterceptorService.prototype.refreshAccessToken = function () {
        var _this = this;
        var getAccessToken = this.storageService.getItem(StorageKey.ACCESS_TOKEN_KEY);
        var getRefreshToken = this.storageService.getItem(StorageKey.REFRESH_TOKEN_KEY);
        return forkJoin([getAccessToken, getRefreshToken]).pipe(switchMap(function (_a) {
            var accessToken = _a[0], refreshToken = _a[1];
            var refreshTokenInput = {
                accessToken: accessToken,
                refreshToken: refreshToken,
                host: AGRICRM_TOKEN,
            };
            return _this.authProxyService.refreshToken(refreshTokenInput).pipe(catchError(function (err) {
                if (err && err.status && err.status === 401) {
                    console.error(err, 'Error while refreshing token -> logout()');
                    _this.storageService.clearAll();
                    _this.notificationService.error(err, "AUTH.logoutError:message");
                    _this.router.navigate(['auth', 'login']);
                    return throwError(err);
                }
                return throwError(err);
            }));
        }), mergeMap(function (res) {
            return _this.storageService.setItem(StorageKey.ACCESS_TOKEN_KEY, res.accessToken).pipe(mergeMap(function () { return of(res); }));
        }), catchError(function (err) {
            if (err && err.status && err.status === 401) {
                console.error(err, 'Error while refreshing token -> logout()');
                _this.storageService.clearAll();
                _this.router.navigate(['auth', 'login']);
                _this.notificationService.error(err, "AUTH.logoutError:message");
                return of(false);
            }
            console.error(err, 'Error while refreshing token -> err');
            return of(err);
        }));
    };
    AuthInterceptorService.prototype.addAuthenticationToken = function (request) {
        // If we do not have a token yet then we should not set the header.
        // Here we could first retrieve the token from where we store it.
        var _this = this;
        return this.storageService.getItem(StorageKey.ACCESS_TOKEN_KEY).pipe(mergeMap(function (res) {
            var token = res;
            if (!token) {
                return of(request);
            }
            // If you are calling for refreshing token.
            if (request.url.includes(_this.apiRefreshUrl)) {
                return of(request);
            }
            // If you are calling an outside domain then do not add the token.
            if (!request.url.includes(_this.config.configuration.api_url)) {
                return of(request);
            }
            return of(request.clone({
                headers: request.headers.set(_this.AUTH_HEADER, "Bearer " + token),
            }));
        }));
    };
    AuthInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthInterceptorService_Factory() { return new AuthInterceptorService(i0.ɵɵinject(i1.AuthProxyService), i0.ɵɵinject(i2.AuthStorageService), i0.ɵɵinject(i3.AppConfigurationService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i3.NotificationService)); }, token: AuthInterceptorService, providedIn: "root" });
    return AuthInterceptorService;
}());
export { AuthInterceptorService };
export var AuthInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true,
};
