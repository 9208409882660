<div [appSpinner]="displaySpinner$ | async">
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-basic-title">
      {{ headerTranslationKey | translate }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="cancel($event)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-add-form
      *ngIf="formModel"
      #addForm
      [formModel]="formModel"
      [formLayout]="formLayout"
      [model]="initValue"
      (add)="add($event)"
      [translateKey]="translationKeyPrefix"
      (afterCancel)="cancel($event)">
    </app-add-form>
  </div>
</div>
