import { HttpClient } from '@angular/common/http';
import { AppConfigurationService, prepareListUrlWithFilter } from 'crmcloud-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "crmcloud-core";
var ContactProxyService = /** @class */ (function () {
    function ContactProxyService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
        this.apiUrl = this.config.configuration.api_url + "/api/contacts";
    }
    ContactProxyService.prototype.getList = function (requestList) {
        return this.httpClient.get(prepareListUrlWithFilter(this.apiUrl, requestList, this.prepareFilterUrl));
    };
    ContactProxyService.prototype.create = function (model) {
        return this.httpClient.post("" + this.apiUrl, model);
    };
    ContactProxyService.prototype.get = function (id) {
        return this.httpClient.get(this.apiUrl + "/" + id);
    };
    ContactProxyService.prototype.delete = function (id) {
        return this.httpClient.delete(this.apiUrl + "/" + id);
    };
    ContactProxyService.prototype.update = function (id, model) {
        return this.httpClient.put(this.apiUrl + "/" + id, model);
    };
    ContactProxyService.prototype.prepareFilterUrl = function (filter) {
        var params = [];
        if (filter.name != null && filter.name !== '') {
            params.push("Name=" + filter.name);
        }
        if (filter.surname != null && filter.surname !== '') {
            params.push("Surname=" + filter.surname);
        }
        return params;
    };
    ContactProxyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContactProxyService_Factory() { return new ContactProxyService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfigurationService)); }, token: ContactProxyService, providedIn: "root" });
    return ContactProxyService;
}());
export { ContactProxyService };
