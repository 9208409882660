import { forkJoin } from 'rxjs';
import { VehTelProxyService } from './vehtel-proxy.service';
import { map } from 'rxjs/operators';
import { OrganizationListService } from '@crm-portal/modules/organization/common/services/organization-list.service';
import * as i0 from "@angular/core";
import * as i1 from "./vehtel-proxy.service";
import * as i2 from "../../modules/organization/common/services/organization-list.service";
var VehTelListService = /** @class */ (function () {
    function VehTelListService(vehTelProxyService, organizationListService) {
        this.vehTelProxyService = vehTelProxyService;
        this.organizationListService = organizationListService;
    }
    VehTelListService.prototype.getFullDeviceList = function (requestList) {
        return forkJoin({
            machines: this.getMachines(true),
            devices: this.vehTelProxyService.getDeviceList(requestList),
        }).pipe(map(function (x) {
            var _loop_1 = function (item) {
                item.machine = x.machines.find(function (machine) { return machine.id === item.vehicleRefId; });
            };
            for (var _i = 0, _a = x.devices.items; _i < _a.length; _i++) {
                var item = _a[_i];
                _loop_1(item);
            }
            return {
                items: x.devices.items,
                pager: x.devices.pager,
            };
        }));
    };
    VehTelListService.prototype.getFullTagList = function (requestList) {
        return forkJoin({
            machines: this.getMachines(false),
            devices: this.vehTelProxyService.getTagList(requestList),
        }).pipe(map(function (x) {
            var _loop_2 = function (item) {
                item.machine = x.machines.find(function (machine) { return machine.id === item.machineRefId; });
            };
            for (var _i = 0, _a = x.devices.items; _i < _a.length; _i++) {
                var item = _a[_i];
                _loop_2(item);
            }
            return {
                items: x.devices.items,
                pager: x.devices.pager,
            };
        }));
    };
    VehTelListService.prototype.getFullBeaconList = function (requestList) {
        return forkJoin({
            machines: this.getMachines(false),
            devices: this.vehTelProxyService.getBeaconList(requestList),
        }).pipe(map(function (x) {
            var _loop_3 = function (item) {
                item.machine = x.machines.find(function (machine) { return machine.id === item.machineRefId; });
            };
            for (var _i = 0, _a = x.devices.items; _i < _a.length; _i++) {
                var item = _a[_i];
                _loop_3(item);
            }
            return {
                items: x.devices.items,
                pager: x.devices.pager,
            };
        }));
    };
    VehTelListService.prototype.getDeviceList = function (requestList) {
        return this.vehTelProxyService.getDeviceList(requestList).pipe(map(function (x) {
            return {
                items: x.items,
                pager: x.pager,
            };
        }));
    };
    VehTelListService.prototype.getTagList = function (requestList) {
        // tslint:disable-next-line: no-identical-functions
        return this.vehTelProxyService.getTagList(requestList).pipe(map(function (x) {
            return {
                items: x.items,
                pager: x.pager,
            };
        }));
    };
    VehTelListService.prototype.getMachines = function (trackable) {
        var organizationRequestList = {
            pageSize: 9999999,
            orderBy: 'id',
            sortOrder: 'asc',
            page: 0,
            filter: null,
        };
        return forkJoin({
            machines: this.vehTelProxyService.getMachines(trackable).pipe(map(function (d) { return d.items; })),
            organizations: this.organizationListService.getList(organizationRequestList).pipe(map(function (d) { return d.items; })),
        }).pipe(map(function (x) {
            var _loop_4 = function (item) {
                item.organization = x.organizations.find(function (organization) { return organization.id === item.organizationId; });
                if (item.organization) {
                    item.organizationName = item.organization.name;
                }
            };
            for (var _i = 0, _a = x.machines; _i < _a.length; _i++) {
                var item = _a[_i];
                _loop_4(item);
            }
            return x.machines;
        }));
    };
    // tslint:disable-next-line: cognitive-complexity
    VehTelListService.prototype.getEmptyMachines = function (trackable, currentMachine) {
        if (trackable === void 0) { trackable = true; }
        if (currentMachine === void 0) { currentMachine = ''; }
        var requestList = {
            pageSize: 9999999,
            orderBy: 'id',
            sortOrder: 'asc',
            page: 0,
            filter: null,
        };
        var tagRequestList = {
            pageSize: 9999999,
            orderBy: 'id',
            sortOrder: 'asc',
            page: 0,
            filter: null,
        };
        return forkJoin({
            devices: this.getDeviceList(requestList),
            tags: this.getTagList(tagRequestList),
            machines: this.getMachines(trackable),
        }).pipe(map(function (x) {
            var result = [];
            result.push({
                id: '',
                name: '--------',
                organization: {
                    name: '--------',
                    id: '',
                    hasFarmPortalAccount: false,
                    postCode: '',
                    city: '',
                },
                organizationId: '',
                type: '',
            });
            for (var _i = 0, _a = x.machines; _i < _a.length; _i++) {
                var machine = _a[_i];
                machine.name = machine.name + " (" + machine.type + ")";
                var add = true;
                for (var _b = 0, _c = x.devices.items; _b < _c.length; _b++) {
                    var device = _c[_b];
                    if (device.vehicleRefId === machine.id) {
                        add = false;
                    }
                }
                for (var _d = 0, _e = x.tags.items; _d < _e.length; _d++) {
                    var tag = _e[_d];
                    if (tag.machineRefId === machine.id) {
                        add = false;
                    }
                }
                if (add || machine.id === currentMachine) {
                    result.push(machine);
                }
            }
            return result;
        }));
    };
    VehTelListService.prototype.getDeviceTypes = function () {
        return this.vehTelProxyService.getDeviceTypes();
    };
    VehTelListService.prototype.getExtraDevices = function () {
        return this.vehTelProxyService.getExtraDevices();
    };
    VehTelListService.prototype.getTagTypes = function () {
        return this.vehTelProxyService.getTagTypes();
    };
    VehTelListService.prototype.getBeaconTypes = function () {
        return this.vehTelProxyService.getBeaconTypes();
    };
    VehTelListService.prototype.getVehiclePosition = function (vehRefId) {
        return this.vehTelProxyService.getVehiclePosition(vehRefId);
    };
    VehTelListService.prototype.getVehicleStatus = function (vehRefId) {
        return this.vehTelProxyService.getVehicleStatus(vehRefId);
    };
    VehTelListService.prototype.getVehicleSessions = function (vehRefId) {
        return this.vehTelProxyService.getVehicleSessions(vehRefId);
    };
    VehTelListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VehTelListService_Factory() { return new VehTelListService(i0.ɵɵinject(i1.VehTelProxyService), i0.ɵɵinject(i2.OrganizationListService)); }, token: VehTelListService, providedIn: "root" });
    return VehTelListService;
}());
export { VehTelListService };
