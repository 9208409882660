<div class="row">
  <div class="col-12">
    <app-simple-form
      #yearFilter
      [formModel]="formModel"
      [formLayout]="formLayout"
      [model]="initValue"
      [translateKey]="'CULTIVATION.list.filter'">
    </app-simple-form>
  </div>
</div>
