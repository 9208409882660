<div [appSpinner]="dataSource.loading$ | async">
  <div
    class="card"
    appPanelFullscreen>
    <div class="card-header d-flex">
      <div class="ml-auto">
        <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
      </div>
    </div>
    <div class="card-content">
      <div class="card-body py-1">
        <div class="container-fluid px-0">
          <div
            class="row text-center"
            [hidden]="!(dataSource.noData$ | async)">
            <div class="col-12">
              <h5>
                {{ 'GROUND.soilSpecimensTab.noSoilSpecimens' | translate }}
              </h5>
            </div>
          </div>
          <div class="mt-3">
            <cdk-table
              #newTable
              [dataSource]="dataSource"
              cdkSort
              cdkSortActive="date"
              cdkSortDirection="desc">
              <ng-container cdkColumnDef="sampleId">
                <div
                  cdk-header-cell
                  *cdkHeaderCellDef
                  class="col-1 text-truncate">
                  {{ 'GROUND.soilSpecimensTab.LIST.sampleId' | translate }}
                </div>
                <div
                  cdk-cell
                  *cdkCellDef="let item"
                  class="col-1 text-truncate pr-0">
                  <button
                    (click)="item.expanded = !item.expanded"
                    class="btn btn-raised shadow-none btn-sm m-0 my-1 mr-1 d-none d-md-inline d-print-none">
                    <i
                      [ngClass]="{
                        ft: true,
                        'ft-chevron-right': !item.expanded,
                        'ft-chevron-down': item.expanded
                      }"
                      aria-hidden="true"></i>
                  </button>
                  <span>{{ item.testData?.sampleId }}</span>
                </div>
              </ng-container>

              <ng-container cdkColumnDef="date">
                <div
                  cdk-header-cell
                  *cdkHeaderCellDef
                  cdk-sort-header
                  class="col-2 text-truncate">
                  {{ 'GROUND.soilSpecimensTab.LIST.date' | translate }}
                </div>
                <div
                  cdk-cell
                  *cdkCellDef="let item"
                  class="col-2 text-truncate pr-0">
                  <span>{{ item.date | date: 'shortDate' }}</span>
                </div>
              </ng-container>

              <ng-container cdkColumnDef="usePurpose">
                <div
                  cdk-header-cell
                  *cdkHeaderCellDef
                  class="col-2 text-truncate">
                  {{ 'GROUND.soilSpecimensTab.LIST.usePurpose' | translate }}
                </div>
                <div
                  cdk-cell
                  *cdkCellDef="let item"
                  class="col-2 text-truncate pr-0">
                  <span>{{ item.testData?.usePurpose }}</span>
                </div>
              </ng-container>

              <ng-container cdkColumnDef="agronomicCategory">
                <div
                  cdk-header-cell
                  *cdkHeaderCellDef
                  class="col-2 text-truncate">
                  {{ 'GROUND.soilSpecimensTab.LIST.agronomicCategory' | translate }}
                </div>
                <div
                  cdk-cell
                  *cdkCellDef="let item"
                  class="col-2 text-truncate pr-0">
                  <span>{{
                    item.testData?.agronomicCategory | enumTranslateKey: 'APPLICATION.DICTIONARIES.AGRONOMIC_CATEGORIES' | translate
                  }}</span>
                </div>
              </ng-container>

              <ng-container cdkColumnDef="pHInKCI">
                <div
                  cdk-header-cell
                  *cdkHeaderCellDef
                  class="col-1 text-truncate">
                  {{ 'GROUND.soilSpecimensTab.LIST.pHInKCI' | translate }}
                </div>
                <div
                  cdk-cell
                  *cdkCellDef="let item"
                  class="col-1 text-truncate pr-0">
                  <span>{{ item.testData?.pHinKCI }}</span>
                </div>
              </ng-container>

              <ng-container cdkColumnDef="reaction">
                <div
                  cdk-header-cell
                  *cdkHeaderCellDef
                  class="col-2 text-truncate">
                  {{ 'GROUND.soilSpecimensTab.LIST.reaction' | translate }}
                </div>
                <div
                  cdk-cell
                  *cdkCellDef="let item"
                  class="col-2 text-truncate pr-0">
                  <span>{{ item.testData?.reaction }}</span>
                </div>
              </ng-container>

              <ng-container cdkColumnDef="limingType">
                <div
                  cdk-header-cell
                  *cdkHeaderCellDef
                  class="col-2 text-truncate">
                  {{ 'GROUND.soilSpecimensTab.LIST.limingType' | translate }}
                </div>
                <div
                  cdk-cell
                  *cdkCellDef="let item"
                  class="col-2 text-truncate pr-0">
                  <span>{{ item.testData?.limingType | enumTranslateKey: 'APPLICATION.DICTIONARIES.LIMING_TYPE' | translate }}</span>
                </div>
              </ng-container>

              <ng-container cdkColumnDef="details">
                <div
                  cdk-header-cell
                  *cdkHeaderCellDef></div>
                <div
                  cdk-cell
                  *cdkCellDef="let item"
                  class="order-4 order-md-5 col-12">
                  <div
                    class="details-row-grid d-print-block"
                    [ngClass]="{ 'd-none': !item.expanded }">
                    <div class="details-col">
                      <div class="details-grid">
                        <p>{{ 'GROUND.soilSpecimensTab.LIST.P2O5Mg' | translate | uppercase }}:</p>
                        <span>
                          {{ item.testData?.p2O5mg }}
                        </span>
                        <p>{{ 'GROUND.soilSpecimensTab.LIST.P2O5Assessment' | translate | uppercase }}:</p>
                        <span> {{ item.testData?.p2O5Assessment }}</span>
                      </div>
                    </div>
                    <div class="details-col">
                      <div class="details-grid">
                        <p>{{ 'GROUND.soilSpecimensTab.LIST.K2OMg' | translate | uppercase }}:</p>
                        <span>
                          {{ item.testData?.k2Omg }}
                        </span>
                        <p>{{ 'GROUND.soilSpecimensTab.LIST.K2OAssessment' | translate | uppercase }}:</p>
                        <span> {{ item.testData?.k2OAssessment }}</span>
                      </div>
                    </div>
                    <div class="details-col">
                      <div class="details-grid">
                        <p>{{ 'GROUND.soilSpecimensTab.LIST.Mgmg' | translate | uppercase }}:</p>
                        <span>
                          {{ item.testData?.mgmg }}
                        </span>
                        <p>{{ 'GROUND.soilSpecimensTab.LIST.MgAssessment' | translate | uppercase }}:</p>
                        <span> {{ item.testData?.mgAssessment }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <cdk-header-row
                class="row header-navbar m-0 py-2 extra_bg-light-grey"
                *cdkHeaderRowDef="displayedColumns">
              </cdk-header-row>
              <cdk-row
                class="row row-striped align-items-center m-0"
                *cdkRowDef="let row; columns: displayedColumns"></cdk-row>
            </cdk-table>
            <div
              class="row"
              *ngIf="dataSource.noData$ | async">
              <div class="col text-center py-3">
                {{ 'GROUND.soilSpecimensTab.list:empty' | translate }}
              </div>
            </div>
            <div
              class="row"
              *ngIf="dataSource.error$ | async">
              <div class="col text-center py-3">
                {{ 'GROUND.soilSpecimensTab.list:error' | translate }}
              </div>
            </div>
            <cdk-paginator
              #newPaginator
              class="row header-navbar d-flex justify-content-end pr-2 py-1"
              [pageSizeOptions]="[10, 25, 50, 75, 100]"
              showFirstLastButtons></cdk-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
