import { HttpClient } from '@angular/common/http';
import { AppConfigurationService } from 'crmcloud-core';
import { TenantService } from '@crm-portal/core/auth/tenant.service';
import * as i0 from "@angular/core";
import * as i1 from "crmcloud-core";
import * as i2 from "../../auth/tenant.service";
import * as i3 from "@angular/common/http";
var MessageCoreProxyService = /** @class */ (function () {
    function MessageCoreProxyService(config, tenantService, httpClient) {
        this.tenantService = tenantService;
        this.httpClient = httpClient;
        this.baseApiUrl = config.configuration.api_url + "/v1/organizations";
    }
    MessageCoreProxyService.prototype.getAll = function () {
        return this.httpClient.get(this.apiUrl);
    };
    MessageCoreProxyService.prototype.getAllUnread = function () {
        return this.httpClient.get(this.apiUrl + "?IsRead=false");
    };
    MessageCoreProxyService.prototype.createMessage = function (messageCommand) {
        return this.httpClient.post(this.apiUrl, messageCommand);
    };
    MessageCoreProxyService.prototype.markAsRead = function (id) {
        var url = this.apiUrl + "/" + id;
        var body = { isRead: true };
        return this.httpClient.patch(url, body);
    };
    Object.defineProperty(MessageCoreProxyService.prototype, "apiUrl", {
        get: function () {
            return this.baseApiUrl + "/" + this.tenantService.currentTenantId + "/messages";
        },
        enumerable: true,
        configurable: true
    });
    MessageCoreProxyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessageCoreProxyService_Factory() { return new MessageCoreProxyService(i0.ɵɵinject(i1.AppConfigurationService), i0.ɵɵinject(i2.TenantService), i0.ɵɵinject(i3.HttpClient)); }, token: MessageCoreProxyService, providedIn: "root" });
    return MessageCoreProxyService;
}());
export { MessageCoreProxyService };
