import { OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { DeviceType, SpinnerService } from 'crmcloud-core';
import { OrganizationCoreService } from '@crm-portal/core/organization';
import { VehTelListService } from '@crm-portal/core/vehtel';
import { WeatherStationCoreService } from '@crm-portal/core/weather-station';
import { WeatherStation } from '../common/models/weather-station.model';
var DeviceDashboardComponent = /** @class */ (function () {
    function DeviceDashboardComponent(vehTelService, weatherSensorService, organizationCoreService, spinnerService) {
        this.vehTelService = vehTelService;
        this.weatherSensorService = weatherSensorService;
        this.organizationCoreService = organizationCoreService;
        this.spinnerService = spinnerService;
        this.elements = [];
    }
    DeviceDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.spinnerService.display();
        forkJoin({
            vehTel: this.vehTelService.getFullDeviceList(this.getRequestList()),
            reHydro: this.weatherSensorService.getAll(DeviceType.MoistureSensor),
            metPro: this.weatherSensorService.getAll(DeviceType.WeatherStation),
            organizations: this.organizationCoreService.getAll(),
        }).subscribe(function (res) {
            var metPro = res.metPro.map(function (x) { return new WeatherStation(x, DeviceType.WeatherStation); });
            var reHydro = res.reHydro.map(function (x) { return new WeatherStation(x, DeviceType.MoistureSensor); });
            var items = metPro.concat(reHydro);
            var organizations = items.map(function (x) { return x.organizationId; }).filter(function (elem, index, array) { return array.indexOf(elem) === index; });
            organizations.push.apply(organizations, res.vehTel.items
                .filter(function (x) { return x.machine; })
                .map(function (x) { return x.machine.organizationId; })
                .filter(function (elem, index, array) { return array.indexOf(elem) === index; })
                .filter(function (x) { return organizations.indexOf(x) === -1; }));
            organizations.forEach(function (organizationId) {
                _this.elements.push({
                    organizationId: organizationId,
                    organization: res.organizations.find(function (x) { return x.id === organizationId; }),
                    weather: items.filter(function (x) { return x.organizationId === organizationId; }),
                    vehTel: res.vehTel.items.filter(function (x) { return x.machine; }).filter(function (x) { return x.machine.organizationId === organizationId; }),
                });
            });
            _this.loadVehTelOnline();
            _this.spinnerService.hide();
        });
        setInterval(function () {
            _this.updateDate();
            // tslint:disable-next-line:no-magic-numbers
        }, 600000);
    };
    DeviceDashboardComponent.prototype.getRequestList = function () {
        return {
            pageSize: 9999999,
            orderBy: 'id',
            sortOrder: 'asc',
            page: 0,
            filter: null,
        };
    };
    DeviceDashboardComponent.prototype.filteredElements = function () {
        var returnElements = { vehTel: [], weather: [] };
        this.elements.forEach(function (x) {
            var _a, _b;
            (_a = returnElements.weather).push.apply(_a, x.weather);
            (_b = returnElements.vehTel).push.apply(_b, x.vehTel);
        });
        return {
            weather: returnElements.weather.sort(function (a, b) { return parseInt(a.serialNumber, 16) - parseInt(b.serialNumber, 16); }),
            vehTel: returnElements.vehTel.sort(function (a, b) { return a.imei - b.imei; }),
        };
    };
    DeviceDashboardComponent.prototype.getDeviceIcon = function (weather) {
        switch (weather.deviceType) {
            case DeviceType.MoistureSensor:
                return 'ft-droplet';
            case DeviceType.WeatherStation:
                return 'ft-sun';
            default:
                return 'ft-layers';
        }
    };
    DeviceDashboardComponent.prototype.loadVehTelOnline = function () {
        var _this = this;
        this.elements.forEach(function (element) {
            element.vehTel.forEach(function (machine) {
                _this.vehTelService.getVehiclePosition(machine.vehicleRefId).subscribe(function (res) {
                    // tslint:disable-next-line:no-magic-numbers
                    machine.lastOnline = res.timestamp * 1000;
                    machine.lastPosition = res.gps;
                });
            });
        });
    };
    DeviceDashboardComponent.prototype.getDeviceStatus = function (date) {
        if (typeof date === 'number' || typeof date === 'string') {
            date = new Date(date);
        }
        // tslint:disable-next-line:no-magic-numbers
        var last3Hours = new Date(new Date().getTime() - 3 * 3600000);
        // tslint:disable-next-line:no-magic-numbers
        var before3Days = new Date(new Date().getTime() - 3 * 24 * 3600000);
        if (date > last3Hours) {
            return 'device--success';
        }
        else if (date > before3Days) {
            return 'device--warning';
        }
        else {
            return 'device--danger';
        }
    };
    DeviceDashboardComponent.prototype.updateDate = function () {
        var _this = this;
        this.loadVehTelOnline();
        forkJoin({
            reHydro: this.weatherSensorService.getAll(DeviceType.MoistureSensor),
            metPro: this.weatherSensorService.getAll(DeviceType.WeatherStation),
        }).subscribe(function (res) {
            var items = res.metPro.concat(res.reHydro);
            _this.elements.forEach(function (element) {
                element.weather.forEach(function (weather) {
                    var weatherFromRequest = items.find(function (x) { return x.id === weather.id; });
                    if (weatherFromRequest) {
                        weather.lastActiveDate = weatherFromRequest.lastActiveDate;
                    }
                });
            });
        });
    };
    return DeviceDashboardComponent;
}());
export { DeviceDashboardComponent };
