<div
  class="card bg-light"
  (click)="handleEdit($event)">
  <div class="card-header d-flex justify-content-between">
    <h4 class="card-title">{{ note?.title }}</h4>
    <div class="d-flex">
      <button
        *ngIf="shareable && hasAccessToShare"
        (click)="onShare($event)"
        title="{{ translationKeyPrefix + '.share:button' | translate }}"
        class="btn btn-raised m-0 my-0 mr-1 btn-sm shadow-none">
        <i
          class="ft ft-share-2"
          aria-hidden="true"></i>
      </button>
      <button
        *ngIf="showEdit"
        (click)="handleEdit($event)"
        title="{{ translationKeyPrefix + '.update:button' | translate }}"
        class="btn btn-raised m-0 my-0 mr-1 btn-sm shadow-none">
        <i
          class="ft ft-edit-2"
          aria-hidden="true"></i>
      </button>
      <button
        *ngIf="showDelete"
        (click)="onDelete($event)"
        title="{{ translationKeyPrefix + '.delete:button' | translate }}"
        class="btn btn-raised m-0 my-0 btn-sm shadow-none">
        <i
          class="ft ft-trash-2"
          aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="card-content">
    <div class="card-body px-2">
      <div
        class="grid"
        *ngIf="items && items.length > 0; else elseTemplate">
        <div
          class="grid-item"
          *ngFor="let item of items; let i = index; trackBy: trackByFn"
          [lightbox]="i"
          [gallery]="galleryId">
          <img
            class="grid-image"
            [src]="item.data.thumb" />
        </div>
      </div>
      <ng-template #elseTemplate>
        <div class="grid-empty">
          {{ translationKeyPrefix + '.list:noPhoto' | translate }}
        </div>
      </ng-template>

      <div
        class="mt-1 description"
        style="-webkit-box-orient: vertical">
        {{ note?.description }}
      </div>
    </div>
  </div>
  <div class="card-footer bg-light">
    <div class="optional-text text-left">
      {{ note?.registeredAt | i18nDate: 'shortDate' }}
    </div>
  </div>
</div>
