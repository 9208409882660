<div [appSpinner]="dataSource.loading$ | async">
  <div
    class="card"
    appPanelFullscreen>
    <div class="card-header d-flex">
      <div class="ml-auto">
        <app-panel-fullscreen-button class="float-right"></app-panel-fullscreen-button>
      </div>
    </div>
    <div class="card-content">
      <div class="card-body py-1">
        <div class="container-fluid px-0">
          <div
            class="row text-center"
            [hidden]="!(dataSource.noData$ | async)">
            <div class="col-12">
              <h5>
                {{ 'GROUND.scansTab.noScans' | translate }}
              </h5>
            </div>
          </div>
          <div
            class="row"
            [hidden]="dataSource.noData$ | async">
            <div class="col-12 col-lg-6 col-xl-8">
              <label class="px-0 mb-2">
                <h5 class="m-0">
                  <i class="ft ft-map"></i>
                  {{ 'GROUND.scansTab.map' | translate }}
                </h5>
              </label>
              <hr class="mt-0" />
              <lib-ground-scans-map
                [organizationId]="organizationId"
                [groundId]="groundId"
                [scanId]="scanId"
                [layer]="layer"></lib-ground-scans-map>
            </div>
            <div class="col-12 col-lg-6 col-xl-4">
              <label class="px-0 mb-2 mt-3 mt-lg-0">
                <h5 class="m-0">
                  <i class="ft ft-layers"></i>
                  {{ 'GROUND.scansTab.scans' | translate }}
                </h5>
              </label>
              <hr class="mt-0" />

              <app-simple-form
                #scanFilter
                [formModel]="formModel"
                [formLayout]="formLayout"
                [model]="initValue"
                [translateKey]="'GROUND.scansTab.filter'">
              </app-simple-form>

              <div class="mt-3">
                <cdk-table
                  [dataSource]="dataSource"
                  cdkSort
                  cdkSortActive="measurementDate"
                  cdkSortDirection="desc">
                  <ng-container cdkColumnDef="measurementDate">
                    <div
                      cdk-header-cell
                      *cdkHeaderCellDef
                      cdk-sort-header
                      class="col-6 text-truncate">
                      {{ 'GROUND.scansTab.measurementDate' | translate }}
                    </div>
                    <div
                      cdk-cell
                      *cdkCellDef="let item"
                      class="col-9 text-truncate pr-0">
                      <span>{{ item.measurementDate | date: 'shortDate' }}</span>
                    </div>
                  </ng-container>
                  <ng-container cdkColumnDef="action">
                    <div
                      cdk-header-cell
                      *cdkHeaderCellDef
                      class="col-3 p-0 text-center">
                      {{ 'APPLICATION.LIST.action' | translate }}
                    </div>
                    <div
                      cdk-cell
                      *cdkCellDef="let item"
                      class="col-3 p-0 pl-1 pl-md-0 pr-0 pr-md-1 w-100">
                      <div class="row no-gutters float-md-right">
                        <button
                          (click)="showOnMap(item)"
                          title="{{ 'GROUND.scansTab.showOnMap:button' | translate }}"
                          class="btn btn-raised m-0 my-1 mr-1 btn-sm btn-info">
                          <i
                            class="ft ft-search"
                            aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </ng-container>
                  <cdk-header-row
                    class="row header-navbar m-0 py-2 extra_bg-light-grey"
                    *cdkHeaderRowDef="displayedColumns">
                  </cdk-header-row>
                  <cdk-row
                    class="row row-striped align-items-center m-0"
                    *cdkRowDef="let row; columns: displayedColumns"></cdk-row>
                </cdk-table>
                <div
                  class="row"
                  *ngIf="dataSource.noData$ | async">
                  <div class="col text-center py-3">
                    {{ 'GROUND.scansTab.list:empty' | translate }}
                  </div>
                </div>
                <div
                  class="row"
                  *ngIf="dataSource.error$ | async">
                  <div class="col text-center py-3">
                    {{ 'GROUND.scansTab.list:error' | translate }}
                  </div>
                </div>
                <cdk-paginator
                  class="row header-navbar d-flex justify-content-end pr-2 py-1"
                  [pageSizeOptions]="[10, 25, 50, 75, 100]"
                  showFirstLastButtons></cdk-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
