import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppConfigurationService } from 'crmcloud-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "crmcloud-core";
var UserService = /** @class */ (function () {
    function UserService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
    }
    UserService.prototype.getUserStatus = function (tenantId) {
        var url = this.config.configuration.api_url + "/v1/organizations/" + tenantId + "/access";
        return this.httpClient.get(url).pipe(map(function (access) {
            var token = JSON.stringify(access);
            return btoa(unescape(encodeURIComponent(token)));
        }));
    };
    UserService.prototype.userRegister = function (user) {
        var url = this.config.configuration.api_url + "/api/User";
        return this.httpClient.post(url, user);
    };
    UserService.prototype.userLogin = function (user) {
        var url = this.config.configuration.api_url + "/api/TokenAuth/Authenticate";
        return this.httpClient.post(url, user);
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfigurationService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
