<div class="d-flex justify-content-between">
  <ul class="available-items">
    <li
      *ngFor="let item of availableListItems"
      class="item"
      [ngClass]="{ 'selected-item': item.isSelected }"
      (click)="onSelectItem(item)">
      <span>{{ item.label }}</span>
    </li>
  </ul>

  <div class="pl-1 pr-1">
    <button
      (click)="onAddSelected()"
      title="{{ 'ORGANIZATION.delete:button' | translate }}"
      class="d-none d-md-block btn btn-raised m-0 my-1 btn-sm btn-primary">
      <i
        class="ft ft-chevron-right"
        aria-hidden="true"></i>
    </button>
    <button
      (click)="onAddAll()"
      title="{{ 'ORGANIZATION.delete:button' | translate }}"
      class="d-none d-md-block btn btn-raised m-0 my-1 btn-sm btn-primary mb-2">
      <i
        class="ft ft-chevrons-right"
        aria-hidden="true"></i>
    </button>
    <button
      (click)="onRemoveSelected()"
      title="{{ 'ORGANIZATION.delete:button' | translate }}"
      class="d-none d-md-block btn btn-raised m-0 my-1 btn-sm btn-danger">
      <i
        class="ft ft-chevron-left"
        aria-hidden="true"></i>
    </button>
    <button
      (click)="onRemoveAll()"
      title="{{ 'ORGANIZATION.delete:button' | translate }}"
      class="d-none d-md-block btn btn-raised m-0 my-1 btn-sm btn-danger">
      <i
        class="ft ft-chevrons-left"
        aria-hidden="true"></i>
    </button>
  </div>

  <ul class="selected-items">
    <li
      *ngFor="let item of selectedListItems"
      class="item"
      [ngClass]="{ 'selected-item': item.isSelected }"
      (click)="onSelectItem(item)">
      <span>{{ item.label }}</span>
    </li>
  </ul>
</div>
