<ng-container *ngIf="drawingIsReady">
  <agm-marker
    *ngFor="let marker of markers$ | async"
    [latitude]="marker.latitude"
    [longitude]="marker.longitude"
    [title]="marker.title"
    [label]="marker.label"
    [markerDraggable]="marker.editable"
    [iconUrl]="marker.iconUrl"
    [visible]="marker.visible"
    [openInfoWindow]="marker.openInfoWindow"
    [opacity]="marker.opacity"
    [zIndex]="marker.zIndex"
    [markerClickable]="marker.clickable"
    [animation]="marker.animation"
    (dragEnd)="onDragEnd(marker, $event)">
    <agm-snazzy-info-window
      [maxWidth]="800"
      [closeWhenOthersOpen]="true">
      <ng-template>
        <div class="d-flex align-items-center justify-content-between border py-1 pl-2 pr-1">
          <div
            class="marker-content"
            [innerHTML]="marker.name"></div>
          <div *ngIf="marker.editable">
            <button
              (click)="deleteMarker(marker)"
              class="d-none d-md-block btn btn-raised m-0 my-1 mr-1 btn-sm btn-danger">
              <i
                class="ft ft-trash-2"
                aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </ng-template>
    </agm-snazzy-info-window>
  </agm-marker>
</ng-container>
