import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateLoader } from '@ngx-translate/core';
import { SupportedLanguages, TranslateModuleOptions, initAppConfiguration, AppConfigurationService, } from 'crmcloud-core';
import { from } from 'rxjs';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true,
    wheelPropagation: false,
};
var DEFAULT_TRANSLATE_MODULE_OPTIONS_CONFIG = {
    defaultLanguage: SupportedLanguages.PL,
};
var WebpackTranslateLoader = /** @class */ (function () {
    function WebpackTranslateLoader() {
    }
    // tslint:disable-next-line: no-any
    WebpackTranslateLoader.prototype.getTranslation = function (lang) {
        return from(System.import("../assets/i18n/" + lang + ".json"));
    };
    return WebpackTranslateLoader;
}());
export { WebpackTranslateLoader };
var ɵ0 = initAppConfiguration, ɵ1 = DEFAULT_TRANSLATE_MODULE_OPTIONS_CONFIG, ɵ2 = DEFAULT_PERFECT_SCROLLBAR_CONFIG, ɵ3 = function (window, appConfigurationService) { return ({
    auth: {
        clientId: appConfigurationService.configuration.ad_clientID,
        authority: appConfigurationService.configuration.ad_authority,
        validateAuthority: true,
        redirectUri: window.location.origin + '/',
        navigateToLoginRequestUrl: true,
    },
}); }, ɵ4 = function (appConfigurationService) { return ({
    consentScopes: [appConfigurationService.configuration.ad_clientID],
    popUp: false,
    protectedResourceMap: [[appConfigurationService.configuration.api_url, [appConfigurationService.configuration.ad_clientID]]],
}); };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
