<div
  class="container-fluid px-0"
  [appSpinner]="displaySpinner$ | async">
  <form
    #warehouseInflowItemsForm
    class="form-horizontal ng-dynamic-form"
    [formGroup]="formGroup"
    *ngIf="formGroup && formModel$ | async as formModel">
    <fieldset *ngIf="showAddItemButton">
      <button
        type="button"
        (click)="addItem()"
        class="btn btn-primary float-right">
        <i class="ft-plus"></i>
        <span>{{ translateKey + '.addItem' | translate }}</span>
      </button>
    </fieldset>
    <fieldset>
      <dynamic-ng-bootstrap-form
        class="row no-gutters"
        [group]="formGroup"
        [layout]="formLayout"
        [model]="formModel">
        <ng-template
          modelType="GROUP"
          align="START"
          let-legend="legend">
          <hr
            class="mt-0 mb-0"
            [ngClass]="{ 'mt-0': true, 'mb-0': legend }" />
          <p
            *ngIf="legend"
            class="form-group-legend m-0 mb-1">
            {{ legend }}
          </p>
        </ng-template>
        <ng-template
          modelId="warehouseInflowItemsArrayModel"
          let-group
          let-index="index"
          let-context="context">
          <div class="warehouse-inflow-items-buttons row mt-2 justify-content-md-end justify-content-xl-center mt-xl-0">
            <div class="col-6 col-md-auto px-md-0">
              <button
                type="button"
                class="btn btn-danger"
                *ngIf="!removeItemDisabled"
                (click)="removeItem(context, index)"
                [disabled]="removeItemDisabled">
                <i class="ft-trash"></i>
              </button>
            </div>
            <div class="col-6 col-md-auto">
              <button
                type="button"
                class="btn btn-primary"
                (click)="insertItem(group.context, group.index + 1)">
                <i class="ft-plus"></i>
              </button>
            </div>
          </div>
        </ng-template>
      </dynamic-ng-bootstrap-form>
    </fieldset>
  </form>
</div>
