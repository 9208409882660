import { Router } from '@angular/router';
import { FcTokenService } from 'crmcloud-core';
var UserGuard = /** @class */ (function () {
    function UserGuard(router, fcService) {
        this.router = router;
        this.fcService = fcService;
    }
    UserGuard.prototype.canActivate = function (next, 
    // tslint:disable-next-line: max-union-size
    state) {
        return this.fcService.currentFcToken != null
            ? true
            : this.router.createUrlTree(['/load-user-data'], {
                queryParams: {
                    returnUrl: state.url,
                },
                skipLocationChange: true,
            });
    };
    return UserGuard;
}());
export { UserGuard };
