import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { RouteInfo } from 'apex-shared';
import { SpinnerService, FeatureService, AppConfigurationService, FeatureConfiguration } from 'crmcloud-core';
import { MESSAGE_UNREAD_SERVICE } from 'crmcloud-shared';
import { ROUTES } from '@crm-portal/app-routing.config';
import { MessageCoreNotificationService } from '@crm-portal/core/message';
import { AuthService } from '@crm-portal/core/auth/services/auth.service';
import { AuthStoreService } from '@crm-portal/core/auth/services/auth-store.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss'],
  providers: [
    {
      provide: MESSAGE_UNREAD_SERVICE,
      useClass: MessageCoreNotificationService,
    },
  ],
})
export class FullLayoutComponent implements OnInit, OnDestroy {
  hideSidebar: boolean;
  iscollapsed = false;
  displaySpinner$;

  private logoutSub: Subscription;

  private loadConfigurationSubscription: Subscription;
  constructor(
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private authStore: AuthStoreService,
    private appConfigurationService: AppConfigurationService,
    private featureService: FeatureService,
  ) {}

  public routes: RouteInfo[];
  public user: {
    name;
    userName;
  };

  get groupClaims(): string[] {
    return this.authStore.getUserClaims;
  }

  ngOnInit() {
    this.displaySpinner$ = this.spinnerService.display$;

    this.loadConfigurationSubscription = forkJoin({
      features: this.featureService.loadFeatures(),
    }).subscribe(config => {
      this.routes = this.recursiveRouteFilter(ROUTES, config.features);
    });
  }

  ngOnDestroy(): void {
    if (this.loadConfigurationSubscription) {
      this.loadConfigurationSubscription.unsubscribe();
    }
    if (this.logoutSub) {
      this.logoutSub.unsubscribe();
    }
  }

  toggleHideSidebar($event: boolean): void {
    setTimeout(() => {
      this.hideSidebar = $event;
    }, 0);
  }

  logout() {
    this.logoutSub = this.authService.logout().subscribe();
  }

  hasAccess(componentKey: string): boolean {
    return this.groupClaims.some(claim => {
      return claim.startsWith(componentKey);
    });
  }

  private recursiveRouteFilter(routes: RouteInfo[], featureConfig: FeatureConfiguration): RouteInfo[] {
    const result = [];
    routes.forEach(route => {
      if (featureConfig.isEnabled(route.title)) {
        this.checkIfExternalAndUpdatePath(route);
        if (route.submenu && route.submenu.length) {
          route.submenu = this.recursiveRouteFilter(route.submenu, featureConfig);
        }
        result.push(route);
      }
    });
    return result;
  }

  private checkIfExternalAndUpdatePath(route: RouteInfo) {
    if (route.isExternalLink && route.title === 'APPLICATION.MENU.supplierPortal') {
      route.path = this.appConfigurationService.configuration.supplier_portal_url;
    }
  }
}
