<ng-container [formGroup]="group">
  <lib-warehouse-outflow-item-form-control
    formControlName="{{ model.id }}"
    [translateKey]="model.translateKey"
    [stockDetailsList]="model.stockDetailsList"
    [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
    [touched]="control.touched"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)">
  </lib-warehouse-outflow-item-form-control>
</ng-container>
