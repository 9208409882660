import { AuthStoreService } from './services/auth-store.service';
import * as i0 from "@angular/core";
import * as i1 from "./services/auth-store.service";
var TenantService = /** @class */ (function () {
    function TenantService(authStoreService) {
        this.authStoreService = authStoreService;
    }
    Object.defineProperty(TenantService.prototype, "currentTenantId", {
        get: function () {
            return this.authStoreService.getUserOrganizationId;
        },
        enumerable: true,
        configurable: true
    });
    TenantService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TenantService_Factory() { return new TenantService(i0.ɵɵinject(i1.AuthStoreService)); }, token: TenantService, providedIn: "root" });
    return TenantService;
}());
export { TenantService };
