import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MSAL_CONFIG, MSAL_CONFIG_ANGULAR, MsalModule, MsalService, MsalGuard } from '@azure/msal-angular';

import { AppConfigurationService, WINDOW } from 'crmcloud-core';
import { AuthProxyService } from './services/auth-proxy.service';
import { AuthService } from './services/auth.service';
import { AuthStoreService } from './services/auth-store.service';
import { AuthInterceptorProvider } from './services/auth-interceptor.service';
import { IdpAuthGuard } from './idp-auth.guard';
import { IdpOrganizationGuard } from './idp-organization.guard';
import { IdpPartnerOrganizationGuard } from './idp-partner-organization.guard';

@NgModule({
  declarations: [],
  imports: [CommonModule, MsalModule],
  providers: [
    AuthService,
    AuthProxyService,
    AuthStoreService,
    AuthInterceptorProvider,
    IdpAuthGuard,
    IdpOrganizationGuard,
    IdpPartnerOrganizationGuard,
    MsalGuard,
    MsalService,
    {
      provide: MSAL_CONFIG,
      useFactory: (window, appConfigurationService: AppConfigurationService) => ({
        auth: {
          clientId: appConfigurationService.configuration.ad_clientID,
          authority: appConfigurationService.configuration.ad_authority,
          validateAuthority: true,
          redirectUri: window.location.origin + '/',
          navigateToLoginRequestUrl: true,
        },
      }),
      deps: [WINDOW, AppConfigurationService],
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: (appConfigurationService: AppConfigurationService) => ({
        consentScopes: [appConfigurationService.configuration.ad_clientID],
        popUp: false,
        protectedResourceMap: [[appConfigurationService.configuration.api_url, [appConfigurationService.configuration.ad_clientID]]],
      }),
      deps: [AppConfigurationService],
    },
  ],
  exports: [MsalModule],
})
export class AuthModule {}
