import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppConfigurationService, prepareQueryParams } from 'crmcloud-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "crmcloud-core";
var OrganizationCoreProxyService = /** @class */ (function () {
    function OrganizationCoreProxyService(httpClient, config) {
        this.httpClient = httpClient;
        this.config = config;
        this.apiUrl = this.config.configuration.api_url + "/v1/organizations";
        this.apiIdpUrl = this.config.configuration.api_url + "/api/organization";
    }
    OrganizationCoreProxyService.prototype.getAll = function () {
        return this.httpClient.get(this.apiIdpUrl + "/all");
    };
    OrganizationCoreProxyService.prototype.find = function (requestList) {
        var params = prepareQueryParams(new HttpParams(), requestList);
        return this.httpClient.get(this.apiUrl, { params: params });
    };
    OrganizationCoreProxyService.prototype.get = function (id) {
        return this.httpClient.get(this.apiUrl + "/" + id).pipe(map(function (x) { return (x != null && x.length > 0 ? x[0] : null); }));
    };
    OrganizationCoreProxyService.prototype.getOrganizationSuppliers = function (id) {
        return this.httpClient.get(this.apiUrl + "/" + id + "/suppliers");
    };
    OrganizationCoreProxyService.prototype.create = function (createContractCommand) {
        return this.httpClient.post("" + this.apiUrl, createContractCommand);
    };
    OrganizationCoreProxyService.prototype.update = function (id, updateContractCommand) {
        return this.httpClient.put(this.apiUrl + "/" + id, updateContractCommand);
    };
    OrganizationCoreProxyService.prototype.delete = function (id) {
        return this.httpClient.delete(this.apiUrl + "/" + id);
    };
    OrganizationCoreProxyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrganizationCoreProxyService_Factory() { return new OrganizationCoreProxyService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfigurationService)); }, token: OrganizationCoreProxyService, providedIn: "root" });
    return OrganizationCoreProxyService;
}());
export { OrganizationCoreProxyService };
