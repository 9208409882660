<ng-container [formGroup]="group">
  <lib-file-form-control
    [formControlName]="model.id"
    [name]="model.name"
    [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
    [multiple]="model.multiple"
    [accept]="model.accept"
    [maxSize]="model.maxSize"
    [readonly]="model.disabled"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)"></lib-file-form-control>
</ng-container>
